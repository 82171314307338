export const PriceData = [
    {
        id:1,
        img:'images/flegs/india-svg.svg',
        url:'/price-india'
    },
    {
        id:2,
        img:'images/flegs/tanzania-svg.svg',
        url:'/price-tanzania'
    },
    {
        id:3,
        img:'images/flegs/united-arab-emirates-svg.svg',
        url:'/price-united-arab-emirates'
    },
    // {
    //     id:4,
    //     img:'images/flegs/angola-svg.svg',
    //     url:'/price-angola'
    // }
]