import React from 'react'

export default function IconMyAccount() {
    return (
        <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.9721 29.8991C39.0385 24.8487 34.7992 21.0371 29.7078 21.0371C24.6164 21.0371 20.3771 24.8487 19.4434 29.8991C19.241 30.9891 20.0628 31.9998 21.1204 31.9998H38.2921C39.3527 31.9998 40.1716 30.9922 39.9691 29.8991H39.9721Z" fill="url(#paint0_linear_546_112)" />
            <path d="M29.7075 18.4613C32.5744 18.4613 34.8986 16.0243 34.8986 13.018C34.8986 10.0118 32.5744 7.57471 29.7075 7.57471C26.8405 7.57471 24.5164 10.0118 24.5164 13.018C24.5164 16.0243 26.8405 18.4613 29.7075 18.4613Z" fill="url(#paint1_linear_546_112)" />
            <g filter="url(#filter0_b_546_112)">
                <path d="M25.7767 29.3672C24.6043 23.0367 19.2893 18.2556 12.9077 18.2556C6.52609 18.2556 1.2111 23.0367 0.038723 29.3672C-0.215091 30.736 0.812251 32.0002 2.14175 32.0002H23.6706C25.0001 32.0002 26.0275 30.736 25.7736 29.3672H25.7767Z" fill="url(#paint2_linear_546_112)" />
                <path d="M12.9077 19.2556C18.6728 19.2556 23.5444 23.5125 24.7386 29.2703L24.7904 29.5495C24.9367 30.3385 24.3396 31.0002 23.6706 31.0002H2.14175C1.47278 31.0002 0.875651 30.3385 1.02196 29.5495L1.022 29.5493C2.11443 23.6506 7.04987 19.2556 12.9077 19.2556Z" stroke="url(#paint3_linear_546_112)" strokeWidth="2" />
            </g>
            <path d="M14.3979 14.8451C17.8947 13.9797 20.0604 10.3057 19.2351 6.63898C18.4098 2.97227 14.906 0.701386 11.4092 1.56681C7.91236 2.43223 5.74669 6.10624 6.57201 9.77294C7.39734 13.4396 10.9011 15.7105 14.3979 14.8451Z" fill="url(#paint4_linear_546_112)" />
            <defs>
                <filter id="filter0_b_546_112" x="-18" y="0.255615" width="61.8123" height="49.7446" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="9" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_546_112" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_546_112" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_546_112" x1="29.7078" y1="38.0324" x2="29.7078" y2="5.07152" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F6005A" />
                    <stop offset="1" stopColor="#F7CBAD" />
                </linearGradient>
                <linearGradient id="paint1_linear_546_112" x1="29.7075" y1="-135.399" x2="29.7075" y2="5.07166" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F6005A" />
                    <stop offset="1" stopColor="#F7CBAD" />
                </linearGradient>
                <linearGradient id="paint2_linear_546_112" x1="1.45726" y1="31.2242" x2="11.5736" y2="12.2257" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0070F4" stopOpacity="0.2" />
                    <stop offset="1" stopColor="#0070F4" stopOpacity="0.49" />
                </linearGradient>
                <linearGradient id="paint3_linear_546_112" x1="1.82206" y1="19.3366" x2="11.0118" y2="37.7899" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0070F4" />
                    <stop offset="1" stopColor="#0070F4" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_546_112" x1="-27.8421" y1="-172.817" x2="14.3388" y2="-2.38125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F6005A" />
                    <stop offset="1" stopColor="#F7CBAD" />
                </linearGradient>
            </defs>
        </svg>


    )
}
