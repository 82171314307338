import React, { useState } from 'react';
import './style.css';
import { Technology } from './TechnologyData';

export default function OurTechnology() {
  const [tooltipIndex, setTooltipIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setTooltipIndex(index);
  };

  const handleMouseLeave = () => {
    setTooltipIndex(null);
  };

  return (
    <section className='OurTechnology-main mb100'>
      <div className='container'>
        <div className="titletwo text-center">
          <h2><span>Our</span> Tech Stack</h2>
        </div>
        <div className='OurTechnology-boxmain d-flex flex-wrap gap-2 gap-sm-3  gap-md-4 justify-content-center'>
          {Technology.map((techlogo, index) => (
            <div
              className={`ourtechnology-box d-flex align-items-center justify-content-center ${tooltipIndex === index ? 'show-tooltip' : ''}`}
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <img src={techlogo.image} alt={techlogo.name} className='img-fluid' />
              <span className='tooltiptext'>{techlogo.name}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
