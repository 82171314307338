import React, { useState, useEffect, Fragment } from 'react';
import './style.css';
import { TallyProductData } from './TallyProductData';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowDown from '../SvgIcons/ArrowDown';

export default function ProductTallyPrimeTabs() {
  const [activeTab, setActiveTab] = useState(TallyProductData[0]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Import useNavigate instead of useHistory

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);    

    return () => {
      window.removeEventListener('resize', handleResize);      
    };
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const activeTabFromPath = TallyProductData.find(tab => tab.url === path);
    if (activeTabFromPath) {
      setActiveTab(activeTabFromPath);
    }
  }, [location]);

  const handleSelect = (event) => {
    const selectedTab = TallyProductData.find(tab => tab.id === parseInt(event.target.value));
    setActiveTab(selectedTab);
    navigate(selectedTab.url); 
    window.scrollTo({ top: 0 });
  };

  const ShowToponClick = (tab) => {
    setActiveTab(tab);
    navigate(tab.url); 
    window.scrollTo({ top: 0 });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleItemClick = () => {
    setIsOpen(false);
    window.scrollTo({ top: 0 });
  };
  return (
    <Fragment>
      <section className='tally-product primetabsmain'>
          <div className='container'>
          <div className="tallyAppsTabs-main d-flex justify-content-between align-items-center">
          <div className='tabsinleft'>
          <div className="btn-group">
            <Link onClick={toggleDropdown}>
              Tally Products <ArrowDown/>
            </Link>
              {isOpen && (
                <ul className="menuinner">
                  <li onClick={handleItemClick}><Link to="/tally-prime">Tally Products</Link></li>
                  <li onClick={handleItemClick}><Link to="/our-products">Our Solutions & Products</Link></li>
                </ul>
              )}
          </div>
          </div>
          <div className='tabsinright'>
            {windowWidth <= 575 ? (
              <div className='downlist'>
                <ArrowDown/>
              <select
                className="dropdown-menu" onChange={handleSelect} value={activeTab.id}>
                {TallyProductData.map((tab) => (
                  <option key={tab.id} value={tab.id}>
                    {tab.title}
                  </option>
                ))}
              </select>
              </div>
            ) : (
              <div className="primetab d-flex align-items-center justify-content-center">
                {TallyProductData.map((tab) => (
                  <Link key={tab.id}
                    to={tab.url} className={`tabitams ${ tab === activeTab ? 'active' : ''}`} onClick={() => ShowToponClick(tab)}>
                    {tab.title}
                  </Link>
                ))}
              </div>
            )}
            </div>
          </div>
        </div>
      </section>     
    </Fragment>
  );
}
