import React from 'react';
import { Link } from "react-router-dom";
import OurClientTallyserver from '../../component/OurClientTallyserver';
import PageLayout from '../../component/PageLayout';
import ProductTallyPrime from '../../component/Product-TallyPrime';
import IconRightArrow from '../../component/SvgIcons/IconRightArrow';
import IconTallyPrimelogo from '../../component/SvgIcons/IconTallyPrimelogo';
import './style.css';
import GoogleTagManager from '../../component/GoogleTagManager';

export default function TallyPrimeServer() {
  return (
    <div className='TallyPrimebg twotab'>
      <GoogleTagManager/>
      <PageLayout>
      <ProductTallyPrime/>
    <section className="TabFirstTallyPrime-tab pt50 pb100">
    <div className="container">
      <div
        className="d-flex flex-column align-items-center primeserver gap50">
     
        <div className='innertablogo'>
        <img
          src="images/TallyPrimeServer/tallyprime-serverlogo.svg"
          className="img-fluid"          
          alt="Tally Prime Server"
        />
        </div>
        <div
        className="gap50 d-flex mb100 flex-column flex-md-row align-items-center justify-content-between unleashmain">
    <div className="unleashmain-pic">
        <img
          src="images/TallyPrimeServer/TallyPrime-Server.png"
          className="img-fluid"          
          alt="Tally Prime Server"
        />
        </div>

        <div className="unleashmain-info">
          <h4>Unleash the power of Tally with a Client-Server Architecture</h4>
          <p>Tally Server offers powerful data server capabilities over the Gold license. 
            This server-based Data Architecture unleashes greater concurrency, secured data access, 
            and advanced monitoring capabilities. Tally Server is truly an Enterprise Class product that helps 
            fast-growing medium and large businesses improve their business efficiencies.</p>
        <div
          className="button d-flex gap50">
          <a href="images/satup/TallyPrimeServer_setup4-0.exe" download className="btn btn-danger">
            <label>Download Now <IconRightArrow /></label><span></span>
          </a>
          <Link to="/contact" onClick={() => { window.scrollTo({ top: 0 });}} className="btn btn-dark">
            <label>Contact Us Now <IconRightArrow /></label><span></span>
          </Link>
        </div>
        </div>
        </div>
      </div>
    
    <div className='titletwo twotabintaly text-center'>
      <h2>Key <span>features</span></h2>
    </div>

      <div
        className="keyfeatures-main flex-column flex-md-row d-flex gap30">
        <div className="keyfeatures-picture box-default justify-content-center d-flex align-items-center">
          <img
            src="images/TallyPrimeServer/high-concurrency.png"
            alt="High Concurrency"
          />
        </div>
        <div className="keyfeatures-information box-default d-flex justify-content-center flex-column">
          <h5>
          High Concurrency
          </h5>
          <p><strong>Performing operations in a frictionless environment</strong> </p>
          <p>
          Multiple users will be able to simultaneously load companies, save transactions, export reports, print reports, import data, take backup and perform other data related activities without experiencing any downtime. This will directly result in optimal utilization of man hours as the velocity at which a task gets completed will increase. Further, the users will be able to seamlessly work on the same data. Your Data will always be real-time, consistent, and accurate.
          </p>
        </div>
      </div>

      <div
        className="keyfeatures-main d-flex flex-md-row-reverse flex-column flex-md-row flex-sm-column gap30">
        <div className="keyfeatures-picture box-default justify-content-center d-flex align-items-center">
          <img
            src="images/TallyPrimeServer/secureddesign.png"
            alt="Secured by design"
          />
        </div>
        <div className="keyfeatures-information box-default d-flex justify-content-center flex-column">
          <h5>
          Secured by design
          </h5>
          <p><strong>Providing controlled access to data files</strong> </p>
          <p>
          When data files are managed through Tally Server, the location of the data on the server is not required. The name of the data server is sufficient to access and operate Tally. Operations over the company data like backup and restore are controlled with server level permissions available with authorization only. This ensures better control over user’s access to data.
          </p>
        </div>
      </div>

      <div
        className="keyfeatures-main d-flex flex-column flex-md-row gap30">
        <div className="keyfeatures-picture box-default justify-content-center d-flex align-items-center">
          <img
            src="images/TallyPrimeServer/high-reliability.png"
            alt="High Reliability"
          />
        </div>
        <div className="keyfeatures-information box-default d-flex justify-content-center flex-column">
          <h5>
          High Reliability
          </h5>
          <p><strong>Minimizing instances of system unavailability</strong> </p>
          <p>
          Continue recording transactions or viewing reports while taking backup with no compromise on speed or access or accuracy. It also limits discrepancies at the client end to the client systems and ensures that neither data on the server nor the operations of other users get impacted. Thus, improves work productivity and business performance by avoiding disruption of work and ensuring zero downtime.
          </p>
        </div>
      </div>

      <div
        className="keyfeatures-main d-flex flex-md-row-reverse flex-column flex-md-row flex-sm-column gap30">
        <div className="keyfeatures-picture box-default justify-content-center d-flex align-items-center">
          <img
            src="images/TallyPrimeServer/optimization.png"
            alt="Business Process Optimization"
          />
        </div>
        <div className="keyfeatures-information box-default d-flex justify-content-center flex-column">
          <h5>
          Business Process Optimization
          </h5>
          <p><strong>Increased Productivity & Business Performance</strong> </p>
          <p>
          A comprehensive monitoring capability allows authorized users to manage user sessions, such as who is logged in and activities in progress. Also, with the ability to track, monitor user activities /sessions and even disconnect users, if required, it helps to optimize processes or system usage for enhanced productivity.
          </p>
        </div>
      </div>
    </div>
   
    <OurClientTallyserver/>
    <div className='container'>
    <div className="tallypricebox-main d-flex justify-content-center">
          <div className="tallypricebox box-default goldcolor text-center">
            <div className="tallylogo">
              <IconTallyPrimelogo />
            </div>
            <h4>Gold</h4>
            <p>
              <span>
              For medium and large enterprises
              (Tally Server works with Tally Gold license)
              </span>
            </p>
            <h1>₹2,70,000</h1>
            <p>+18% GST(₹48,600)</p>
            <div className="buybtn">
              <Link onClick={() => { window.scrollTo({ top: 0 });}} to="/contact" className="btn btn-purpal">
                <label>Buy Now <IconRightArrow /></label><span></span>
              </Link>
            </div>
          </div>
        </div>
        </div>

  </section>
  </PageLayout>
  </div>
  )
}
