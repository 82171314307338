import React from 'react'

export default function IconTallyIntegrators() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.71952 15.0571C4.94894 13.4601 6.3263 12.2286 7.98587 12.2286C9.64545 12.2286 11.0227 13.4601 11.2522 15.0571H15.0571V9.40013H16.4714C17.2512 9.40013 17.8856 8.76566 17.8856 7.98587C17.8856 7.20609 17.2512 6.57162 16.4714 6.57162H15.0571V0C6.95485 0.466892 0.467332 6.94825 0 15.0571H4.71952Z" fill="#FBBE24" />
            <path d="M27.2804 16.9429C27.051 18.5399 25.6735 19.7714 24.014 19.7714C22.3545 19.7714 20.9772 18.5399 20.7477 16.9429H16.9428V22.5999H15.5285C14.7487 22.5999 14.1143 23.2343 14.1143 24.0141C14.1143 24.7939 14.7487 25.4284 15.5285 25.4284H16.9428V32C25.0447 31.5331 31.5326 25.0519 31.9999 16.9429H27.2804Z" fill="#FBBE24" />
            <path d="M16.9429 4.71952C18.5399 4.94894 19.7714 6.3263 19.7714 7.98587C19.7714 9.64545 18.5399 11.0227 16.9429 11.2522V15.0571H22.5999V16.4714C22.5999 17.2512 23.2343 17.8856 24.0141 17.8856C24.7939 17.8856 25.4284 17.2512 25.4284 16.4714V15.0571H32C31.5331 6.95517 25.052 0.467332 16.9429 0V4.71952Z" fill="#FBBE24" />
            <path d="M15.0571 27.2804C13.46 27.051 12.2286 25.6735 12.2286 24.014C12.2286 22.3545 13.46 20.9772 15.0571 20.7477V16.9428H9.40013V15.5285C9.40013 14.7487 8.76572 14.1143 7.98587 14.1143C7.20602 14.1143 6.57162 14.7487 6.57162 15.5285V16.9428H0C0.466892 25.0448 6.948 31.5326 15.0571 31.9999V27.2804Z" fill="#FBBE24" />
        </svg>

    )
}
