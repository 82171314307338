import React from "react";
import { Link } from "react-router-dom";
import PageLayout from "../../component/PageLayout";
import ProductTallyPrimeTabs from "../../component/Product-TallyPrime";
import IconRightArrow from "../../component/SvgIcons/IconRightArrow";
import IconTallyPrimelogo from "../../component/SvgIcons/IconTallyPrimelogo";
import './style.css';
import GoogleTagManager from "../../component/GoogleTagManager";

export default function TallyPrimeusingAWS() {
  return (
    <div className='TallyPrimebg thirdtab'>
      <GoogleTagManager/>
    <PageLayout>
    <ProductTallyPrimeTabs/>
    <section className="TabfourTallyPrime-tab newdesign pt50">
    <div className="container">

    <div className="pb50 text-center d-flex flex-column gap50" >
      <div className="awsservice d-flex align-items-center justify-content-center  gap50">
        <IconTallyPrimelogo />
        <span>X</span>
        <img src="images/TallyPrimeusingAWS/aws.png" alt="aws service" />
        </div> 
        <Link className="management">Best Business Management Software, <span>Now on Cloud</span></Link>         
        </div>
        
      <div className="PrimeusingAWS mb100">
      <div className="titletwo">
       <h2>Best solution to access your <span>Tally, anytime, anywhere</span></h2> 
       </div> 
       <div className="best-innerbox d-flex flex-column-reverse flex-md-row align-items-center">
       <div className="best-solution">
       <p>
       Tally powered by AWS helps you to securely access Tally and your business data anytime, anywhere. 
       It’s enabled by a virtual machine dedicated to your business that is made easily available via our 
       strong network of partners. So, you get greater flexibility to collaborate with multiple users 
       working on the same data, at the same time.
       </p>
      <ul className="pt50 pb50">
        <li>Reliable</li> <li>Secure</li><li>Simple</li><li>Affordable</li>
        </ul>

        <div
          className="button justify-content-center justify-content-md-start d-flex gap50">
          <Link to="/contact" onClick={() => { window.scrollTo({ top: 0 });}} className="btn btn-dark">
            <label>Contact Us Now <IconRightArrow /></label><span></span>
          </Link>
        </div>
        </div>
        <img
          src="images/TallyPrimeusingAWS/best-solution.png"
          className="img-fluid fadeIn"
          alt="Tally Prime"
        />
        </div> 
      </div>

    <div className="titletwo thirdtabthere text-center">
      <h2>Why choose tally powered by <span>AWS?</span></h2>
    </div>

      <div
        className="aws-serviceuse-first">
        <div className="aws-serviceuse-picture">
          <img
            src="images/TallyPrimeusingAWS/reliability.svg"
            alt="Your growth Partner"
          />
        </div>
        <div className="aws-serviceuse-information">
          <h4>
          Reliability
          </h4>
          <p>
          You are familiar with the trust and simplicity of Tally products, and with AWS you will find expertise in 
          cloud platforms. Tally on AWS has been worked upon by teams at Tally & AWS to make sure it works for you 
          anytime, anywhere. To know more about how AWS ensures high availability and security of data
          </p>
        </div>
      </div>
      
      <div
        className="aws-serviceuse-second">
        <div className="aws-serviceuse-picture">
          <img
            src="images/TallyPrimeusingAWS/everything.svg"
            alt="Your growth Partner"
          />
        </div>
        <div className="aws-serviceuse-information">
          <h4>
          Everything you need in one place
          </h4>
          <p>
          If you choose to go with this option, all you need to do is talk to your Tally partner about it. They will lead the entire engagement from requirements gathering to implementation and even offer post-sales support. You don’t need to consult multiple vendors for your solution or for any concern related to it.
          </p>
        </div>
      </div>

      <div
        className="aws-serviceuse-thierd">
        <div className="aws-serviceuse-picture">
          <img
            src="images/TallyPrimeusingAWS/economical.svg"
            alt="Your growth Partner"
          />
        </div>
        <div className="aws-serviceuse-information">
          <h4>
          Economical
          </h4>
          <p>
          Tally on AWS will be available to you at very affordable price points, worked out especially for the needs of your business.
          </p>
        </div>
      </div>

      <div
        className="aws-serviceuse-fourth">
        <div className="aws-serviceuse-picture">
          <img
            src="images/TallyPrimeusingAWS/flexibility.svg"
            alt="Your growth Partner"
          />
        </div>
        <div className="aws-serviceuse-information">
          <h4>
          Flexibility
          </h4>
          <p>
          Not sure which usage plan works best for you? Need to add more users? Busy month? You can seamlessly upgrade or downgrade your plan whenever you like, in a matter of minutes! A fully automated process will take care of your data and billing.
          </p>
        </div>
      </div>

      
      <div
        className="aws-serviceuse-fiveth">
        <div className="aws-serviceuse-picture">
          <img
            src="images/TallyPrimeusingAWS/platform-agnostic.svg"
            alt="Your growth Partner"
          />
        </div>
        <div className="aws-serviceuse-information">
          <h4>
          Platform Agnostic
          </h4>
          <p>
          The solution works across Windows, Mac, and Linux clients when accessed through a Chrome browser even when you have different systems with general configurations of either 64 bit or 32 bit across users and across office-home
          </p>
        </div>
      </div>
      
    </div>
  </section>
  </PageLayout>
  </div>
  )
}
