

export const LocationData= [
  {
  id:1,
  title:'India',
  address:'B/905-908, 9<sup>th</sup> floor, Sun WestBank, Near Vallabh Sadan, Ashram Road, Ahmedabad - 380009',
  email:'info@globalsoftwares.net',
  phone:'+91 79 6661 7000 (IND)',
  phonenumber:'+91 79 6661 7000',
  map:<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.7821414746427!2d72.56826257436794!3d23.03177001598664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84592ebaaaab%3A0xb7394aa27743e449!2sSun%20Westbank!5e0!3m2!1sen!2sin!4v1712753236779!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>,
},
{
  id:2,
  title:'Tanzania',
  address:'PHOENIX HOUSE, 3<sup>rd</sup> Floor, Plot 719 /11, Azikiwe Street Dar Es Salaam, Tanzania.',
  email:'info@globalinfosoft.net',
  phone:'+255 788 373 250 (TZ)',
  phonenumber:'+255 788 373 250',
  map:<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.6114318565883!2d39.27894018885498!3d-6.817024499999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4b08b878c235%3A0x164c8e8ab102304e!2sPhoenix!5e0!3m2!1sen!2sin!4v1712750338801!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>,
},
{
  id:3,
  title:'U.a.e',
  address:'1909, 19<sup>th</sup> Floor, Fahidi Heights, Nr. Al Fahidi Metro Station, Bur Dubai, Dubai, U.A.E.',
  email:'dubai@globalsoftwares.net',
  phone:'+971 4 8866551 (UAE)',
  phonenumber:'+971 4 8866551',
  map:<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14432.535283250252!2d55.29071133893689!3d25.26608339774226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f433b903adf3b%3A0x9fb693268cbdbce1!2sAl%20Musallah%20Tower!5e0!3m2!1sen!2sin!4v1712750429638!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
},
{
  id:4,
  title:'Angola',
  address:'108, Ambriz Filda Apartments, Luanda, Angola',
  email:'tallyangola@globalsoftwares.net',
  phone:'+244 935 120 574 (AGO)',
  phonenumber:'+244 935 120 574',
  map:<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3942.3118638915416!2d13.285839574139235!3d-8.85052959064877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f147e3e91627%3A0xe3b36a9c7a33747a!2sFilda%20station!5e0!3m2!1sen!2sin!4v1712748776670!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>,
}
]
