import React from "react";
import { Link } from "react-router-dom";
import PageLayout from "../../component/PageLayout";
import {
  OurModualesOne,
  OurModualesThree,
  OurModualesTwo,
} from "./OurModuales";
import { OurVerticalSolutions, ProductsData } from "./ProductsData";
import "./style.css";
import GoogleTagManager from "../../component/GoogleTagManager";
import TallyPrimeRelease from "../../component/TallyPrimeRelease";
import CanvasComponent from "../../Canvas";

export default function OurProducts() {
  return (
    <div className="home-main">
      <GoogleTagManager/>
      <PageLayout>
      <div className="integratedanimation">
        <section className="ourproducts-banner box-default">
          <div className="ourproducts-main d-flex flex-column-reverse flex-md-row justify-content-between align-items-center gap100">
            <div
              className="titletwo thirdtabthere">
              <h2>
                <span>Unleash Possibilities</span> with Our Bespoke Products
                Offerings.
              </h2>
            </div>
            <div className="banner-ourproducts">
              <img
                className="img-fluid"
                src="images/Our-Products/productsofferings.svg"
                alt="Unleash Possibilities with Our Bespoke Products Offerings."
              />
            </div>
          </div>
        </section>
    
      <div className="redcolor"></div>      
          <section className="integrated-apps">
          <div className="container">
          <div className="integrated-inner">
            <div
              className="titletwo thirdtabthere">
              <h2>
                <span>//</span> 01. Tally Integrated mobile apps
              </h2>
              <p>
                High-impact services to take your business to the next level
              </p>
            </div>
            <div className="integrated-box-main d-flex justify-content-between flex-wrap">
              {ProductsData.map((productItem, index) => (
                <div
                  className="integrated-box position-relative"
                  key={productItem.id}>
                  <img
                    className="img-fluid"
                    src={productItem.img}
                    alt={productItem.title}
                  />
                  <h4>{productItem.title}</h4>
                  <div className="custom-tooltip position-absolute start-50 translate-middle-x">
                    {productItem.des}
                  </div>
                </div>
              ))}
            </div>
          </div>
          </div>
        </section>
        <div className="blackcolor"></div>    
        <section className="integrated-appsfullsection">
          <div className="container">
            <div className="revolutionizes-second d-flex justify-content-between flex-column">
              <div className="revolutionizes">
              <div
              className="titletwo">
              <h2>
                <span>//</span> 02. Our Vertical Solutions
              </h2>
            </div>
                <p>
                  Global Software revolutionizes the Tally experience for
                  businesses in diverse sectors, including Supermarkets, Petrol
                  Pumps, Schools, and the Automobile industry, through our
                  specialized customization solutions. These bespoke modules
                  seamlessly integrate with Tally, optimizing operations such as
                  inventory management in Supermarkets, transaction tracking in
                  Petrol Pumps, and enhancing School Management systems. Our
                  customization services are flexible, allowing businesses to
                  adapt Tally to unique industry requirements, providing
                  precision and control tailored to the specific needs of
                  Supermarkets, Petrol Pumps, Schools, and the Automotive
                  sector. By empowering businesses with advanced Tally
                  customizations, we ensure a competitive edge through modules
                  that redefine efficiency and accuracy. Global Software's
                  expertise in industry-specific solutions transforms financial
                  and operational processes, offering Supermarkets, Petrol
                  Pumps, Schools, and Automotive businesses unparalleled
                  strategic advantages.
                </p>
              </div>
              <div className="revolutionizesapps d-flex flex-wrap justify-content-between text-center">
                {OurVerticalSolutions.map((solution, index) => (
                  <Link
                    to={solution.url}
                    className="solutionbox"
                    key={solution.id}>
                    <div className="solutionicon d-flex align-items-center justify-content-center">
                      <img src={solution.img} alt={solution.title} />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: solution.title }} />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </section>
        <div className="redcolor"></div> 
        <div className="TallyPrimeRelease-ourproducts">
        <TallyPrimeRelease />          
        </div>
        <div className="redcolor"></div>
        <section className="integrated-apps">
          <div className="container">
            <div
              className="titletwo thirdtabthere">
              <h2>
                <span>//</span> 04. Our Plug & Play Module
              </h2>
            </div>
            <div className="solution-box-main d-flex gap30 justify-content-between flex-column flex-sm-row">
              <div className="modual-box d-flex gap20 flex-wrap">
                {OurModualesOne.map((modualfirst, index) => (
                  <Link
                    to={modualfirst.url}
                    key={modualfirst.id}
                    className="d-flex align-items-center">
                    <div className="modualicon d-flex align-items-center justify-content-center">
                      <img src={modualfirst.img} alt={modualfirst.title} />
                    </div>
                    <p
                      dangerouslySetInnerHTML={{ __html: modualfirst.title }}
                    />
                  </Link>
                ))}
              </div>

              <div className="modual-box d-flex gap20 flex-wrap">
                {OurModualesTwo.map((modualtwo, index) => (
                  <Link
                    to={modualtwo.url}
                    key={modualtwo.id}
                    className="d-flex align-items-center">
                    <div className="modualicon d-flex align-items-center justify-content-center">
                      <img src={modualtwo.img} alt={modualtwo.title} />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: modualtwo.title }} />
                  </Link>
                ))}
              </div>

              <div className="modual-box d-flex gap20 flex-wrap">
                {OurModualesThree.map((modualthree, index) => (
                  <Link
                    to={modualthree.url}
                    key={modualthree.id}
                    className="d-flex align-items-center wow fadeIn"
                    data-wow-duration="1s"
                    data-wow-delay={`${0.5 * (index + 0.1)}s`}
                  >
                    <div className="modualicon d-flex align-items-center justify-content-center">
                      <img src={modualthree.img} alt={modualthree.title} />
                    </div>
                    <p
                      dangerouslySetInnerHTML={{ __html: modualthree.title }}
                    />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </section> 
        <div className="blackcolor"></div>         
        <CanvasComponent/>
        </div>        
      </PageLayout>
    </div>
  );
}
