import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import './style.css';

export default function Counter() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(true);
        }, 300);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <section className="pb100 counter-main">
            <div className='container'>
                <div className='countupmain'>
                    <div className='row justify-content-center'>
                        <div className='col-6 col-sm-4 col-md-4 col-lg-2 text-center'>
                            <div className='box-default'>
                                <CountUp start={isVisible ? 0 : null} end={22} suffix="Y" />
                                <p>Experience</p>
                            </div>
                        </div>
                        <div className='col-6 col-sm-4 col-md-4 col-lg-2 text-center'>
                            <div className='box-default'>
                                <CountUp start={isVisible ? 0 : null} end={12} suffix="K+" />
                                <p>Happy Customers</p>
                            </div>
                        </div>
                        <div className='col-6 col-sm-4 col-md-4 col-lg-2 text-center'>
                            <div className='box-default'>
                                <CountUp start={isVisible ? 0 : null} end={50} suffix="+" />
                                <p>Employees</p>
                            </div>
                        </div>
                        <div className='col-6 col-sm-4 col-md-4 col-lg-2 text-center'>
                            <div className='box-default'>
                                <CountUp start={isVisible ? 0 : null} end={4} />
                                <p>Countries</p>
                            </div>
                        </div>
                        <div className='col-6 col-sm-4 col-md-4 col-lg-2 text-center'>
                            <div className='box-default'>
                                <CountUp start={isVisible ? 0 : null} end={5400} suffix="+" />
                                <p>Project Completed</p>                            
                            </div>
                        </div>
                        <div className='col-6 col-sm-4 col-md-4 col-lg-2 text-center'>
                            <div className='box-default'>
                                <CountUp start={isVisible ? 0 : null} end={16} suffix="+" />
                                <p>Countries Served</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
