import React from 'react';
import { LocationData } from '../../component/Footer/LocationData';
import PageLayout from '../../component/PageLayout';
import IconCall from '../../component/SvgIcons/IconCall';
import IconEmail from '../../component/SvgIcons/IconEmail';
import './style.css';
import GoogleTagManager from '../../component/GoogleTagManager';

export default function Contact() {
  return (
    <div className="home-main">
      <GoogleTagManager/>
      <PageLayout>
        <div className='Innerpage-title'>
          <h1 className='title1 text-center box-default'>Contact</h1>
        </div>
        <section className='pt50 contactpage-main'>
         <div className='container'>
        <div className='titletwo pt50'>
        <h2><span>Our</span> Office Locations</h2>
        </div>
        <div className='contact-mainfirst flex'>
        <div className="contact-box d-flex flex-wrap justify-content-between flex-column flex-sm-row flex-wrap">
          {LocationData.map((location) => (
            <div className="contact" key={location.id}>
              <h5>{location.title}</h5>
              <p dangerouslySetInnerHTML={{ __html: location.address }} />
              <a href={`mailto:${location.email}`}><div className='infoicon'><IconEmail/></div> {location.email}</a>
              <a href={`tel:${location.phonenumber}`}><div className='infoicon'><IconCall/> </div> {location.phone}</a>
              {location.map}
            </div>
          ))}
           
        </div>
            
        </div>    

        </div>
        </section>        
      </PageLayout>
    </div>
  );
}
