import React from 'react'

export default function IconTallyprime() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.86102e-05 0.0664062H17.2891V8.99652H2.86102e-05V0.0664062Z" fill="#3069B1" />
            <path d="M0.000340462 9.00439H8.64648V17.9334H0.000340462V9.00439Z" fill="#FFC032" />
            <path d="M8.64715 9.00439H17.29V17.9334H8.64715V9.00439Z" fill="#90C4E9" />
        </svg>

    )
}
