import React from "react";

export default function IconEmail() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15_604)">
        <path
          d="M15.9997 0C7.1635 0 0.00012207 7.16359 0.00012207 15.9998C0.00012207 24.8359 7.1635 32 15.9997 32C24.836 32 31.9999 24.8359 31.9999 15.9998C31.9999 7.16359 24.836 0 15.9997 0ZM16.0001 5.6199L25.4682 11.524H6.53192L16.0001 5.6199ZM25.5862 20.952H25.5852C25.5852 21.899 24.8178 22.6664 23.8709 22.6664H8.12927C7.18227 22.6664 6.41491 21.8989 6.41491 20.952V11.8996C6.41491 11.7995 6.42515 11.7021 6.44179 11.6064L15.4881 17.2473C15.4992 17.2543 15.511 17.2589 15.5224 17.2653C15.5344 17.272 15.5465 17.2784 15.5587 17.2844C15.6226 17.3174 15.6884 17.344 15.7557 17.3613C15.7626 17.3633 15.7696 17.3641 15.7765 17.3657C15.8503 17.3831 15.9251 17.394 15.9997 17.394H16.0003C16.0008 17.394 16.0013 17.394 16.0013 17.394C16.076 17.394 16.1508 17.3834 16.2246 17.3657C16.2315 17.364 16.2385 17.3633 16.2454 17.3613C16.3126 17.344 16.3782 17.3174 16.4424 17.2844C16.4546 17.2784 16.4667 17.272 16.4787 17.2653C16.49 17.2589 16.5019 17.2543 16.513 17.2473L25.5593 11.6064C25.576 11.7021 25.5862 11.7993 25.5862 11.8996V20.952Z"
          fill="#513F95"
        />
      </g>
      <defs>
        <clipPath id="clip0_15_604">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
