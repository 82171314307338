import React from 'react'

export default function IconProductTallyPrime() {
    return (
        <svg width="365" height="74" viewBox="0 0 365 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_220)">
                <path d="M-2.28882e-05 0.000976562H73.827V36.9593H-2.28882e-05V0.000976562Z" fill="#3069B1" />
                <path d="M-5.34058e-05 36.9917H36.9204V73.9454H-5.34058e-05V36.9917Z" fill="#FFC032" />
                <path d="M36.9209 36.9917H73.8275V73.9454H36.9209V36.9917Z" fill="#90C4E9" />
                <path d="M112.055 58.6246V22.5126H98.314V17.6795H131.372V22.5126H117.571V58.6246H112.055Z" fill="#3069B1" />
                <path d="M149.206 43.7377C147.349 44.4824 144.559 45.116 140.839 45.6386C138.737 45.9346 137.249 46.2722 136.376 46.6422C135.503 47.0122 134.833 47.5626 134.357 48.2749C133.886 48.9917 133.645 49.7919 133.645 50.666C133.645 52.0072 134.163 53.1219 135.198 54.0145C136.228 54.9117 137.744 55.3557 139.73 55.3557C141.703 55.3557 143.459 54.9349 144.997 54.0839C146.531 53.2375 147.659 52.0812 148.379 50.6105C148.929 49.4727 149.206 47.7985 149.206 45.5785V43.7377ZM149.632 54.9672C147.737 56.549 145.908 57.6636 144.157 58.3157C142.401 58.9679 140.52 59.2962 138.511 59.2962C135.193 59.2962 132.638 58.4961 130.855 56.9051C129.076 55.3141 128.184 53.2791 128.184 50.8047C128.184 49.3525 128.517 48.0251 129.192 46.8226C129.866 45.6247 130.749 44.6581 131.839 43.932C132.929 43.2059 134.158 42.6601 135.521 42.2855C136.529 42.0265 138.044 41.7721 140.072 41.5316C144.207 41.046 147.252 40.4679 149.206 39.7972C149.225 39.1127 149.234 38.6734 149.234 38.4884C149.234 36.4395 148.749 34.9965 147.783 34.1594C146.476 33.0216 144.531 32.4574 141.953 32.4574C139.541 32.4574 137.762 32.869 136.616 33.6969C135.47 34.5247 134.625 35.9909 134.075 38.0952L129.067 37.4246C129.52 35.3249 130.268 33.6229 131.312 32.3279C132.356 31.0329 133.863 30.0385 135.835 29.3401C137.808 28.6417 140.091 28.2949 142.692 28.2949C145.27 28.2949 147.368 28.5909 148.975 29.1875C150.593 29.7841 151.775 30.5334 152.533 31.4352C153.295 32.3371 153.822 33.4795 154.127 34.8577C154.298 35.7134 154.381 37.2581 154.381 39.492V46.1982C154.381 50.8695 154.492 53.8249 154.709 55.0644C154.926 56.3039 155.361 57.4879 156.007 58.6256H150.653C150.126 57.5804 149.784 56.3594 149.632 54.9672Z" fill="#3069B1" />
                <path d="M167.322 17.6823H162.203V58.6274H167.322V17.6823Z" fill="#3069B1" />
                <path d="M180.268 17.6823H175.149V58.6274H180.268V17.6823Z" fill="#3069B1" />
                <path d="M187.984 70.047L187.416 65.3249C188.534 65.6255 189.509 65.7735 190.345 65.7735C191.482 65.7735 192.392 65.5885 193.076 65.2185C193.76 64.8439 194.319 64.3213 194.753 63.6506C195.076 63.1511 195.594 61.9024 196.319 59.909C196.412 59.6315 196.564 59.2199 196.772 58.6788L185.309 28.9631H190.826L197.118 46.1404C197.927 48.3188 198.662 50.6081 199.304 53.0085C199.891 50.7006 200.593 48.4483 201.411 46.2514L207.87 28.9631H212.989L201.494 59.1274C200.26 62.3834 199.304 64.6311 198.625 65.8568C197.71 67.5171 196.67 68.7289 195.492 69.5013C194.319 70.2736 192.914 70.6621 191.283 70.6621C190.294 70.6621 189.199 70.454 187.984 70.047Z" fill="#3069B1" />
                <path d="M223.509 37.148H234.293C237.855 37.148 240.387 36.4959 241.889 35.1916C243.386 33.8874 244.134 32.0559 244.134 29.6879C244.134 27.9766 243.695 26.5105 242.813 25.2941C241.93 24.0731 240.766 23.2684 239.329 22.8753C238.4 22.6348 236.682 22.5099 234.182 22.5099H223.509V37.148ZM217.993 58.6265V17.6814H233.725C236.492 17.6814 238.608 17.8109 240.068 18.0699C242.115 18.4075 243.829 19.0458 245.215 19.9846C246.601 20.9235 247.715 22.2416 248.56 23.939C249.401 25.6318 249.822 27.4956 249.822 29.5214C249.822 33.004 248.694 35.9501 246.44 38.3644C244.185 40.774 240.105 41.9765 234.205 41.9765H223.509V58.6265H217.993Z" fill="#3069B1" />
                <path d="M256.138 58.6247V28.9646H260.744V33.4601C261.918 31.3557 263.003 29.9682 264.001 29.2976C264.995 28.6316 266.09 28.2939 267.286 28.2939C269.01 28.2939 270.765 28.8351 272.549 29.9127L270.788 34.5793C269.532 33.8532 268.284 33.4878 267.028 33.4878C265.909 33.4878 264.902 33.8208 264.015 34.4776C263.123 35.1389 262.49 36.0593 262.112 37.2341C261.539 39.0193 261.252 40.9757 261.252 43.0986V58.6247H256.138Z" fill="#3069B1" />
                <path d="M275.621 28.9627H280.74V58.6228H275.621V28.9627ZM275.621 17.6823H280.74V23.4636H275.621V17.6823Z" fill="#3069B1" />
                <path d="M288.539 58.6247V28.9646H293.118V33.1271C294.065 31.6748 295.331 30.5047 296.907 29.6213C298.478 28.7379 300.27 28.2939 302.28 28.2939C304.516 28.2939 306.351 28.7518 307.783 29.6629C309.22 30.5741 310.222 31.8506 310.814 33.4878C313.202 30.0237 316.316 28.2939 320.147 28.2939C323.141 28.2939 325.446 29.1079 327.058 30.7359C328.671 32.3686 329.475 34.8753 329.475 38.2654V58.6247H324.383V39.9397C324.383 37.9278 324.217 36.4802 323.884 35.5968C323.552 34.7134 322.951 34.0012 322.078 33.4601C321.205 32.9189 320.184 32.6507 319.01 32.6507C316.885 32.6507 315.12 33.3444 313.715 34.7319C312.315 36.1194 311.608 38.3394 311.608 41.3919V58.6247H306.489V39.3523C306.489 37.1184 306.073 35.4442 305.237 34.3249C304.405 33.2103 303.038 32.6507 301.143 32.6507C299.702 32.6507 298.367 33.0207 297.142 33.7653C295.923 34.5146 295.036 35.6014 294.486 37.0352C293.936 38.4689 293.659 40.5363 293.659 43.2373V58.6247H288.539Z" fill="#3069B1" />
                <path d="M340.942 40.9442H357.806C357.585 38.4514 356.938 36.5782 355.875 35.3341C354.244 33.3962 352.128 32.425 349.531 32.425C347.18 32.425 345.202 33.202 343.599 34.7467C342 36.2915 341.109 38.3589 340.942 40.9442ZM357.755 49.0704L363.041 49.7132C362.209 52.7519 360.666 55.106 358.407 56.7802C356.148 58.4591 353.269 59.2962 349.758 59.2962C345.341 59.2962 341.834 57.9596 339.247 55.2864C336.659 52.6131 335.366 48.8669 335.366 44.043C335.366 39.0572 336.673 35.1815 339.293 32.425C341.908 29.6685 345.299 28.2949 349.476 28.2949C353.514 28.2949 356.818 29.6454 359.373 32.3417C361.937 35.0427 363.212 38.8399 363.212 43.7377C363.212 44.0337 363.203 44.4824 363.184 45.079H340.656C340.845 48.3396 341.788 50.8325 343.474 52.5622C345.161 54.2966 347.263 55.1615 349.786 55.1615C351.666 55.1615 353.269 54.6759 354.595 53.7092C355.921 52.7426 356.975 51.1932 357.755 49.0704Z" fill="#3069B1" />
            </g>
            <defs>
                <clipPath id="clip0_13_220">
                    <rect width="365" height="74" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
