import React from "react";
import "./style.css";
import { LocationData } from "./LocationData";
import { Link } from "react-router-dom";
import IconLinkdin from "../SvgIcons/IconLinkdin";
import IconFacebook from "../SvgIcons/IconFacebook";
import IconInstagram from "../SvgIcons/IconInstagram";
import IconYoutube from "../SvgIcons/IconYoutube";
import IconCall from "../SvgIcons/IconCall";
import IconEmail from "../SvgIcons/IconEmail";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        <div className="location-main d-flex justify-content-between flex-column flex-sm-row flex-wrap">
          {LocationData.map((location) => (
            <div className="location-box" key={location.id}>
              <h5>{location.title}</h5>
              <p dangerouslySetInnerHTML={{ __html: location.address }} />
              <a href={`mailto:${location.email}`}>{location.email}</a>
              <a href={`tel:${location.phonenumber}`}>{location.phone}</a>
            </div>
          ))}
        </div>

        <div className="footer-second-part d-flex justify-content-between">
          <div className="footerlogoleft">
            <div className="d-flex justify-content-between">
            <Link
              to="/"
              onClick={() => {
                window.scrollTo({ top: 0 });
              }}
            >
              <img src="images/gs-logo1.png" className="img-fluid" alt="Global Software" />
            </Link>
          
            <Link className="d-sm-flex d-md-none d-flex logofivestart">
              <img className="img-fluid" src="images/5-star2.png" alt="5-star" />
            </Link>
            </div>

            <ul className="d-none d-sm-flex justify-content-start justify-content-md-between">
              <li>
                <Link to="https://www.linkedin.com/company/globalsoftwares/?viewAsMember=true" target="_blank">
                  <IconLinkdin />
                </Link>
              </li>
              <li>
                <Link to="https://www.facebook.com/Globaltally/" target="_blank">
                  <IconFacebook />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/globaltally" target="_blank">
                  <IconInstagram />
                </Link>
              </li>
              <li>
                <Link to="https://www.youtube.com/channel/UC7EtsYA82_Z6eKugK2XnBkg?view_as=subscriber" target="_blank">
                  <IconYoutube />
                </Link>
              </li>
              <li>
                <a href="tel:+91 79 6661 7000">
                  <IconCall />
                </a>
              </li>
              <li>
                <a href="mailto:info@globalsoftwares.net">
                  <IconEmail />
                </a>
              </li>
            </ul>
          </div>
          <div className="quicklinks">
            <h5>Quick Links</h5>
            <ul className="footermenu d-flex justify-content-center justify-content-sm-start justify-content-md-between">
              <li>
                <Link to="/career" onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}>Career</Link>
              </li>
              <li>
                <Link to="https://globalinfosoft.net/crf/" target="_blank">Login</Link>
                
              </li>
              <li>
                <Link to="/privacy-policy" onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}>Privacy Policy</Link>
              </li>
              <li>
                <Link
                  to="/terms-and-condition"
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                  }}
                >
                  Terms & Condition
                </Link>
              </li>
            </ul>
            <ul className="flegs d-flex justify-content-md-between justify-content-sm-end justify-content-center">
              <li>
                <Link to="https://globalsoftwares.net/">
                  <img src="images/flegs/india.png" className="img-fluid" alt="India" title="India"/>
                </Link>
              </li>
              <li>
                <Link to="https://www.tgstl.net">
                  <img src="images/flegs/tanzania.png" className="img-fluid" alt="Tanzania" title="Tanzania" />
                </Link>
              </li>
              <li>
                <Link>
                  <img
                  className="img-fluid" 
                    src="images/flegs/united-arab-emirates.png"
                    alt="U.A.E"
                    title="U.A.E"
                  />
                </Link>
              </li>
              <li>
                <Link to="http://tallyangola.com/">
                  <img title="Angola" className="img-fluid" src="images/flegs/angola.png" alt="Angola" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="footerlogoright d-none d-md-flex align-items-center justify-content-center">
            <Link>
              <img className="img-fluid" src="images/5-star2.png" alt="5-star" />
            </Link>
          </div>

          <ul className="d-sm-none d-flex justify-content-center bottomlinks">
              <li>
              <Link to="https://www.linkedin.com/company/globalsoftwares/?viewAsMember=true" target="_blank">
                  <IconLinkdin />
                </Link>
              </li>
              <li>
                <Link to="https://www.facebook.com/Globaltally/" target="_blank">
                  <IconFacebook />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/globaltally" target="_blank">
                  <IconInstagram />
                </Link>
              </li>
              <li>
                <Link to="https://www.youtube.com/channel/UC7EtsYA82_Z6eKugK2XnBkg?view_as=subscriber" target="_blank">
                  <IconYoutube />
                </Link>
              </li>
              <li>
                <a href="tel:+91 79 6661 7000">
                  <IconCall />
                </a>
              </li>
              <li>
                <a href="mailto:info@globalsoftwares.net">
                  <IconEmail />
                </a>
              </li>
            </ul>

        </div>
      </div>
      <div className="copyright">
        <div className="container">
        <div className="d-flex justify-content-center  gap-1 align-items-center flex-wrap">
        {/* justify-content-sm-between */}
          {/* <div className="reviewcount d-flex justify-content-center gap-1 align-items-center">
          <img src="../images/google-review.png" alt="google review" className="googlereview"/>        
          <img src="../images/review-star.png" alt="review-star" className="startreview"/>        
          <p>4.3</p>
          </div> */}
        © 2002-{currentYear} Global Software. All Rights Reserved
        </div>
        </div>
      </div>
      
    </footer>
  );
}
