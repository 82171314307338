import React from 'react'
import IconProductTallyPrime from '../SvgIcons/IconProductTallyPrime'
import IconTallyPrimeServer from '../SvgIcons/IconTallyPrimeServer'
import Iconshoper9 from '../SvgIcons/Iconshoper9'
import './style.css'

export default function TallyProducts() {
    return (
<section className='pt100 pb100  box-default tally-products'>
<div className="rain">
      <div className="drop d1"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d2"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d3"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d4"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d5"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d6"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d7"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d8"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d9"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d10"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d11"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d12"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d13"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d14"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
      <div className="drop d15"><img src='../images/tally-logo-center.png' alt='tally prime'/> </div>
    </div>

            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='titletwo text-center'>
                            <h2>Tally <span>products</span></h2>
                        </div>
                    </div>
                </div>
                <div className='tallyproducts-main d-flex align-items-center justify-content-between'>

                    <div className='tallyproductsBox text-center'>
                        <span>
                            <IconProductTallyPrime />
                        </span>
                        <p>Best Business Management<br></br> Software</p>

                    </div>

                    <div className='tallyproductsBox text-center'>
                        <span>
                            <IconTallyPrimeServer />
                        </span>
                        <p>Unleash the power of Tally with a <br></br>Client-Server Architecture</p>

                    </div>

                    <div className='tallyproductsBox text-center'>
                        <span>
                            <Iconshoper9 />
                        </span>
                        <p>Enterprise Retail Solution for<br></br>
                            all retail businesses</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
