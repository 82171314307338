import React from 'react'
import { Link } from 'react-router-dom'


export default function ApplicationScanApp() {
    return (
        <section className="applicationsupport-main pt50 mb100">
        <div className="container">
          <div className="app-boxmain box-default flex-column-reverse flex-sm-row flex-md-row d-flex align-items-center justify-content-between">
            <div className="app-left">
              <h1>
                Download our support <span>application</span>
              </h1>
              <p>
                Easy to use mobile app that support on android & now available
                in Tally.
              </p>
              <Link to="https://play.google.com/store/apps/details?id=globalsoftwares.com.global_query&pli=1" target='_blank'>
                <figure>
                  <img
                    src="images/playstore.png"
                    alt="playstore"
                    className="img-fluid"
                  />
                </figure>
              </Link>
            </div>
            <div className="app-right">
              <figure>
                <img
                  src="images/ServicesSupport/qrcode.svg"
                  alt="Download our support"
                  className="img-fluid"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
    )
}
