import React, { Fragment } from 'react'
import Header from '../Header'
import Footer from '../Footer'

export default function PageLayout({ children }) {
    return (
        <Fragment>
            <Header />
            {children}
            <Footer />
        </Fragment>
    )
}
