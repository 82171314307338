import React from 'react';
import { Logopictures } from './LogoPic';
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import './style.css';
const ourclients = {
  slidesPerView: "auto",
  loop: true,
  speed: 5000,
  autoplay: {    
    disableOnInteraction: false,
    delay: 1,
  },
};
export default function OurClient() {
  return (
    <section className='OurClient text-center'>
      <div className='container'>
        <div className="titletwo">
          <h2>Our <span>clients</span></h2>
        </div>
        <div className="clients-main box-default">
          <Swiper
            {...ourclients} modules={[Autoplay]}         
          >
            {Logopictures.map((clientlogo) => (
              <SwiperSlide key={clientlogo.id}>
                <div className='slide'><img src={clientlogo.img} alt="" className='img-fluid' /></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
