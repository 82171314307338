import React from "react";
import PageLayout from "../../component/PageLayout";
import TallySupportBoxes from "../../component/TallySupportBoxes";
import GoogleTagManager from "../../component/GoogleTagManager";

export default function AnnualMaintainanceContract() {
  return (
    <div className="home-main supportpage">
      <GoogleTagManager/>
      <PageLayout>
        <TallySupportBoxes />

        <div className="container">
          <div
            className="implementation"
          >
            <div className="titletwo">
              <h2>
                Annual Maintenance <span>Contract</span>
              </h2>
            </div>
            <p>
              We have various packages starting as low as Rs. 7200/- per year!
              Global Software provides direct access to the people who know
              Tally product best. Get peace of mind for a full year along with
              fast answers and how-to help directly from Global Software's
              support professionals. Use Expert Support to get the most out of
              the software you rely on. Stop wasting time trying to tackle
              problems alone. With unlimited calls and Web case submissions,
              you'll get accurate answers fast, so you can get back to work
              within no time.
            </p>
          </div>

          <div className="unmached-main mt100 mb100">
            <div className="titletwo">
              <h2>
                <span>Get Tally Annual Support Cover</span> - from the People
                who know Tally Best
              </h2>
            </div>
            <p>
              Extended evening and weekend hours and flexible service plan
              provide the support you need, when you need it.Save precious time
              by solving Tally issues with the Tally Experts. With unlimited
              calls, email support and now even Instant Remote Support, and
              personal visits as well.sWhether you are new to Tally or an
              experienced user, and regardless of the size of your organization,
              Global Software has the right Annual Support Cover Plan that work
              for you.Extended evening and weekend hours and flexible service
              plan provide the support you need, when you need it.Save precious
              time by solving Tally issues with the Tally Experts. With
              unlimited calls, email support and now even Instant Remote
              Support, and personal visits as well.sWhether you are new to Tally
              or an experienced user, and regardless of the size of your
              organization, Global Software has the right Annual Support Cover
              Plan that work for you.
            </p>
          </div>
        </div>
      </PageLayout>
    </div>
  );
}
