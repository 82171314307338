import React from 'react'

export default function IconTallyPrimeServer() {
    return (
        <svg width="259" height="74" viewBox="0 0 259 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M93.078 27.0652V3.17935H84V0H105.809V3.17935H96.7313V27.0652H93.078Z" fill="#3069B1" />
            <path d="M117.932 24.5924C116.686 25.6522 115.468 26.3859 114.306 26.8206C113.143 27.2554 111.898 27.4728 110.57 27.4728C108.383 27.4728 106.695 26.9565 105.505 25.8967C104.342 24.8641 103.733 23.5054 103.733 21.875C103.733 20.9239 103.955 20.0543 104.398 19.2663C104.868 18.4511 105.449 17.8261 106.169 17.3641C106.889 16.875 107.691 16.5217 108.604 16.2772C109.269 16.0869 110.265 15.9239 111.621 15.7609C114.334 15.4619 116.354 15.0543 117.655 14.6195C117.655 14.1848 117.682 13.8859 117.682 13.75C117.682 12.4185 117.35 11.4674 116.714 10.8967C115.856 10.163 114.555 9.78259 112.839 9.78259C111.261 9.78259 110.071 10.0543 109.324 10.625C108.549 11.1685 107.996 12.1195 107.636 13.5326L104.315 13.0706C104.619 11.6848 105.117 10.5435 105.809 9.70107C106.501 8.8315 107.497 8.17933 108.798 7.71737C110.099 7.25542 111.621 7.03802 113.337 7.03802C115.053 7.03802 116.437 7.22824 117.516 7.60868C118.596 8.01629 119.371 8.50542 119.869 9.10324C120.367 9.70107 120.727 10.4619 120.921 11.3859C121.031 11.9293 121.087 12.9619 121.087 14.4293V18.8315C121.087 21.9293 121.17 23.8587 121.308 24.7011C121.446 25.5163 121.723 26.3043 122.138 27.0652H118.596C118.264 26.3587 118.042 25.5435 117.932 24.5924ZM117.682 17.2282C116.437 17.7174 114.583 18.1522 112.119 18.4782C110.736 18.6685 109.739 18.8859 109.158 19.1304C108.577 19.4022 108.134 19.7554 107.83 20.2174C107.525 20.6793 107.359 21.1956 107.359 21.7935C107.359 22.663 107.691 23.3967 108.383 23.9946C109.075 24.5652 110.071 24.8641 111.4 24.8641C112.701 24.8641 113.863 24.5924 114.859 24.0217C115.883 23.4782 116.631 22.7174 117.101 21.7391C117.489 21.0054 117.655 19.8913 117.655 18.4511L117.682 17.2282Z" fill="#3069B1" />
            <path d="M126.235 27.0652V0H129.611V27.0652H126.235Z" fill="#3069B1" />
            <path d="M134.787 27.0652V0H138.163V27.0652H134.787Z" fill="#3069B1" />
            <path d="M143.256 34.5924L142.868 31.5218C143.616 31.712 144.252 31.8207 144.806 31.8207C145.553 31.8207 146.162 31.6848 146.605 31.4402C147.075 31.1957 147.435 30.8696 147.74 30.4076C147.933 30.0815 148.293 29.2663 148.764 27.9348C148.819 27.7446 148.93 27.4729 149.068 27.1196L141.485 7.44568H145.11L149.262 18.7772C149.815 20.1902 150.286 21.712 150.729 23.2881C151.116 21.8207 151.587 20.3261 152.112 18.8859L156.375 7.44568H159.751L152.223 27.3913C151.393 29.5381 150.756 31.0326 150.313 31.8479C149.705 32.9348 149.013 33.7229 148.238 34.2392C147.463 34.7555 146.522 35 145.442 35C144.778 35 144.059 34.8642 143.256 34.5924Z" fill="#3069B1" />
            <path d="M163.072 27.0652V0H173.478C175.305 0 176.717 0.0815218 177.685 0.244565C179.041 0.489131 180.176 0.89674 181.09 1.52174C182.003 2.14674 182.75 3.01631 183.304 4.13044C183.857 5.24457 184.134 6.4674 184.134 7.79892C184.134 10.1087 183.387 12.0381 181.892 13.6413C180.398 15.2446 177.713 16.0326 173.811 16.0326H166.725V27.0652H163.072ZM166.725 12.8533H173.866C176.218 12.8533 177.879 12.4185 178.875 11.5489C179.872 10.7065 180.37 9.4837 180.37 7.90762C180.37 6.79348 180.066 5.81522 179.484 5.02718C178.903 4.21196 178.128 3.66848 177.187 3.42392C176.578 3.26087 175.444 3.17935 173.783 3.17935H166.725V12.8533Z" fill="#3069B1" />
            <path d="M188.286 27.0652V7.44563H191.33V10.4619C192.105 9.04889 192.825 8.12498 193.489 7.6902C194.153 7.25542 194.873 7.03802 195.675 7.03802C196.81 7.03802 197.972 7.39129 199.135 8.07063L197.945 11.1413C197.115 10.7065 196.312 10.4619 195.482 10.4619C194.734 10.4619 194.07 10.6793 193.489 11.1141C192.908 11.5489 192.493 12.1739 192.243 12.9348C191.856 14.1032 191.69 15.4076 191.69 16.8206V27.0652H188.286Z" fill="#3069B1" />
            <path d="M201.156 3.77718V0H204.532V3.77718H201.156ZM201.156 27.0652V7.44566H204.532V27.0652H201.156Z" fill="#3069B1" />
            <path d="M209.708 27.0652V7.44563H212.752V10.2174C213.361 9.26629 214.191 8.47824 215.243 7.90759C216.267 7.30976 217.457 7.03802 218.786 7.03802C220.253 7.03802 221.47 7.33694 222.411 7.93476C223.352 8.53259 224.017 9.37498 224.404 10.4619C226.009 8.17933 228.057 7.03802 230.604 7.03802C232.596 7.03802 234.119 7.55433 235.198 8.64129C236.25 9.72824 236.803 11.3859 236.803 13.6141V27.0652H233.427V14.7011C233.427 13.3967 233.288 12.4456 233.095 11.8478C232.873 11.25 232.458 10.788 231.877 10.4348C231.296 10.0815 230.631 9.89129 229.856 9.89129C228.445 9.89129 227.255 10.3532 226.341 11.2772C225.4 12.2011 224.958 13.6685 224.958 15.6793V27.0652H221.553V14.3206C221.553 12.8532 221.277 11.7391 220.723 11.0054C220.17 10.2717 219.284 9.89129 218.011 9.89129C217.07 9.89129 216.184 10.1359 215.381 10.6522C214.579 11.1413 213.998 11.8478 213.638 12.7989C213.278 13.75 213.084 15.1087 213.084 16.9022V27.0652H209.708Z" fill="#3069B1" />
            <path d="M255.402 20.7065L258.889 21.1685C258.363 23.1522 257.339 24.7282 255.845 25.8152C254.35 26.9293 252.468 27.4728 250.144 27.4728C247.237 27.4728 244.913 26.6032 243.224 24.8369C241.508 23.0706 240.65 20.5978 240.65 17.4185C240.65 14.1304 241.508 11.5761 243.224 9.75542C244.968 7.93476 247.21 7.03802 249.95 7.03802C252.607 7.03802 254.793 7.90759 256.481 9.70107C258.17 11.4945 259 13.9945 259 17.2282C259 17.4185 259 17.7174 259 18.0978H244.11C244.248 20.2445 244.857 21.9022 245.964 23.0435C247.099 24.1848 248.483 24.7554 250.144 24.7554C251.389 24.7554 252.441 24.4293 253.299 23.8043C254.184 23.1522 254.876 22.1196 255.402 20.7065ZM244.304 15.3804H255.43C255.291 13.7228 254.849 12.5 254.157 11.6848C253.077 10.3804 251.693 9.75542 249.977 9.75542C248.428 9.75542 247.127 10.2717 246.047 11.2772C244.996 12.3098 244.414 13.6685 244.304 15.3804Z" fill="#3069B1" />
            <path d="M84 64.8388L87.3998 64.5398C87.5656 65.899 87.9526 67.0136 88.5331 67.8835C89.1412 68.7534 90.0533 69.4602 91.2971 70.0039C92.5686 70.5476 93.9783 70.8194 95.5262 70.8194C96.9358 70.8194 98.152 70.6019 99.2024 70.1942C100.28 69.7864 101.054 69.2427 101.579 68.5087C102.105 67.8019 102.353 67.0408 102.353 66.1981C102.353 65.3553 102.105 64.6214 101.607 63.9689C101.11 63.3437 100.28 62.8272 99.1471 62.3922C98.4008 62.1204 96.77 61.6583 94.227 61.0602C91.7117 60.4621 89.9427 59.9184 88.92 59.3748C87.6209 58.6951 86.6259 57.8524 85.9901 56.8466C85.3544 55.8408 85.0227 54.7262 85.0227 53.4757C85.0227 52.1165 85.4097 50.8388 86.2113 49.6427C86.9852 48.4466 88.1461 47.5495 89.6663 46.9243C91.1866 46.3262 92.8727 46 94.7246 46C96.7423 46 98.539 46.3262 100.115 46.9786C101.662 47.6311 102.851 48.5825 103.708 49.833C104.537 51.0835 104.979 52.5243 105.062 54.101L101.552 54.3728C101.358 52.6602 100.723 51.3553 99.6446 50.4854C98.5666 49.6155 96.9635 49.1806 94.8628 49.1806C92.6515 49.1806 91.0484 49.5883 90.0257 50.3767C89.0306 51.165 88.5331 52.1165 88.5331 53.2583C88.5331 54.2097 88.8924 55.0252 89.5834 55.6505C90.3021 56.2757 92.1264 56.9282 95.0839 57.5806C98.0415 58.233 100.087 58.8039 101.165 59.2932C102.768 60.0272 103.957 60.9515 104.73 62.066C105.477 63.1806 105.864 64.4583 105.864 65.899C105.864 67.3398 105.449 68.699 104.592 69.9495C103.763 71.2272 102.575 72.233 100.999 72.9398C99.4235 73.6466 97.6545 74 95.692 74C93.2043 74 91.1313 73.6194 89.4452 72.9126C87.7591 72.2058 86.46 71.1456 85.4926 69.7049C84.5252 68.2641 84.0276 66.633 84 64.8388Z" fill="#3069B1" />
            <path d="M123.996 67.1767L127.507 67.6389C126.954 69.6233 125.931 71.2 124.466 72.2874C122.974 73.402 121.066 73.9457 118.772 73.9457C115.842 73.9457 113.548 73.0758 111.834 71.3088C110.148 69.5418 109.292 67.068 109.292 63.8874C109.292 60.5981 110.148 58.0427 111.862 56.2214C113.576 54.4 115.815 53.5029 118.579 53.5029C121.232 53.5029 123.416 54.3728 125.102 56.167C126.788 57.9612 127.617 60.4622 127.617 63.6971C127.617 63.8874 127.617 64.1864 127.59 64.567H112.747C112.885 66.7146 113.493 68.3728 114.599 69.5146C115.704 70.6563 117.114 71.2272 118.772 71.2272C119.988 71.2272 121.066 70.901 121.923 70.2758C122.808 69.6233 123.499 68.5903 123.996 67.1767ZM112.94 61.8486H124.052C123.886 60.1903 123.471 58.967 122.78 58.1515C121.702 56.8466 120.292 56.2214 118.606 56.2214C117.031 56.2214 115.732 56.7379 114.681 57.7437C113.631 58.7767 113.051 60.1359 112.94 61.8486Z" fill="#3069B1" />
            <path d="M131.736 73.5379V53.9107H134.776V56.9282C135.55 55.5146 136.269 54.5903 136.932 54.1554C137.595 53.7204 138.314 53.5029 139.116 53.5029C140.249 53.5029 141.41 53.8563 142.571 54.5359L141.382 57.6078C140.553 57.1728 139.724 56.9282 138.922 56.9282C138.176 56.9282 137.512 57.1456 136.932 57.5806C136.352 58.0156 135.937 58.6408 135.688 59.402C135.301 60.5709 135.135 61.8757 135.135 63.2893V73.5379H131.736Z" fill="#3069B1" />
            <path d="M150.034 73.5378L142.516 53.9106H146.137L150.393 65.6543C150.863 66.932 151.278 68.2369 151.665 69.6233C151.969 68.5903 152.383 67.3398 152.936 65.8718L157.359 53.9106H160.841L153.295 73.5378H150.034Z" fill="#3069B1" />
            <path d="M177.426 67.1767L180.908 67.6389C180.356 69.6233 179.36 71.2 177.868 72.2874C176.375 73.402 174.468 73.9457 172.174 73.9457C169.244 73.9457 166.95 73.0758 165.236 71.3088C163.55 69.5418 162.693 67.068 162.693 63.8874C162.693 60.5981 163.55 58.0427 165.264 56.2214C167.005 54.4 169.244 53.5029 171.98 53.5029C174.634 53.5029 176.818 54.3728 178.504 56.167C180.19 57.9612 181.019 60.4622 181.019 63.6971C181.019 63.8874 181.019 64.1864 181.019 64.567H166.148C166.286 66.7146 166.894 68.3728 168 69.5146C169.106 70.6563 170.515 71.2272 172.174 71.2272C173.39 71.2272 174.468 70.901 175.325 70.2758C176.209 69.6233 176.9 68.5903 177.426 67.1767ZM166.342 61.8486H177.453C177.315 60.1903 176.873 58.967 176.182 58.1515C175.104 56.8466 173.722 56.2214 172.008 56.2214C170.46 56.2214 169.133 56.7379 168.083 57.7437C167.033 58.7767 166.452 60.1359 166.342 61.8486Z" fill="#3069B1" />
            <path d="M185.137 73.5379V53.9107H188.178V56.9282C188.952 55.5146 189.67 54.5903 190.334 54.1554C190.997 53.7204 191.716 53.5029 192.517 53.5029C193.651 53.5029 194.811 53.8563 196 54.5359L194.784 57.6078C193.955 57.1728 193.153 56.9282 192.324 56.9282C191.577 56.9282 190.914 57.1456 190.334 57.5806C189.753 58.0156 189.339 58.6408 189.09 59.402C188.703 60.5709 188.537 61.8757 188.537 63.2893V73.5379H185.137Z" fill="#3069B1" />
            <path d="M0 0H74V74H0V0Z" fill="#2A67B1" />
            <path d="M8.89746 7.38361H65.1114V66.5754H8.89746V7.38361Z" fill="#FEC530" />
            <path d="M39.2072 54.7452H14.8125V59.1918H39.2072V54.7452Z" fill="#56A0D7" />
            <path d="M39.2072 34.7563H14.8125V39.2029H39.2072V34.7563Z" fill="#56A0D7" />
            <path d="M39.2072 14.8082H14.8125V19.2547H39.2072V14.8082Z" fill="#56A0D7" />
            <path d="M58.8288 17.011C58.8288 18.2348 57.8498 19.2547 56.626 19.2547C55.4021 19.2547 54.4231 18.2348 54.4231 17.011C54.4231 15.7872 55.4021 14.8082 56.626 14.8082C57.8498 14.8082 58.8288 15.7872 58.8288 17.011Z" fill="#2A67B1" />
            <path d="M58.8288 37C58.8288 38.2238 57.8498 39.2029 56.626 39.2029C55.4021 39.2029 54.4231 38.2238 54.4231 37C54.4231 35.7762 55.4021 34.7563 56.626 34.7563C57.8498 34.7563 58.8288 35.7762 58.8288 37Z" fill="#2A67B1" />
            <path d="M58.8288 56.9889C58.8288 58.2127 57.8498 59.1918 56.626 59.1918C55.4021 59.1918 54.4231 58.2127 54.4231 56.9889C54.4231 55.7651 55.4021 54.7452 56.626 54.7452C57.8498 54.7452 58.8288 55.7651 58.8288 56.9889Z" fill="#2A67B1" />
            <path d="M11.8347 27.3727H62.1743V26.6384H11.8347V27.3727Z" fill="#2A67B1" />
            <path d="M11.8347 47.3616H62.1743V46.6273H11.8347V47.3616Z" fill="#2A67B1" />
        </svg>

    )
}
