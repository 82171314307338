import React, { useState, useEffect, useRef } from "react";
import PageLayout from "../../component/PageLayout";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import "./style.css";
import IconRightArrow from "../../component/SvgIcons/IconRightArrow";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import GoogleTagManager from "../../component/GoogleTagManager";

export default function Events() {
  const [eventDetails, setEventDetails] = useState(null);
  const [events, setEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);    
  const [isExpanded, setIsExpanded] = useState(false);    
  const navigate = useNavigate();

  const handleRegisterNow = () => {
    navigate("/event-details", { state: { eventDetails } });
    window.scrollTo({ top: 0 });
  };

  const handleRegisterdetazisNow = () => {
    const eventDetails = null;
    navigate("/event-details", { state: { eventDetails } });
    window.scrollTo({ top: 0 });
  };

  const contentRef = useRef(null);
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };


  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const fetchEvents = async (eventDate) => {
    try {
      const response = await Axios.post("https://gshost.in/GS/EventAPI/event.php", {
        GSEvent: {
          event_date: eventDate
        },
      });
    
      console.log("response event", response);
      
      const currentDate = new Date();

      setEvents([]);
      setEventDetails(null);
      const eventData = response.data.GSEventRes.map((event) => ({
        id: event.Title,
        title: event.Title,
        start: `${event.EventDate}T${event.StartTime}`,
        end: `${event.EventDate}T${event.EndTime}`,
        url: event.RegistrationPageLink,
        extendedProps: {
          contents: event.Contents,
          link: event.Link,
          image: event.EventPhoto 
        },
      }));

      const upcomingEvents = eventData.filter(event => {
        const eventStart = new Date(event.start);
        return eventStart > currentDate;
      });

      upcomingEvents.sort((a, b) => new Date(a.start) - new Date(b.start));
      const datasca = upcomingEvents.filter((eventdatas,index) => index === 0);
      setEvents(eventData);
      setLoading(false);   
      setUpcomingEvents(datasca)
      
      if (eventDetails) {
        const selectedEvent = events.find(event => event.title === eventDetails.eventName);
        if (!selectedEvent) {
          setEventDetails(null); // Reset eventDetails if selected event is not found
        }
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setError(error);
      setLoading(false);
    }
  };

   const formatTime = (datetimeStr) => {
    const dateObj = new Date(datetimeStr);
    if (isNaN(dateObj.getTime())) {
      console.error('Invalid datetime string:', datetimeStr);
      return ''; 
    }
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const handleEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault();

    const eventStartDate = new Date(clickInfo.event.start);
    const currentDate = new Date();
      const eventfinaldate = formatDate(eventStartDate);
      console.log("dateDatadateData", eventfinaldate);
      
    // Check if the event date is in the past
    if (eventStartDate < currentDate) {      
      setEventDetails({
        eventName: clickInfo.event.title,
        eventDetails: clickInfo.event.extendedProps.contents,
        eventStartDate: eventfinaldate,
        eventTime: `${formatTime(clickInfo.event.start)} - ${formatTime(clickInfo.event.end)}`,
        eventLink: clickInfo.event.url,
        pastEvent: true,        
      });
    } else {
      setEventDetails({
        eventName: clickInfo.event.title,
        eventDetails: clickInfo.event.extendedProps.contents,
        eventStartDate: eventfinaldate,
        eventTime: `${formatTime(clickInfo.event.start)} - ${formatTime(clickInfo.event.end)}`,
        eventLink: clickInfo.event.url,
      });
    }
  };

  const handleDatesSet = (dateInfo) => {
    const endDate = new Date(dateInfo.end).getMonth();
    const currentYear = new Date(dateInfo.end).getFullYear();
    const Month = endDate < 10 ? '0' + endDate : endDate;
    const eventDate = `${currentYear}-${Month}`;    
    fetchEvents(eventDate);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    // Extract year, month, and day
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
    const day = date.getDate().toString().padStart(2, '0'); // Pad single-digit days with leading zero
  
    // Return formatted date string
    return `${year}-${month}-${day}`;
  };


  return (
    <div className="home-main eventpage">
      <GoogleTagManager />
      <PageLayout>
        <div className="Innerpage-title eventsecondtitle">
          <h1 className="title1 text-center box-default">Events <span>&</span> Registration</h1>
        </div>       
       <section className="eventstabdetails">
        <div className="container">
        <div className="tabs">  
              <button
                className={`btn btn-danger ${activeTab === 0 ? 'btn-dark' : ''}`}
                onClick={() => handleTabClick(0)}
              >
              <label>Upcoming Events</label>
              <span></span>        
              </button>
              {/* <button
                className={`btn btn-danger ${activeTab === 1 ? 'btn-dark' : ''}`}
                onClick={() => handleTabClick(1)}
              >
                <label>Old Events</label>
                <span></span>         
              </button>*/}
            </div>
            <div className="tab-content">
              {activeTab === 0 && 
               <div className="evenboxtabingdata">
               
                 {upcomingEvents.length > 0 ? (
                   upcomingEvents.map((event, index) => (
                      <div  key={index} className="d-flex flex-column justify-content-between flex-md-row align-items-start">
                       <div className="eventstabdetailstab-pic">
                         <img
                           src={event.extendedProps.image || "images/event-picture.gif"}
                           className="img-fluid fadeIn"
                           alt={event.title} 
                         />                         
                       </div>             
                       <div className="eventstabdetailstab-details">  
                        <h4>{event.title} </h4>                              
                         <div
                          className={`contenthideshow ${isExpanded ? 'showall' : 'hideall'}`}
                          ref={contentRef}
                          dangerouslySetInnerHTML={{ __html: event.extendedProps.contents}}
                        />  

                         <div className="button justify-content-center justify-content-md-start d-flex gap-3">
                         
                           <button onClick={toggleExpand} className="btn btn-danger">
                             <label>{isExpanded ? 'Less More' : 'Read More'} <IconRightArrow /></label><span></span>
                           </button>
                         
                           <button onClick={handleRegisterdetazisNow} className="btn btn-dark">
                             <label>Register Now <IconRightArrow /></label><span></span>
                           </button>
                         </div>
                       </div>
                     </div>
                   ))
                 ) : (
                   <p>No upcoming events.</p>
                 )}               
             </div>
             
            }
              {activeTab === 1 &&
          <div className="evenboxtabingdata evenboxtabingdata02"> 
          <p>No Events</p>
          </div>
     }
            </div>

        </div>
      </section>

        <section className="mt100 calendar-main">
          <div className="container">
            <div className="calendarbox-main d-flex justify-content-between">
              <div className="calendarleftside position-relative">
                <div className="background"></div>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin]}
                  headerToolbar={{
                    left: "prev,next",
                    center: "title",
                    right: "today",
                  }}
                  events={events}
                  eventClick={handleEventClick}
                  datesSet={handleDatesSet} // Callback for date range change
                  initialDate={new Date()} // Set initial date to today
                />
              </div>
              <div className="calendarrightside">
                <h3>Events Details</h3>
                <div className="event-info">
                  {loading ? (
                    <p>Loading events...</p>
                  ) : error ? (
                    <p>Error loading events. Please try again later.</p>
                  ) : events.length === 0 ? (
                    <p>No events found for this month.</p>
                  ) : eventDetails != null ? (
                    <div className="event-details02">
                      <ul>
                        <li>
                          <strong>Event Name</strong>: {eventDetails.eventName}
                        </li>
                        <li>
                          <strong>Event Date & Time</strong>: ({eventDetails.eventStartDate ? (() => { const [year, month, day] = eventDetails.eventStartDate.split("-"); return `${day}-${month}-${year}`; })() : ""}) - ( {eventDetails.eventTime})
                        </li>
                        <li>
                          <strong>Event Details</strong>: <div className="eventdetails-scheduled" dangerouslySetInnerHTML={{ __html: eventDetails.eventDetails }}/>
                        </li>                      
                      </ul>
                      {eventDetails.pastEvent ? (
                        <p>There are currently no events scheduled. Please check back later for updates.</p>
                      ) : (
                        <button
                        onClick={handleRegisterNow}
                        className="btn btn-danger"
                      >
                          <label>
                            Register Now <IconRightArrow />
                          </label>
                          <span></span>
                        </button>
                      )}
                    </div>
                  ) : (
                    <p>Select an event to view details</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>


      </PageLayout>
    </div>
  );
}
