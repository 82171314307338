import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageLayout from "../../component/PageLayout";
import ProductTallyPrimeTabs from "../../component/Product-TallyPrime";
import IconRightArrow from "../../component/SvgIcons/IconRightArrow";
import IconTallyPrimelogo from "../../component/SvgIcons/IconTallyPrimelogo";
import "./style.css";
import GoogleTagManager from "../../component/GoogleTagManager";

export default function TallySoftwareService() {
  const [silver, setSilver] = useState("2");
  const [gold, setGold] = useState("2");
  const [auditor, setAuditor] = useState("2");

  const SilverHandleChange = (event) => {
    setSilver(event.target.value);
  };
  const GoldHandleChange = (event) => {
    setGold(event.target.value);
  };
  const AuditordHandleChange = (event) => {
    setAuditor(event.target.value);
  };

  return (
    <div className="TallyPrimebg add addradiubtn fourtab">
      <GoogleTagManager />
      <PageLayout>
        <ProductTallyPrimeTabs />
        <section className="TabFirstTallyPrime-tab pt50 pb100">
          <div className="container">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="col-12 col-md-6 col-lg-6 leftsidetitle">
              <div className="titletwo titletwohooks mb-0">
                <h2>
                GET <span>10%</span> OFF                  
                </h2>
                </div>
                <h4>on 2-Year TSS Subscription</h4>
                <p className="priceheiglight">Savings of <strong>2700</strong> INR on Gold and <strong>900</strong> INR on Silver TSS</p>
              
              </div>

              <div className="tablemain col-12 col-md-6 col-lg-6">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>1-Year Subscription</th>
                      <th>2-Year Subscription</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                          <div className="tabletitle">
                            <p>
                              <strong>Silver TSS</strong>
                            </p>
                            <p>(Single User)</p>
                          </div>                         
                      </td>
                      <td> 
                        <div className="tableprice">
                            <label>4,500</label>
                            <p>+18% GST (₹ 810)</p>
                          </div>
                          </td>
                      <td><div className="tableprice">
                        <label>8,100</label>
                        <p>+18% GST (₹ 1,458)</p>
                        <p className="effective">(Effective price 4,050/ Year)</p>
                        
                      </div></td>
                    </tr>
                    <tr>
                      <td>
                          <div className="tabletitle">
                            <p>
                              <strong>Gold TSS</strong>
                            </p>
                            <p>(Multi User)</p>
                          </div>                         
                      </td>
                      <td> <div className="tableprice">
                            <label>13,500</label>
                            <p>+18% GST (₹ 2,430)</p>
                          </div></td>
                    <td>  <div className="tableprice">
                        <label>24,300</label>
                        <p>+18% GST (₹ 4,374)</p>
                        <p className="effective">(Effective price 12,150/Year)</p>
                        
                      </div></td>
                    </tr>
                    <tr>
                      <td colSpan="3" align="center">
                        <small>
                          Note: 10% off applicable only for TallyPrime users | +
                          18% GST
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>               
              </div>
            </div>

            <div className="titletwo col-sm-12 col-md-12 col-lg-12 pt50 text-center">
                <h2>
                  Powerful upgrades & connected services for your <span>growing business</span>
                </h2>
              </div>


            <div
              className="keyfeatures-main flex-column flex-md-row d-flex gap30">
              <div className="keyfeatures-picture box-default d-flex align-items-center justify-content-center">
                <img
                  src="images/tally-software-service/e-way-bills-instantly.png"
                  alt="Connected Services to Generate e-Way Bills Instantly"
                />
              </div>
              <div className="keyfeatures-information box-default d-flex justify-content-center flex-column">
                <h5>Connected Services to Generate e-Way Bills Instantly</h5>
                <p>
                  Tally’s fully connected e-way bill solution is simple,
                  seamless and reliable with zero manual work. Tally being one
                  of the recognised ISO certified GSP (GST Suvidha Provider),
                  Tally directly integrates with the e-way portal to seamlessly
                  generate e-way bill for you without the need to do it
                  manually.
                </p>
              </div>
            </div>

            <div
              className="keyfeatures-main  d-flex flex-md-row-reverse flex-column flex-md-row flex-sm-column gap30">
              <div className="keyfeatures-picture box-default d-flex justify-content-center align-items-center">
                <img
                  src="images/tally-software-service/e-Invoice.png"
                  alt="Connected Service to Generate e-Invoice"
                />
              </div>
              <div className="keyfeatures-information box-default d-flex justify-content-center flex-column">
                <h5>Connected Service to Generate e-Invoice</h5>
                <p>
                  Generating and printing e-invoice is amazingly simpler with
                  Tally’s connected services. Tally being a recognized and ISO
                  certified GSP (GST Suvidha Provider), Tally directly
                  integrates with IRP portal to seamlessly generate e-invoices.
                </p>
              </div>
            </div>

            <div
              className="keyfeatures-main flex-column flex-md-row d-flex gap30">
              <div className="keyfeatures-picture box-default d-flex justify-content-center align-items-center">
                <img
                  src="images/tally-software-service/business-reports.png"
                  alt="Business Reports on Any Device, Anywhere"
                />
              </div>
              <div className="keyfeatures-information box-default d-flex justify-content-center flex-column">
                <h5>Business Reports on Any Device, Anywhere</h5>
                <p>
                  Real-time and secure access to important business report from
                  a web browser, on any device, anywhere.
                </p>
                <ul>
                  <li>
                    View your important business reports anywhere on a web
                    browser
                  </li>
                  <li>
                    Convenience of accessing your data anywhere while your data
                    stays only with you
                  </li>
                  <li>
                    Access important business reports such as Bills Receivable &
                    Payable, Stock Summary, Sales/Purchase Register, Profit &
                    Loss A/C, Balance Sheet etc on the go
                  </li>
                  <li>
                    Helping you make the business decisions faster than before
                  </li>
                  <li>
                    You can download an invoice or any report and share it on
                    the go
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="keyfeatures-main d-flex flex-md-row-reverse flex-column flex-md-row flex-sm-column gap30">
              <div className="keyfeatures-picture box-default justify-content-center d-flex align-items-center">
                <img
                  src="images/tally-software-service/productupdates.png"
                  alt="Product updates"
                />
              </div>
              <div className="keyfeatures-information box-default d-flex justify-content-center flex-column">
                <h5>Product updates</h5>
                <p>
                  Regular product updates ensure that your Tally caters to your
                  ever-changing business requirements.
                </p>
                <ul>
                  <li>
                    Avail new product enhancements & major releases at frequent
                    intervals
                  </li>
                  <li>
                    Ensure compatibility with the latest technological and
                    statutory changes
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="keyfeatures-main flex-column flex-md-row d-flex gap30">
              <div className="keyfeatures-picture box-default justify-content-center d-flex align-items-center">
                <img
                  src="images/tally-software-service/digital-payment-request.png"
                  alt="Digital Payment Request"
                />
              </div>
              <div className="keyfeatures-information box-default d-flex justify-content-center flex-column">
                <h5>Digital Payment Request</h5>
                <p>
                  Integrate with payment gateways and enjoy the benefits of a
                  single system to generate and manage payment links. What’s
                  more? You can do it with UPI as well.
                </p>
                <ul>
                  <li>
                    Supports integration with payment gateways such as PayU and
                    Razorpay for streamlined payments
                  </li>
                  <li>
                    Add payment QR code and link on the invoices, including bulk
                    generation for multiple invoices
                  </li>
                  <li>
                    Generate payment links and share them with your customers
                    through email and SMS
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="keyfeatures-main d-flex flex-md-row-reverse flex-column flex-md-row flex-sm-column gap30">
              <div className="keyfeatures-picture box-default justify-content-center d-flex align-items-center">
                <img
                  src="images/tally-software-service/remote-access.png"
                  alt="Remote Access"
                />
              </div>
              <div className="keyfeatures-information box-default d-flex justify-content-center flex-column">
                <h5>Remote Access</h5>
                <p>
                  24X7 business connectivity using our Remote Access Services
                  and manage your business from anywhere in the world.
                </p>
                <ul>
                  <li>
                    Get round the clock access to crucial information about your
                    business performance irrespective of the time or location
                  </li>
                  <li>
                    Manage cash flows, bank accounts, stock positions or
                    manufacturing time-lines even when you are not in your
                    office
                  </li>
                  <li>
                    Create and modify the business transactions and other
                    details on the go
                  </li>
                  <li>
                    Provide access to Chartered Accountants and their audit
                    clerks to audit data
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="tallypricebox-main pt50 d-flex flex-column flex-sm-row flex-md-row gap50 flex-wrap justify-content-between">
              <div className="tallypricebox graycolor box-default text-center">
                <div className="tallylogo">
                  <IconTallyPrimelogo />
                </div>
                <h4>Silver</h4>
                {silver === "2" ? (
                  <span className="wdp-ribbon wdp-ribbon-two">Get 10% Off</span>
                ) : (
                  ""
                )}
                <p>
                  <span>Single user edition For Standalone PCs</span>
                </p>
                <ul className="yearselect">
                  <li>Years:</li>
                  <li>
                    <input
                      type="radio"
                      id="silver2"
                      name="silver-selector"
                      value="2"
                      checked={silver === "2"}
                      onChange={SilverHandleChange}
                    />
                    <label htmlFor="silver2">2</label>
                    <div className="check"></div>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="silver1"
                      name="silver-selector"
                      value="1"
                      checked={silver === "1"}
                      onChange={SilverHandleChange}
                    />
                    <label htmlFor="silver1">1</label>
                    <div className="check"></div>
                  </li>
                </ul>

                <h1>₹ {silver === "2" ? "8,100" : "4,500"}</h1>
                <p>+18% GST (₹ {silver  === "2" ? "1,458" : "810"})</p>
                {silver === "2" ? (
                  <p style={{ marginTop: "10px" }}>
                    Effective price 4,050/Year
                  </p>
                ) : (
                  ""
                )}
                <div className="buybtn">
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0 });
                    }}
                    to="/contact"
                    className="btn btn-purpal"
                  >
                    <label>
                      Buy Now <IconRightArrow />
                    </label>{" "}
                    <span></span>
                  </Link>
                  {silver === "2" ? (
                  <p style={{ marginTop: "20px" }}>
                    Applicable only for TallyPrime users
                  </p>
                ) : (
                  ""
                )}
                </div>
               
              </div>

              <div className="tallypricebox box-default goldcolor text-center">
                <div className="tallylogo">
                  <IconTallyPrimelogo />
                </div>
                <h4>Gold</h4>
                {gold === "2" ? (
                  <span className="wdp-ribbon wdp-ribbon-two">Get 10% Off</span>
                ) : (
                  ""
                )}
                <p>
                  <span>
                    Unlimited multi-user edition For multiple PCs on LAN
                    environment
                  </span>
                </p>
                <ul className="yearselect">
                  <li>Years:</li>
                  <li>
                    <input
                      type="radio"
                      id="gold2"
                      name="gold-selector"
                      value="2"
                      checked={gold === "2"}
                      onChange={GoldHandleChange}
                    />
                    <label htmlFor="gold2">2</label>
                    <div className="check"></div>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="gold1"
                      name="gold-selector"
                      value="1"
                      checked={gold === "1"}
                      onChange={GoldHandleChange}
                    />
                    <label htmlFor="gold1">1</label>
                    <div className="check"></div>
                  </li>
                </ul>

                <h1>₹ {gold === "2" ? "24,300" : "13,500"}</h1>
                <p>+18% GST (₹ {gold  === "2" ? "4,374" : "2,430"})</p>
                {gold === "2" ? (
                  <p style={{ marginTop: "10px" }}>
                    Effective price 12,150/Year
                  </p>
                ) : (
                  ""
                )}
                <div className="buybtn">
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0 });
                    }}
                    to="/contact"
                    className="btn btn-purpal"
                  >
                    <label>
                      Buy Now <IconRightArrow />
                    </label>
                    <span></span>
                  </Link>
                  {gold === "2" ? (
                  <p style={{ marginTop: "20px" }}>
                    Applicable only for TallyPrime users
                  </p>
                ) : (
                  ""
                )}
                </div>
              
              </div>

              <div className="tallypricebox box-default bluecolor text-center">
                <div className="tallylogo">
                  <IconTallyPrimelogo />
                </div>
                <h4>Auditor</h4>
                {auditor === "2" ? (
                  <span className="wdp-ribbon wdp-ribbon-two">Get 10% Off</span>
                ) : (
                  ""
                )}
                <p>
                  <span>Unlimited multi-user edition For multiple PCs on LAN environment</span>
                </p>
                <ul className="yearselect">
                  <li>Years:</li>
                  <li>
                    <input
                      type="radio"
                      id="auditor2"
                      name="auditor-selector"
                      value="2"
                      checked={auditor === "2"}
                      onChange={AuditordHandleChange}
                    />
                    <label htmlFor="auditor2">2</label>
                    <div className="check"></div>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="auditor1"
                      name="auditor-selector"
                      value="1"
                      checked={auditor === "1"}
                      onChange={AuditordHandleChange}
                    />
                    <label htmlFor="auditor1">1</label>
                    <div className="check"></div>
                  </li>
                </ul>

                <h1>₹ {auditor === "2" ? "12,150" : "6,750"}</h1>
                <p>+18% GST (₹ {auditor  === "2" ? "2,187" : "1,215"})</p>
                {auditor === "2" ? (
                  <p style={{ marginTop: "10px" }}>
                    Effective price 6,075/Year
                  </p>
                ) : (
                  ""
                )}
                <div className="buybtn">
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0 });
                    }}
                    to="/contact"
                    className="btn btn-purpal"
                  >
                    <label>
                      Buy Now <IconRightArrow />
                    </label>
                    <span></span>
                  </Link>
                  {auditor === "2" ? (
                  <p style={{ marginTop: "20px" }}>
                    Applicable only for TallyPrime users
                  </p>
                ) : (
                  ""
                )}
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </PageLayout>
    </div>
  );
}
