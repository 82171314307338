export const OurClientTallyserverLogop= [

    {
        id: 1,
        img: 'images//TallyPrimeServer/cyberoam.png'
    },

    {
        id: 2,
        img: 'images/TallyPrimeServer/photokina.png'
    },
    {
        id: 3,
        img: 'images/client-logos/gp.png'
    },
    {
        id: 4,
        img: 'images/TallyPrimeServer/skoda.png'
    },
    {
        id: 5,
        img: 'images/TallyPrimeServer/avani.png'
    },
    {
        id: 6,
        img: 'images/client-logos/btipl.png'
    },
    {
        id: 7,
        img: 'images/client-logos/pdeu.png'
    },
    {
        id: 8,
        img: 'images/client-logos/katria.png'
    },
    {
        id: 9,
        img: 'images/TallyPrimeServer/suntec.png'
    }  

]