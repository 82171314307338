export const Logopictures = [

    {
        id: 1,
        img: 'images/client-logos/gp.png'
    },

    {
        id: 2,
        img: 'images/client-logos/mica.png'
    },
    {
        id: 3,
        img: 'images/client-logos/adani.png'
    },
    {
        id: 4,
        img: 'images/client-logos/national-health-mission.png'
    },
    {
        id: 5,
        img: 'images/client-logos/gulf.png'
    },
    {
        id: 6,
        img: 'images/client-logos/hm.png'
    },
    {
        id: 7,
        img: 'images/client-logos/vadilal.png'
    },
    {
        id: 8,
        img: 'images/client-logos/btipl.png'
    },
    {
        id: 9,
        img: 'images/client-logos/pdeu.png'
    },
    {
        id: 10,
        img: 'images/client-logos/katria.png'
    },
    {
        id: 11,
        img: 'images/client-logos/green-cross.png'
    },
    {
        id: 12,
        img: 'images/client-logos/hocco.svg'
    }

]