import React from 'react'

export default function IconTallySupport() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.28335 9.9587C2.28335 5.70982 5.78681 2.25297 10.093 2.25297C12.8878 2.25297 15.344 3.70864 16.7247 5.89227H19.3066C17.7268 2.42251 14.1915 0 10.093 0C4.52743 0 0 4.46719 0 9.9587C0 14.7278 3.41474 18.7243 7.95847 19.693V17.3715C4.68642 16.4532 2.28335 13.4772 2.28335 9.9587Z" fill="#FF0000" />
            <path d="M19.9355 8.91455H3.48763C3.42217 9.28051 3.3877 9.65702 3.3877 10.0412C3.3877 10.4235 3.42243 10.8002 3.49004 11.1678H8.76487V19.9413C9.13977 19.9798 9.52082 19.9999 9.90668 19.9999C10.2925 19.9999 10.6733 19.9798 11.0485 19.9413V11.1678H17.6324C17.183 14.1827 14.9576 16.6348 12.0417 17.4534V19.7755C16.5852 18.8068 19.9999 14.81 19.9999 10.0412C19.9997 9.66044 19.9778 9.28473 19.9355 8.91455Z" fill="#231F20" />
        </svg>

    )
}
