import React from 'react';
import { OurClientTallyserverLogop } from './OurClientTallyserverLogop';
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ourclients = {
  slidesPerView: "auto",
  loop: true,
  speed: 5000,
  autoplay: {    
    disableOnInteraction: false,
    delay: 1,
  },
};
export default function OurClientTallyserver() {
  return (
    <section className='pb50 pt115 OurClient text-center'>
      <div className='container'>
        <div className="titletwo">
          <h2>Our clients using <span>Tally Server</span></h2>
        </div>
        <div className='clients-main box-default'>
          <Swiper
            {...ourclients} modules={[Autoplay]}         
          >
            {OurClientTallyserverLogop.map((clientlogo) => (
              <SwiperSlide key={clientlogo.id}>
                <div className='slide'><img src={clientlogo.img} alt="" /></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
