import React from "react";
import "./style.css";

export default function HomeBanner() {
  return (
   <div className="banner-animationeffect">
    <section className="pb100 slider-main">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-sm-center justify-content-md-start justify-content-center align-items-center">
            <div
              className="company-info d-flex flex-column">
              <div className="hometitle d-flex flex-column">
                <label>Welcome to</label>
                <h1>
                  <p>
                    <span>G</span>lobal
                  </p>
                  <span>S</span>oftware
                </h1>
              </div>
              <div className="partners d-flex justify-content-sm-center justify-content-md-start justify-content-center">
                <img
                  src="../images/5-star.png"
                  alt="Tally Partner 5 Star"
                  className="img-fluid"
                />                
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="banner-box box-default d-flex align-items-center justify-content-center">
              <img
                src="../images/home-picture.png"
                alt="Global Shoftware"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    
    </section>
    </div> 
  );
}
