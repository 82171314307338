import React from "react";
import PageLayout from "../../component/PageLayout";
import TallySupportBoxes from "../../component/TallySupportBoxes";
import "./style.css";
import GoogleTagManager from "../../component/GoogleTagManager";

export default function ValueAddedServices() {
  return (
    <div className="home-main supportpage">
      <GoogleTagManager/>
      <PageLayout>
        <TallySupportBoxes />
        <div className="container">
        <div className="titletwo phonetitlecenter">
          <h2>Our Value <span>Added Services</span> </h2>
        </div>
                
          <div className="unmached-main corporate mb100">
            <div className="titletwo">
              <h1>
                <span>Corporate</span> / Tailored Training
              </h1>
            </div>
            <p>
              Are you confident your users are capable to extract the maximum
              from the powerful tool that's Tally?Did your vendor take that
              extra effort of up-dating you on the new product features?If you
              are not sure about the quality of training that you have received,
              it's time to call us. We can handle your requirements by creating
              tailor made schedules. Our experienced training executives will
              handle the situation for you to render maximum productivity from
              your Tally users.
            </p>
          </div>

          <div className="corruption-main d-flex flex-column flex-sm-row align-items-center gap40">
            <figure>
              <img
                src="images/value-added-services/data-corruption.svg"
                alt="Data Corruption Troubleshooting"
              />
            </figure>
            <div className="corruption-info">
              <div className="titletwo">
                <h2>
                  <span>Data Corruption</span> / Troubleshooting
                </h2>
              </div>
              <p>
                Data corruption in Tally is the compromise of stored data
                integrity, often caused by hardware issues, power failures,
                software bugs, viruses, or improper shutdowns. To prevent this,
                ensure regular backups, use reliable hardware, employ an
                uninterruptible power supply, keep software updated, and
                implement security measures. In the event of corruption,
                restoring from a recent backup is crucial for data recovery.
                Regular maintenance and adherence to best practices help
                minimize the risk of data corruption in Tally.
              </p>
            </div>
          </div>

          <div className="corruption-main d-flex flex-sm-row-reverse flex-column align-items-center gap40 mt100 mb100">
            <figure>
              <img
                src="images/value-added-services/data-splitting.svg"
                alt="Data Splitting"
              />
            </figure>
            <div className="corruption-info">
              <div className="titletwo">
                <h2>
                  <span>Data</span> Splitting
                </h2>
              </div>
              <p>
                Data Splitting in Tally involves dividing large financial
                datasets into smaller, more manageable segments, optimizing
                system performance. This process is especially useful preventing
                sluggishness associated with oversized data files. It allows
                segmentation based on fiscal years, aiding compliance and
                reporting. Smaller data files are easier to maintain, backup,
                and manage, reducing the risk of data loss. Users can
                selectively access specific periods or sections, offering
                flexibility for analysis and reporting.
              </p>
            </div>
          </div>

          <div className="corruption-main d-flex  flex-column flex-sm-row align-items-center gap40">
            <figure>
              <img
                src="images/value-added-services/synchronization.svg"
                alt="Synchronization between Head Office and Branches"
              />
            </figure>
            <div
              className="corruption-info">
              <div className="titletwo">
                <h2>
                  <span>Synchronization</span> between Head Office and Branches
                </h2>
              </div>
              <p>
                Data Synchronization in Tally is a process that ensures
                consistency and updates between multiple instances of Tally. It
                allows seamless sharing of financial data across different
                locations or devices in real-time. Users can collaborate on the
                same set of data, ensuring accuracy and avoiding duplication.
                Synchronization handles transactions, masters, and
                configurations, providing a unified view of the company's
                financial information. This feature is crucial for businesses
                with multiple branches or users working on different systems.
              </p>
            </div>
          </div>

          <div className="corruption-main d-flex flex-sm-row-reverse flex-column align-items-center gap40 mt100 mb100">
            <figure>
              <img
                src="images/value-added-services/management.svg"
                alt="Security and User Management"
              />
            </figure>
            <div
              className="corruption-info">
              <div className="titletwo">
                <h2>
                  <span>Security</span> and User Management
                </h2>
              </div>
              <p>
                In Tally, security involves access control with defined user
                roles, restricting permissions based on responsibilities. Data
                encryption safeguards information during transmission and
                storage, while audit trails monitor and log user activities for
                review. User authentication, including password protection and
                multi-factor authentication, ensures authorized access.
                Effective security and user management in TallyPrime are crucial
                for maintaining data integrity and preventing unauthorized
                access to financial information. Regular reviews of user
                permissions and adherence to best practices contribute to a
                secure accounting environment.
              </p>
            </div>
          </div>

          <div className="corruption-main d-flex flex-column flex-sm-row  align-items-center gap40 mb100">
            <figure>
              <img
                src="images/value-added-services/implementation.svg"
                alt="Implementation of Advance Features of Tally"
              />
            </figure>
            <div
              className="corruption-info">
              <div className="titletwo">
                <h2>
                  <span>Implementation</span> of Advance Features of Tally
                </h2>
              </div>
              <p>
                Noteworthy features integrated during this process encompass
                comprehensive functionalities such as payroll management, TDS,
                TCS, job costing, point-of-sale operations, E-invoicing, E-way
                Bill management, Manufacturing Journals with Bill of Materials
                (BoM), Procurement Processes, and Make-to-Order capabilities.
                This strategic implementation ensures the optimal utilization of
                TallyPrime, tailored to meet the diverse needs of the business
                landscape.
              </p>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
}
