import React, { useEffect, useRef } from 'react';

function CanvasComponent() {
  const canvasRef = useRef(null);
  let can_w, can_h;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    can_w = canvas.width;
    can_h = canvas.height;

    var R = 5;
    var balls = [];
    var alpha_f = 0.03;
    var link_line_width = 0.8;
    var dis_limit = 260;
    var mouse_in = false;
    var mouse_ball = {
      x: 0,
      y: 0,
      vx: 0,
      vy: 0,
      r: 0,
      type: 'mouse'
    };

    function getRandomColor() {
      return {
        r: Math.floor(Math.random() * 256),
        g: Math.floor(Math.random() * 256),
        b: Math.floor(Math.random() * 256)
      };
    }

    function randomArrayItem(arr) {
      return arr[Math.floor(Math.random() * arr.length)];
    }

    function randomNumFrom(min, max) {
      return Math.random() * (max - min) + min;
    }

    function randomSidePos(length) {
      return Math.ceil(Math.random() * length);
    }

    function getRandomSpeed(pos,minSpeed, maxSpeed) {
        var min = minSpeed || -0.2; // Define minimum speed (optional)
        var max = maxSpeed || 0.2;  // Define maximum speed (optional)
      switch (pos) {
        case 'top':
          return [randomNumFrom(min, max), randomNumFrom(0.1, max)];
        case 'right':
          return [randomNumFrom(min, -0.1), randomNumFrom(min, max)];
        case 'bottom':
          return [randomNumFrom(min, max), randomNumFrom(min, -0.1)];
        case 'left':
          return [randomNumFrom(0.1, max), randomNumFrom(min, max)];
        default:
          return;
      }
    }

    function getRandomBall() {
      var pos = randomArrayItem(['top', 'right', 'bottom', 'left']);
      switch (pos) {
        case 'top':
          return {
            x: randomSidePos(can_w),
            y: -R,
            vx: getRandomSpeed('top')[0],
            vy: getRandomSpeed('top')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10),
            color: getRandomColor()
          };
        case 'right':
          return {
            x: can_w + R,
            y: randomSidePos(can_h),
            vx: getRandomSpeed('right')[0],
            vy: getRandomSpeed('right')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10),
            color: getRandomColor()
          };
        case 'bottom':
          return {
            x: randomSidePos(can_w),
            y: can_h + R,
            vx: getRandomSpeed('bottom')[0],
            vy: getRandomSpeed('bottom')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10),
            color: getRandomColor()
          };
        case 'left':
          return {
            x: -R,
            y: randomSidePos(can_h),
            vx: getRandomSpeed('left')[0],
            vy: getRandomSpeed('left')[1],
            r: R,
            alpha: 1,
            phase: randomNumFrom(0, 10),
            color: getRandomColor()
          };
        default:
          return;
      }
    }
    function renderBalls() {
        balls.forEach(ball => {
          if (ball.color) {
            ctx.fillStyle = `rgba(${ball.color.r},${ball.color.g},${ball.color.b},${ball.alpha})`;
            ctx.beginPath();
            ctx.arc(ball.x, ball.y, R, 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.fill();
          }
        });
      }
      

    function updateBalls() {
      var new_balls = [];
      balls.forEach(b => {
        b.x += b.vx;
        b.y += b.vy;

        if (b.x > -(50) && b.x < (can_w + 50) && b.y > -(50) && b.y < (can_h + 50)) {
          new_balls.push(b);
        }

        b.phase += alpha_f;
        b.alpha = Math.abs(Math.cos(b.phase));
      });

      balls = new_balls.slice(0);
    }
    function renderLines() {
        var fraction, alpha;
        for (var i = 0; i < balls.length; i++) {
          for (var j = i + 1; j < balls.length; j++) {
            fraction = getDisOf(balls[i], balls[j]) / dis_limit;
      
            if (fraction < 1 && fraction > 1) {
              alpha = (1 - fraction).toString();
      
              // Create gradient color with opacity
              var gradient = ctx.createLinearGradient(balls[i].x, balls[i].y, balls[j].x, balls[j].y);
              gradient.addColorStop(0, 'rgba(190, 32, 38, 0.3)'); // First color with opacity
              gradient.addColorStop(1, 'rgba(47, 76, 160, 0.3)'); // Second color with opacity
      
              ctx.lineWidth = link_line_width;
              ctx.strokeStyle = gradient; // Use gradient color for stroke style
      
              ctx.beginPath();
              ctx.moveTo(balls[i].x, balls[i].y);
              ctx.lineTo(balls[j].x, balls[j].y);
              ctx.stroke();
              ctx.closePath();
            } else if (fraction < 1.5) {
              alpha = (1.5 - fraction).toString();
      
              // Create gradient color with opacity
              var gradient = ctx.createLinearGradient(balls[i].x, balls[i].y, balls[j].x, balls[j].y);
              gradient.addColorStop(0, 'rgba(190, 32, 38, 0.3)'); // First color with opacity
              gradient.addColorStop(1, 'rgba(47, 76, 160, 0.3)'); // Second color with opacity
      
              ctx.lineWidth = 1.5;
              ctx.strokeStyle = gradient; // Use gradient color for stroke style
      
              ctx.beginPath();
              ctx.moveTo(balls[i].x, balls[i].y);
              ctx.lineTo(balls[j].x, balls[j].y);
              ctx.stroke();
              ctx.closePath();
            }
          }
        }
      }
      

    function getDisOf(b1, b2) {
      var delta_x = Math.abs(b1.x - b2.x),
          delta_y = Math.abs(b1.y - b2.y);

      return Math.sqrt(delta_x * delta_x + delta_y * delta_y);
    }

    function addBallIfy() {
      if (balls.length < 40) {
        balls.push(getRandomBall());
      }
    }

    function render() {
      ctx.clearRect(0, 0, can_w, can_h);

      renderBalls();
      renderLines();
      updateBalls();
      addBallIfy();

      window.requestAnimationFrame(render);
    }

    function initBalls(num) {
      for (var i = 1; i <= num; i++) {
        balls.push({
          x: randomSidePos(can_w),
          y: randomSidePos(can_h),
          vx: getRandomSpeed('top', 1, 3)[0],
          vy: getRandomSpeed('top', 1, 3)[1],
          r: R,
          alpha: 1,
          phase: randomNumFrom(0, 0),
          color: getRandomColor()
        });
      }
    }

    function initCanvas() {
      canvas.setAttribute('width', window.innerWidth);
      canvas.setAttribute('height', window.innerHeight);

      can_w = parseInt(canvas.getAttribute('width'));
      can_h = parseInt(canvas.getAttribute('height'));
    }

    window.addEventListener('resize', function () {
      initCanvas();
    });

    function goMovie() {
      initCanvas();
      initBalls(20);
      window.requestAnimationFrame(render);
    }

    goMovie();

    canvas.addEventListener('mouseenter', function () {
      mouse_in = true;
      balls.push(mouse_ball);
    });

    canvas.addEventListener('mouseleave', function () {
      mouse_in = false;
      var new_balls = [];
      balls.forEach(b => {
        if (!b.hasOwnProperty('type')) {
          new_balls.push(b);
        }
      });
      balls = new_balls.slice(0);
    });

    canvas.addEventListener('mousemove', function (e) {
      const ev = e || window.event;
      mouse_ball.x = ev.pageX;
      mouse_ball.y = ev.pageY;
    });

    canvas.addEventListener('click', function () {
      balls.forEach(b => {
        b.color = getRandomColor();
      });
    });

  }, []); 

  return <canvas className='canvaseffect' ref={canvasRef} width={'100%'} height={'100%'}></canvas>;
}

export default CanvasComponent;
