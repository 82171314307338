import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'

export default function TellyprimeSection() {
  return (
    <section className='section'>
        <div className='container'>
      <div className="blockAvailable">       
      <Link to="/tallyprime-now">
      TallyPrime 5.0 is Now Available 
      </Link>  
    </div>
   </div>
   </section>
  )
}
