import React from 'react'

export default function IconTallyConnect() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.5314 2.46839C26.2402 -0.822797 20.8858 -0.822797 17.5945 2.46839L12.6902 7.3727C11.8344 8.22852 11.141 9.32039 10.694 10.6535C10.6865 10.656 10.6793 10.6598 10.6718 10.6623C9.65527 13.7202 10.4262 17.0455 12.6902 19.3095C13.3667 19.986 14.1625 20.543 15.0245 20.9541L15.3428 20.6358C16.4437 19.535 16.4437 17.7578 15.3428 16.6568C13.5125 14.8265 13.5125 11.8555 15.3428 10.0253L20.2471 5.12095C22.0775 3.29064 25.0484 3.29064 26.8787 5.12095C28.709 6.95127 28.709 9.9222 26.8787 11.7525L23.1548 15.4764C23.6514 17.0117 23.7566 18.6375 23.5163 20.22C23.9085 19.9466 24.2858 19.6507 24.627 19.3095L29.5313 14.4052C32.8226 11.114 32.8226 5.75952 29.5314 2.46839Z" fill="#FBBE24" />
            <path d="M19.322 12.6779C18.6456 12.0015 17.8498 11.4444 16.9745 11.0465L16.6694 11.3516C15.5686 12.4524 15.5685 14.2297 16.6694 15.3306C18.4997 17.1609 18.4997 20.1319 16.6694 21.9622C15.1201 23.5114 13.23 25.4019 11.7529 26.879C9.92257 28.7093 6.95163 28.7093 5.12132 26.879C3.29101 25.0487 3.29101 22.0778 5.12132 20.2474L8.85738 16.5111C8.36082 14.9758 8.2557 13.3499 8.49601 11.7674C8.10376 12.0409 7.72638 12.3368 7.38513 12.6779L2.46863 17.5948C-0.822553 20.8859 -0.822553 26.2404 2.46863 29.5316C5.75982 32.8229 11.1143 32.8228 14.4055 29.5316L19.322 24.6148C20.1778 23.7589 20.8713 22.6671 21.3182 21.3341C21.3257 21.3316 21.3329 21.3278 21.3404 21.3253C22.3569 18.2673 21.586 14.9419 19.322 12.6779Z" fill="#FBBE24" />
            <path d="M27.2798 25.9411L25.9535 24.6148C25.587 24.2482 24.9937 24.2482 24.6272 24.6148C24.2607 24.9813 24.2607 25.5746 24.6272 25.9411L25.9535 27.2674C26.3201 27.634 26.9133 27.634 27.2798 27.2674C27.6463 26.9008 27.6463 26.3076 27.2798 25.9411Z" fill="#FBBE24" />
            <path d="M7.38513 6.0463L6.05875 4.71999C5.69219 4.35343 5.09894 4.35343 4.73244 4.71999C4.36588 5.08655 4.36588 5.6798 4.73244 6.0463L6.05875 7.37262C6.42532 7.73918 7.01857 7.73918 7.38513 7.37262C7.75169 7.00605 7.75169 6.4128 7.38513 6.0463Z" fill="#FBBE24" />
            <path d="M30.7432 18.9713L28.9311 18.4856C28.4305 18.3515 27.9163 18.6481 27.7823 19.1488C27.6481 19.6525 27.9482 20.1646 28.4454 20.2977L30.2575 20.7834C30.7581 20.9175 31.2723 20.6208 31.4064 20.1202C31.5391 19.6196 31.2438 19.1028 30.7432 18.9713Z" fill="#FBBE24" />
            <path d="M3.5667 11.6897L1.75464 11.204C1.25401 11.0699 0.739827 11.3665 0.605764 11.8671C0.471576 12.3707 0.771639 12.8829 1.26895 13.016L3.08101 13.5018C3.58164 13.6358 4.09583 13.3392 4.22989 12.8386C4.36326 12.3373 4.06539 11.8205 3.5667 11.6897Z" fill="#FBBE24" />
            <path d="M20.7958 30.2451L20.3101 28.4331C20.1754 27.9305 19.6618 27.6358 19.1612 27.7699C18.6606 27.904 18.364 28.4181 18.498 28.9188L18.9838 30.7308C19.1169 31.2281 19.6291 31.5281 20.1327 31.394C20.6333 31.2599 20.9298 30.7458 20.7958 30.2451Z" fill="#FBBE24" />
            <path d="M13.5141 3.06854L13.0284 1.25648C12.8949 0.753916 12.3801 0.460541 11.8795 0.593291C11.3789 0.727353 11.0822 1.24154 11.2163 1.74217L11.702 3.55423C11.746 3.71873 11.8316 3.86123 11.9442 3.97392C12.1735 4.20317 12.5154 4.30679 12.8509 4.21742C13.3515 4.08342 13.6482 3.56917 13.5141 3.06854Z" fill="#FBBE24" />
        </svg>

    )
}
