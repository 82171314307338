import React from 'react'

export default function IconProducts() {
    return (
        <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="11.5" cy="31" rx="4.5" ry="5" fill="url(#paint0_linear_2_1705)" />
            <circle cx="25" cy="31" r="5" fill="url(#paint1_linear_2_1705)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.4183 1.52829C30.801 0.597851 31.6434 0 32.5718 0H37.6365C38.9418 0 40 1.164 40 2.59987C40 4.03574 38.9418 5.19974 37.6365 5.19974H34.0967L31.5175 11.4711C30.9795 12.7793 29.5792 13.3601 28.3899 12.7683C27.2006 12.1764 26.6727 10.6361 27.2107 9.3279L30.4183 1.52829Z" fill="url(#paint2_linear_2_1705)" />
            <g className='IconpProdect' filter="url(#filter0_b_2_1705)">
                <path d="M3.46681 21.2233C1.9051 14.816 1.12424 11.6124 2.72703 9.41244C2.81049 9.29788 2.89807 9.18639 2.9896 9.07817C4.74746 7 8.04487 7 14.6397 7H22.3603C28.9551 7 32.2525 7 34.0104 9.07817C34.1019 9.18639 34.1895 9.29788 34.273 9.41244C35.8758 11.6124 35.0949 14.816 33.5332 21.2233C32.5281 25.3468 32.0256 27.4086 30.5311 28.6484C30.4508 28.715 30.3688 28.7794 30.2851 28.8416C28.7267 30 26.6045 30 22.3603 30H14.6397C10.3955 30 8.27334 30 6.7149 28.8416C6.6312 28.7794 6.54918 28.715 6.46892 28.6484C4.97442 27.4086 4.47189 25.3468 3.46681 21.2233Z" fill="#F8B77B" fillOpacity="0.6" />
                <path d="M3.64221 21.1805C2.86007 17.9716 2.27837 15.5833 2.08985 13.722C1.90176 11.8648 2.10933 10.5669 2.87294 9.51875C2.95384 9.40771 3.03872 9.29965 3.12744 9.19477C3.96494 8.20466 5.17678 7.69564 7.02565 7.43859C8.87874 7.18095 11.3369 7.18054 14.6397 7.18054H22.3603C25.6631 7.18054 28.1213 7.18095 29.9743 7.43859C31.8232 7.69564 33.0351 8.20466 33.8726 9.19477L34.0104 9.07817L33.8726 9.19477C33.9613 9.29965 34.0462 9.40771 34.1271 9.51874C34.8907 10.5669 35.0982 11.8648 34.9101 13.722C34.7216 15.5833 34.1399 17.9716 33.3578 21.1805C32.8542 23.2464 32.48 24.7808 32.0463 25.9547C31.6142 27.1243 31.1295 27.9173 30.4158 28.5094C30.338 28.574 30.2585 28.6364 30.1774 28.6967C29.4331 29.2499 28.5478 29.533 27.3092 29.6759C26.066 29.8192 24.4866 29.8195 22.3603 29.8195H14.6397C12.5134 29.8195 10.9339 29.8192 9.6908 29.6759C8.45218 29.533 7.56686 29.2499 6.8226 28.6967L6.7149 28.8416L6.8226 28.6967C6.74148 28.6364 6.66198 28.574 6.58419 28.5094C5.87047 27.9173 5.38575 27.1243 4.95368 25.9547C4.52004 24.7808 4.14575 23.2464 3.64221 21.1805Z" stroke="url(#paint3_linear_2_1705)" strokeWidth="0.361074" />
            </g>
            <defs>
                <filter id="filter0_b_2_1705" x="-5.97904" y="-0.823274" width="48.9581" height="38.6465" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.91164" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_1705" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2_1705" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_2_1705" x1="9.59615" y1="28.1875" x2="16.6434" y2="30.3686" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBA47F" />
                    <stop offset="1" stopColor="#DF6B00" />
                </linearGradient>
                <linearGradient id="paint1_linear_2_1705" x1="22.8846" y1="28.1875" x2="30.5575" y2="30.8261" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBA47F" />
                    <stop offset="1" stopColor="#DF6B00" />
                </linearGradient>
                <linearGradient id="paint2_linear_2_1705" x1="30.75" y1="2.84375" x2="40.7248" y2="6.27399" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBA47F" />
                    <stop offset="1" stopColor="#DF6B00" />
                </linearGradient>
                <linearGradient id="paint3_linear_2_1705" x1="17.9394" y1="7" x2="40.5434" y2="25.7195" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>

    )
}
