import React from 'react'

export default function GlobalLogo() {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 1080 1080"  style={{enableBackground:'new 0 0 1080 1080'}} space="preserve">
   <style type="text/css">
          {`.st0 { fill: #FF0000; } .st1 { fill: #206CB1; }`}
        </style>
<g>
	<g>
		<path d="M823.91,200.53c-8.78-0.08-16.59-3-23.99-7.49c-10.78-6.53-17.91-15.7-20.15-28.18c-1.63-9.05-0.07-17.89,4.14-26
			c2.97-5.72,6.09-11.59,10.31-16.36c9.05-10.25,19.91-17.48,34.61-16.3c20.38,1.63,42.03,19.5,41.06,43.34
			c-0.53,12.98-6.48,23.96-14.38,33.95c-6.41,8.11-14.23,14.17-24.63,16.22C828.59,200.16,826.23,200.27,823.91,200.53z
			 M819.89,183.72c4.86,0.14,9.34-1.13,13.25-3.97c9.42-6.82,15.45-16.06,18.27-27.25c2.79-11.08-1.2-20.64-10.49-26.3
			c-8.94-5.45-18.92-4.45-27.51,3.06c-7.51,6.57-12.61,14.83-15.07,24.47C794.42,169.14,804.44,182.9,819.89,183.72z"/>
		<g>
			<path d="M196.41,140.24c-0.09-17.92,13.13-34.56,31.92-40.02c15.29-4.44,28.8-1.22,39.88,10.26
				c9.9,10.26,16.86,22.27,18.27,36.74c1.59,16.31-5.7,28.67-18.83,37.49c-18.98,12.76-41.77,11.35-57.46-9.28
				C201.21,163.61,196.47,151.56,196.41,140.24z M245.45,176.84c12.07-0.83,21.59-8.83,23.43-20.44c0.96-6.04-0.45-11.83-3.01-17.26
				c-4.6-9.74-10.16-18.63-21-22.71c-14.47-5.44-31.06,5.52-30.74,20.97c0.32,15.24,7.73,27.07,20.16,35.53
				C237.44,175.08,241.7,175.58,245.45,176.84z"/>
			<path d="M176.5,257.1c-20.22-22.26-40.08-44.14-60.11-66.2c4.64-4.29,8.96-8.29,13.42-12.41c16.54,18.1,32.94,36.03,49.62,54.29
				c10.81-10,21.52-19.9,32.4-29.96c3.51,3.91,6.93,7.71,10.57,11.75C207.09,228.75,191.95,242.78,176.5,257.1z"/>
			<g>
				<path d="M282.33,71.76c14.76-5.79,29.11-11.56,43.58-17.01c5.3-2,10.96-2.23,16.49-0.66c14.55,4.14,20.24,24.39,11.46,32.97
					c-0.29,0.28-0.41,0.73-0.66,1.21c2.61,0.55,5.17,0.77,7.48,1.64c8.31,3.12,12.14,10.01,13.71,18.08
					c2.39,12.22-2.34,22.69-13.53,28.21c-10.46,5.15-21.52,9.09-32.33,13.52c-4.53,1.85-9.11,3.59-14.15,5.57
					C303.75,127.57,293.13,99.9,282.33,71.76z M324.91,134.32c0.69,0,1.05,0.09,1.34-0.01c5.52-2.07,11.06-4.11,16.55-6.26
					c2.69-1.05,5.43-2.09,7.92-3.52c6.03-3.47,7.7-8.97,5.08-15.83c-2.14-5.61-6.98-7.99-13.69-6.38c-3.34,0.8-6.58,2.06-9.8,3.29
					c-5.25,2.02-10.45,4.19-15.94,6.4C319.34,119.76,322.15,127.1,324.91,134.32z M303.83,79.37c2.53,6.57,4.87,12.64,7.29,18.89
					c7.36-2.92,14.33-5.58,21.19-8.46c2.01-0.85,3.78-2.29,5.59-3.57c3.25-2.3,4.59-5.41,3.3-9.27c-1.91-5.74-6.66-8.79-13.18-6.65
					C320.01,72.95,312.19,76.21,303.83,79.37z"/>
				<path d="M111.52,236.1c-3.13,4.79-5.99,9.31-9.04,13.7c-0.45,0.64-1.82,1.1-2.64,0.95c-17.36-3.1-27.05,13.25-25.19,25.17
					c2.48,15.93,18.14,29.57,34.31,30.3c9.87,0.45,19.16-5.16,23.26-14.05c3.97-8.59,2.05-18.6-4.98-25.94
					c-0.21-0.22-0.55-0.32-1.06-0.6c-1.86,2.79-3.7,5.57-5.54,8.34c-1.85,2.79-3.68,5.59-5.71,8.68c-4.01-2.7-7.95-5.35-12.15-8.18
					c6.79-10.35,13.48-20.56,20.31-30.96c6.83,4.63,13.35,9.11,19.93,13.51c6.57,4.4,13.18,8.73,20.04,13.26
					c-1.8,2.75-3.5,5.46-5.33,8.08c-1.05,1.51-2.38,2.03-4.26,1.01c-2.39-1.3-4.93-2.31-6.81-3.17c0.08,5.93,0.89,11.85,0.08,17.54
					c-2.16,15.29-17.7,29.34-33.08,30.65c-18.41,1.57-32.37-6.97-44.63-19.54c-8.21-8.42-12.49-18.36-11.98-30.1
					c0.47-10.78,5.19-19.76,12.29-27.79c6.68-7.56,14.38-12.93,24.6-14.21C100.02,231.99,105.66,233.3,111.52,236.1z"/>
				<path d="M661.67,91.9c-6.76-1.45-13.24-2.84-19.95-4.28c1.02-4.89,1.98-9.53,3-14.45c11.98,2.53,23.71,5.02,35.75,7.56
					c-3.17,15.69-6.29,31.13-9.51,47.05c-3.86-0.78-7.61-1.55-11.54-2.34c0-3.65,0-7.28,0-10.53c-4.66,2.22-9.12,5.22-14.02,6.51
					c-10.07,2.66-19.61,0.21-28.59-4.87c-13.29-7.52-18.78-19.53-19.28-34.1c-0.51-14.59,2.88-28.35,12.55-39.59
					c8.9-10.35,20.45-15.45,34.46-14.04c9.88,0.99,19.02,3.83,26.72,10.32c7.59,6.39,11.42,14.68,11.81,24.56
					c0.04,0.96-0.2,1.93-0.35,3.23c-5.5-1.12-10.72-2.13-15.9-3.3c-0.62-0.14-1.37-1.08-1.52-1.77c-1.97-9.22-7.37-15.2-16.67-17.06
					c-9.16-1.83-17.34,0.56-23,8.16c-8.25,11.1-11.19,23.75-8.06,37.31c2.28,9.88,11.43,16.74,21.84,17.28
					C649.33,108.07,657.73,102.3,661.67,91.9z"/>
				<path d="M723.1,50.86c15.06,6.5,30.05,12.29,44.43,19.33c19.29,9.44,17.3,31.42,5.88,41.7c-3.83,3.45-8.39,4.94-13.45,5.11
					c0,0.32-0.09,0.63,0.01,0.73c5.32,5.35,5.6,11.62,3.21,18.29c-1.84,5.14-3.96,10.17-5.79,15.31c-1.04,2.91-2.27,5.91-0.06,8.93
					c0.26,0.36-0.14,1.2-0.28,2.17c-5.95-2.51-11.71-4.83-17.33-7.44c-0.69-0.32-1.17-2.34-0.88-3.31
					c1.64-5.54,3.34-11.08,5.39-16.48c4.36-11.47,3.07-14.84-8.13-19.73c-5.52-2.4-11.06-4.74-16.6-7.1
					c-0.18-0.08-0.43-0.01-0.93-0.01c-4.37,10.58-8.77,21.26-13.33,32.3c-5.73-2.43-11.21-4.75-17.08-7.24
					C699.81,105.91,711.36,78.61,723.1,50.86z M724.14,94.56c8.44,3.38,16.48,6.96,24.76,9.8c6.27,2.15,10.7-0.39,13.4-6.58
					c2.75-6.3,1.51-12.2-4.15-15.1c-7.89-4.04-16.16-7.33-24.59-11.08C730.36,79.4,727.34,86.76,724.14,94.56z"/>
				<path d="M912.45,163.88c4.06,4.39,8.13,8.79,12.17,13.16c-2.79,2.86-5.28,5.56-7.92,8.1c-9.02,8.69-18.07,17.34-27.14,25.97
					c-2.9,2.75-6.11,5.2-8.8,8.13c-4.56,4.96-6.54,10.82-3.49,17.22c3.27,6.84,8.69,11.35,16.5,12.02
					c5.36,0.46,9.25-2.79,12.85-6.23c10.19-9.73,20.33-19.5,30.5-29.25c3.62-3.47,7.26-6.92,11.05-10.53
					c4.24,4.55,8.33,8.96,12.55,13.49c-0.57,0.67-1.02,1.28-1.55,1.79c-13.56,13-27.08,26.04-40.71,38.96
					c-6.42,6.08-13.77,10.35-23.01,9.85c-7.14-0.38-13.39-3.13-18.88-7.66c-9.28-7.66-16.46-16.63-18.26-29.02
					c-1.1-7.58,0.97-14.49,5.93-19.88c7.53-8.19,15.84-15.66,23.85-23.4c4.1-3.96,8.4-7.73,12.35-11.83
					c3.64-3.79,6.93-7.91,10.37-11.89C911.35,163.21,911.9,163.54,912.45,163.88z"/>
				<path d="M424.67,27.76c15.44,27.42,30.85,54.76,46.52,82.59c-6.39,1.15-12.27,2.28-18.18,3.17c-0.69,0.1-1.89-0.96-2.32-1.77
					c-2.24-4.11-4.35-8.29-6.4-12.5c-0.88-1.79-1.69-2.56-4.07-2.07c-9.16,1.88-18.4,3.42-27.63,4.93c-2.21,0.36-2.95,1.38-3.2,3.48
					c-0.64,5.41-1.48,10.8-2.28,16.43c-6.05,1.11-12.05,2.22-18.64,3.43c0.58-4.1,1.06-7.9,1.67-11.69
					c3.99-24.87,8.02-49.72,12-74.59c1.2-7.47,1.15-7.47,8.94-8.87c2.19-0.39,4.37-0.85,6.56-1.26
					C419.71,28.66,421.79,28.29,424.67,27.76z M411.9,86.91c8.09-1.47,15.51-2.81,23.27-4.22c-6.03-11.19-11.87-22.03-17.7-32.88
					c-0.24,0.07-0.48,0.13-0.71,0.2C415.16,62.1,413.57,74.2,411.9,86.91z"/>
				<path d="M944.63,290.83c-8.97,5.75-17.77,11.39-26.84,17.2c-3.25-5.21-6.41-10.28-9.72-15.58
					c25.08-16.09,49.94-32.05,74.99-48.12c7.87,12.75,16,24.81,22.99,37.5c10.69,19.4-3.63,35.3-18.61,39.73
					c-12.47,3.69-23.05-0.21-30.3-11.05c-3.66-5.47-7.03-11.14-10.54-16.71C946.03,292.87,945.4,291.98,944.63,290.83z
					 M980.26,267.92c-7.82,4.97-15.34,9.74-22.89,14.54c0.41,0.87,0.56,1.28,0.79,1.65c3.07,4.91,6.02,9.9,9.26,14.69
					c4.51,6.66,10.64,8.06,17.39,4.25c7.76-4.38,9.75-9.71,5.8-17.54C987.67,279.68,984,274.21,980.26,267.92z"/>
				<path d="M483.84,18.84c5.71,0,11.48,0,17.77,0c0,24.37,0,48.61,0,73.16c15.1,0,29.7,0,44.56,0c0,5.39,0,10.48,0,15.89
					c-20.55,0-41.17,0-62.33,0C483.84,78.41,483.84,48.84,483.84,18.84z"/>
			</g>
		</g>
	</g>
	<g>
		<path d="M102.22,1071.6c0-34.05,0-67.82,0-101.88c287.42,0,574.77,0,862.33,0c0,33.88,0,67.73,0,101.88
			C677.22,1071.6,389.87,1071.6,102.22,1071.6z M179.46,1046.33c1.9,0,3.41,0,5.26,0c0-12.88,0-25.54,0-39.16
			c0.94,1.59,1.41,2.3,1.8,3.05c5.67,10.88,11.46,21.7,16.92,32.69c1.73,3.48,3.9,4.45,7.54,3.24c0-17.01,0-34.02,0-50.96
			c-1.91,0-3.52,0-5.43,0c0,12.61,0,24.96,0,37.3c-1.08-0.77-1.64-1.66-2.13-2.6c-5.44-10.38-11.04-20.67-16.19-31.19
			c-1.8-3.67-4.07-4.47-7.77-3.43C179.46,1012.18,179.46,1029.13,179.46,1046.33z M896.81,1046.16c0-17.15,0-34.1,0-51.08
			c-1.84,0-3.45,0-5.3,0c0,12.72,0,25.2,0,37.68c-1.32-0.57-2.14-1.38-2.65-2.35c-5.56-10.57-11.07-21.17-16.58-31.76
			c-2.25-4.32-2.75-4.54-7.88-3.29c0,16.93,0,33.88,0,51.03c1.82,0,3.42,0,5.37,0c0-12.77,0-25.34,0-37.91
			c1.04,0.66,1.57,1.45,2,2.29c5.48,10.49,10.94,20.98,16.41,31.48C890.56,1046.83,891.33,1047.2,896.81,1046.16z M172.96,1021.09
			c-0.13-7.07-1.07-13.4-4.76-19.05c-7.07-10.84-22.22-11.11-29.62-0.53c-6.93,9.91-6.8,28.58,0.26,38.43
			c7.01,9.78,21.04,10.06,28.44,0.53C171.83,1034.61,172.81,1027.69,172.96,1021.09z M381.45,1020.96
			c-0.11-6.53-1.04-12.67-4.36-18.21c-7.14-11.91-23.09-12.29-29.84-0.18c-2.93,5.25-4.69,11.65-5.11,17.66
			c-0.45,6.43,1.48,13.02,5.1,18.66c7.49,11.7,23.03,11.27,30.05-0.74C380.43,1032.78,381.29,1026.86,381.45,1020.96z
			 M653.26,1020.73c-0.28,0.08-0.56,0.17-0.84,0.25c1.4,5.22,2.09,10.77,4.32,15.61c6.13,13.31,22.6,14.58,30.57,2.7
			c6.51-9.7,6.39-28.06-0.24-37.66c-7.18-10.39-21.22-10.55-28.56-0.28C654.36,1007.15,653.44,1013.85,653.26,1020.73z
			 M819.12,1020.88c0.17,6.12,1.16,12.14,4.46,17.54c7.11,11.63,22.24,12.03,29.7,0.8c6.35-9.56,6.39-27.52,0.09-37.12
			c-7.05-10.73-21.04-11.23-28.76-0.98C820.23,1006.94,819.46,1013.81,819.12,1020.88z M225.78,1022.88c7.5,0,14.61,0,21.71,0
			c0-2.3,0-4.24,0-6.5c-7.37,0-14.48,0-21.71,0c0-5.18,0-10.04,0-15.29c7.81,0,15.46,0,23.19,0c0-2.22,0-4.06,0-5.9
			c-9.84,0-19.47,0-29.04,0c-0.13,0.5-0.23,0.71-0.23,0.93c-0.02,13.86-0.04,27.72-0.05,41.59c-0.01,8.87,0,8.87,8.89,8.87
			c7.02,0,14.03,0,21.18,0c0-2.41,0-4.43,0-6.78c-8.18,0-16.06,0-23.95,0C225.78,1034.13,225.78,1028.81,225.78,1022.88z
			 M646.5,1009.67c0.92-8.52-4.17-14.61-12.91-15.74c-8.25-1.07-14.81,3.71-15.97,11.64c-1.14,7.73,1.88,13.01,9.16,15.81
			c3.64,1.4,7.39,2.55,11.02,3.97c3.28,1.28,4.81,3.87,4.74,7.36c-0.07,3.32-2.18,6.04-5.51,7.29c-6.76,2.54-13.6-1.14-15.22-8.19
			c-0.2-0.85-0.45-1.69-0.74-2.75c-1.98,0.15-3.83,0.3-6.61,0.51c1.07,3.01,1.77,5.6,2.87,8.01c3.65,8,10.99,11.43,19.61,9.32
			c7.57-1.85,11.66-7.96,11.14-16.79c-0.37-6.13-3.72-10.16-9.32-12.32c-3.95-1.53-8.1-2.55-11.98-4.23
			c-4.15-1.79-5.02-6.95-2.09-10.4c4.01-4.72,13.15-3.44,15.58,2.22c0.6,1.4,0.89,2.94,1.4,4.68
			C643.31,1009.93,644.93,1009.8,646.5,1009.67z M904.02,1029.43c-0.97,6.77,2.94,13.97,8.75,16.51c6.9,3.01,15.17,1.47,19.48-3.52
			c5.48-6.34,6.12-20.65-6.3-24.82c-3.81-1.28-7.61-2.57-11.37-3.97c-2.75-1.03-4-3.35-3.85-6.08c0.15-2.77,1.44-5.11,4.24-6.33
			c6.17-2.68,12.01,0.09,13.79,6.57c0.2,0.73,0.45,1.45,0.72,2.31c1.8-0.17,3.41-0.33,4.97-0.48c0.52-8.6-4.5-14.54-12.96-15.67
			c-8.17-1.09-14.44,3.25-16.11,11.16c-1.49,7.05,1.67,13.24,8.4,16c3.81,1.56,7.81,2.67,11.64,4.18c3.23,1.27,4.99,3.79,4.81,7.31
			c-0.18,3.54-1.89,6.06-5.35,7.35c-6.66,2.48-13.31-0.86-15.21-7.74c-0.28-1.03-0.58-2.06-0.89-3.18
			C907.01,1029.18,905.48,1029.31,904.02,1029.43z M276.64,1029.4c-5.48-0.64-5.88-0.23-4.8,4.86c0.14,0.65,0.31,1.3,0.51,1.94
			c2.46,7.9,8.99,11.93,18.01,11.12c6.83-0.61,12.08-5.69,13.14-12.71c1.26-8.36-2.21-14.18-10.36-17.23
			c-3.45-1.28-6.98-2.32-10.43-3.59c-3-1.1-4.32-3.36-4.08-6.55c0.24-3.12,1.82-5.17,4.76-6.23c6.22-2.26,11.6,0.5,13.21,6.78
			c0.18,0.72,0.36,1.44,0.58,2.3c1.81-0.17,3.42-0.32,4.98-0.46c0.44-9.09-4.67-14.88-13.59-15.79
			c-8.25-0.84-14.27,3.91-15.52,12.25c-1.05,6.98,2.06,12.49,8.78,15.15c3.84,1.52,7.9,2.49,11.65,4.2
			c5.56,2.53,6.24,9.41,1.54,13.21c-5.17,4.18-14.25,1.96-16.82-4.16C277.53,1032.96,277.2,1031.3,276.64,1029.4z M387.58,995
			c0,1.63,0,2.84,0,4.06c0,14.29,0,28.58,0,42.87c0,5.22,0.22,5.39,5.92,4.13c0-3.24,0-6.56,0-9.88c0-3.41,0-6.83,0-10.4
			c3.53,0,6.54,0.06,9.55-0.01c10.52-0.25,14.97-4.54,15.68-15.02c0.58-8.5-3.66-15.15-10.66-15.64
			C401.43,994.63,394.72,995,387.58,995z M464.76,1046.06c5.64,1.28,6.03,1.07,7.38-3.82c0.83-3.01,1.52-6.07,2.6-8.98
			c0.34-0.93,1.69-2.04,2.63-2.1c4.01-0.23,8.05-0.23,12.06,0c0.95,0.05,2.29,1.15,2.66,2.08c1.14,2.9,1.9,5.94,2.79,8.93
			c1.48,4.97,1.82,5.16,7.5,3.97c-0.07-0.63-0.04-1.3-0.23-1.91c-5.01-15.72-10.11-31.4-15-47.16c-0.77-2.49-2.31-2.17-4.09-2.26
			c-1.82-0.09-3.09,0.14-3.7,2.3c-2.62,9.22-5.41,18.38-8.16,27.56C469.07,1031.8,466.91,1038.92,464.76,1046.06z M730.59,995.02
			c0,12.63-0.44,24.93,0.14,37.18c0.46,9.84,6.15,15,14.9,15.15c9.26,0.17,14.89-4.5,15.81-14.45c0.81-8.75,0.53-17.61,0.68-26.42
			c0.07-3.76,0.01-7.53,0.01-11.33c-2.1,0-3.81,0-5.49,0c-0.1,0.58-0.2,0.91-0.2,1.24c-0.02,9.05,0.06,18.1-0.09,27.15
			c-0.05,3.44-0.29,6.94-1,10.29c-1.1,5.11-4.11,6.98-9.91,6.82c-4.56-0.12-7.78-2.74-8.68-7.31c-0.43-2.18-0.69-4.42-0.71-6.64
			c-0.09-9.39-0.07-18.77-0.09-28.16c0-1.17,0-2.34,0-3.52C733.87,995.02,732.33,995.02,730.59,995.02z M582.19,1046.4
			c0-2.07,0.21-3.96-0.03-5.8c-1.45-11.14,1.59-21.02,7.76-30.27c3.12-4.68,5.42-9.91,8.14-14.97c-5.93-1.25-6.28-1.11-8.66,3.55
			c-3.19,6.25-6.37,12.51-9.71,19.05c-3.23-6.36-6.34-12.49-9.45-18.62c-2.63-5.18-2.63-5.18-9.05-4.08
			c0.5,1.03,0.93,2.02,1.43,2.97c3.44,6.63,6.71,13.37,10.42,19.85c2.53,4.43,3.71,8.91,3.4,14c-0.29,4.75-0.06,9.52-0.06,14.32
			C578.56,1046.4,580.19,1046.4,582.19,1046.4z M449.26,1001.15c0,15.26,0,30.16,0,45.24c2.14,0,3.86,0,6,0c0-15.11,0-30.01,0-45.33
			c4.58,0,8.75,0,12.93,0c0-2.28,0-4.12,0-6c-10.7,0-21.15,0-31.75,0c0,2.05,0,3.89,0,6.09
			C440.69,1001.15,444.77,1001.15,449.26,1001.15z M307.63,995c0,2.19,0,4.03,0,6.21c4.29,0,8.38,0,12.9,0c0,15.24,0,30.14,0,45.13
			c2.16,0,3.87,0,5.97,0c0-15.15,0-30.05,0-45.31c4.58,0,8.76,0,12.99,0c0-2.21,0-4.04,0-6.03C328.8,995,318.34,995,307.63,995z
			 M800.14,1001.14c0-2.25,0-4.18,0-6.01c-11.01,0-32.44,0-32.44,0v6.14h13.17c0,0,0,30.13,0,45.11c1.99,0,3.59,0,5.57,0
			c0-15.11,0-30.01,0-45.24C791.21,1001.14,795.63,1001.14,800.14,1001.14z M563.24,1040c-6.36,0-12.75,0-19.51,0
			c0-15.28,0-30.07,0-44.8c-2.11,0-3.83,0-5.81,0c0,1.46,0,2.67,0,3.88c0,13.96-0.03,27.93,0.01,41.89
			c0.02,6.23-0.85,5.54,5.48,5.57c6.56,0.03,13.12,0.01,19.82,0.01C563.24,1044.23,563.24,1042.36,563.24,1040z M698.34,1046.38
			c8.82,0,17.26,0,25.91,0c0-2.16,0-4.1,0-6.48c-6.81,0-13.45,0-20.47,0c0-15.23,0-30.03,0-44.86c-2.03,0-3.65,0-5.44,0
			C698.34,1012.2,698.34,1029.15,698.34,1046.38z M511.77,1040.05c0-15.32,0-30.1,0-44.89c-2.02,0-3.64,0-5.29,0
			c0,17.13,0,34.04,0,51.37c7.1,0,14,0,20.9,0c5.57,0,5.57,0,4.97-5.62c-0.02-0.2-0.19-0.38-0.44-0.86
			C525.5,1040.05,518.94,1040.05,511.77,1040.05z M811.72,1046.42c0-17.23,0-34.25,0-51.4c-1.83,0-3.45,0-5.22,0
			c0,17.19,0,34.21,0,51.4C808.24,1046.42,809.77,1046.42,811.72,1046.42z"/>
		<path d="M138.78,1020.82c1.15,4.37,1.87,8.91,3.58,13.05c1.81,4.37,5.52,7.24,10.48,7.12c4.97-0.11,9.22-2.33,11.32-7.06
			c4.07-9.17,4.11-18.49-0.52-27.47c-3.22-6.24-13.2-9.13-19.34-2.22C140.12,1008.96,139.72,1014.73,138.78,1020.82z"/>
		<path d="M393.67,1019.43c4.95-0.34,9.54-0.32,13.99-1.08c3.42-0.59,5.23-4.13,5.18-8.38c-0.05-3.97-1.88-7.69-4.69-8.06
			c-4.78-0.64-9.63-0.67-14.48-0.96C393.67,1007.37,393.67,1013.03,393.67,1019.43z"/>
		<path d="M138.78,1020.82c0.94-6.09,1.33-11.85,5.52-16.57c6.14-6.92,16.11-4.02,19.34,2.22c4.64,8.98,4.6,18.3,0.52,27.47
			c-2.1,4.72-6.35,6.94-11.32,7.06c-4.95,0.11-8.67-2.75-10.48-7.12C140.66,1029.73,139.94,1025.18,138.78,1020.82z"/>
		<path d="M376.33,1020.79c-1.46,4.8-2.15,9.82-4.42,13.97c-4.63,8.46-14.95,8.6-19.76,0.31c-5.43-9.37-5.2-19.44-0.16-28.8
			c4.54-8.42,15.6-8.19,20.02,0.29C374.22,1010.77,374.87,1015.78,376.33,1020.79z"/>
		<path d="M687.07,1020.86c-1.53,4.86-2.27,10-4.64,14.21c-4.61,8.17-14.58,7.97-19.67,0.04c-4.06-6.32-3.9-13.39-2.96-20.43
			c0.37-2.81,1.25-5.75,2.66-8.19c4.86-8.4,15.74-8.46,20.25,0.12C684.91,1010.81,685.59,1015.82,687.07,1020.86z"/>
		<path d="M824.68,1020.99c0.83-4.24,1.25-8.15,2.39-11.84c1.65-5.32,6.2-8.67,11.05-8.85c5.14-0.18,9.38,2.55,11.57,8.03
			c3.2,7.98,3.35,16.14,0.19,24.18c-1.99,5.05-5.64,8.5-11.32,8.48c-5.68-0.02-9.4-3.52-11.25-8.58
			C825.97,1028.7,825.5,1024.67,824.68,1020.99z"/>
		<path d="M393.67,1019.43c0-6.4,0-12.06,0-18.48c4.85,0.29,9.7,0.32,14.48,0.96c2.81,0.38,4.64,4.1,4.69,8.06
			c0.05,4.25-1.75,7.79-5.18,8.38C403.21,1019.11,398.62,1019.08,393.67,1019.43z"/>
		<path d="M483.27,1002.73c2.12,7.33,4.22,14.58,6.4,22.11c-4.33,0-8.3,0-12.57,0C479.14,1017.51,481.18,1010.2,483.27,1002.73z"/>
	</g>
	<g>
		<path className="st0" d="M620.63,274.28c0,77.3-0.19,164.28-0.19,242.04c92.43,0.21,173.49,0.82,266.11,0.02
			c0.49,9.32,1.1,15.09,1.21,24.12c0.03,85.74-19.59,144.55-67.02,213.86c-30.41,39.02-61.86,71.52-106.91,97.6
			c-29.5,16.3-60.35,30.67-93.24,38.5c-23.51,5.57-41.11,8.72-64.63,10.1c-1.33,0.08-2.69,0.24-4.16,0.24c0-77.1,0-153.28,0-230.14
			c22.59,0,45.06,0,67.96,0c0,46.48,0.01,101.9,0.01,148.99c1.92-0.49,3.32-1.08,4.66-1.54c31.1-10.52,64.29-28.47,88.83-48.8
			c45.32-35.91,74.4-79.36,92.65-135.19c4.59-13.27,7.05-30.68,9.75-44.34c0.15-0.77,0.08-1.58,0.14-2.82c-87.54,0-174.94,0-262.6,0
			c0-131.64,0-262.29,0-394.01c67.56,4.53,126.68,24.41,181.66,62.25c63.35,43.97,105.21,99.51,132.92,173.83
			c-1.55,0.12-3.71,0.05-3.71,0.05c-26.79,0.01-70.04,0.05-70.04,0.05s0.48,0.48-0.48-1.45c-18.08-36.21-45.03-75.17-76.66-100.33
			c-27.66-22-59.71-40.96-93.43-52.05C622.56,274.96,621.6,274.65,620.63,274.28z"/>
		<path className="st0" d="M507.63,900.29c-18.79-1.85-36.15-4.77-54.34-8.17c-39.7-10.73-76.75-23.74-111.85-47.34
			c-35.82-22.81-62.22-48.75-88.95-81.84c-34.08-42.18-52.72-85.36-64.86-137.91c-4.42-19.12-7.05-39.5-8.16-59.13
			c-1.07-18.86-0.17-40.51,1.74-59.26c7.35-65.7,28.55-114.45,66.1-169.13c26.04-34.82,53.06-62.22,89.83-85.91
			c29.44-18.29,57.08-33.83,90.76-43.28c19.33-5.01,38.35-10.38,58.34-12.86c7.53-0.92,13.39-1.63,21.06-2.16
			c0,26.4,0.21,42.46,0.21,68.55c-6.23,0.51-12.38,1.58-18.53,2.1c-18.9,2.76-38.6,9.46-56.6,15.09
			c-35,11.95-65.59,33.59-93.73,58.1c-43.86,41.33-71.76,90.76-85.11,149.93c-5.67,25.16-7.45,49.98-5.8,75.61
			c4.13,64.43,26.01,117.18,67.86,169.59c5.62,6.37,11.05,13.06,17.39,18.69c28.03,24.94,50.16,41.76,86.63,58.36
			c1.84,0.63,9.58,3.84,11.46,4.34c0.32,0.09,9.04,3.77,9.63,3.83c0-71.81-0.28-158.26-0.28-230.28c-38.06,0-81.19,0-119.37,0
			c0-26.07-0.47-44.8-0.47-70.89c62.23,0,124.56,0,187.04,0C507.63,644.6,507.63,771.83,507.63,900.29z"/>
	</g>
	<path d="M533.38,140.21c-223.95,0-405.5,181.55-405.5,405.5s181.55,405.5,405.5,405.5s405.5-181.55,405.5-405.5
		S757.33,140.21,533.38,140.21z M533.38,926.85c-210.5,0-381.14-170.64-381.14-381.14s170.64-381.14,381.14-381.14
		s381.14,170.64,381.14,381.14S743.88,926.85,533.38,926.85z"/>
</g>
<g>
	<g>
		<path className="st1" d="M84,1224.05c3.22,0,3.22-5,0-5C80.79,1219.05,80.78,1224.05,84,1224.05L84,1224.05z"/>
	</g>
</g>
</svg>

  )
}
