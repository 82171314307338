import React from 'react'

export default function IconTallyEnterpriseSolution() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9_2015)">
                <path d="M7.09644 10.8989V31.2313H17.2846V10.8989H7.09644ZM13.7144 26.1368H10.6667V24.1348H13.7144V26.1368ZM13.7144 21.5656H10.6667V19.5636H13.7144V21.5656ZM13.7144 16.9944H10.6667V14.9924H13.7144V16.9944Z" fill="#FBBE24" />
                <path d="M15.2379 0V8.8969H19.2866V31.2312H32V2.6026L15.2379 0ZM24.3257 25.1358H22.3237V8.94228H24.3257V25.1358ZM28.8969 27.8946H26.8949V8.94228H28.8969V27.8946Z" fill="#FBBE24" />
                <path d="M3.10444 20.1536V22.1556H5.09443V24.8249H3.10444V26.8269H5.09443V31.2313H0V15.9934H5.09443V20.1536H3.10444Z" fill="#FBBE24" />
            </g>
            <defs>
                <clipPath id="clip0_9_2015">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
