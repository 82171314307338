import React from 'react'
import { Link } from 'react-router-dom'
import PageLayout from '../../component/PageLayout'
import ProductTallyPrimeTabs from '../../component/Product-TallyPrime'
import IconRightArrow from '../../component/SvgIcons/IconRightArrow'
import IconTallyPrimelogo from '../../component/SvgIcons/IconTallyPrimelogo'
import './style.css'
import GoogleTagManager from '../../component/GoogleTagManager'

export default function TallyPrime() {
  return (
    <div className='TallyPrimebg firsttab'>
      <GoogleTagManager/>
    <PageLayout>
    <ProductTallyPrimeTabs/>
    <section className="TabFirstTallyPrime-tab pt50">
      <div className="container">
        <div className="d-flex flex-column align-items-center  gap50 mb100">
          <div className="tallylogofitrsttab">
          <IconTallyPrimelogo />
          </div>
          <img
            src="images/tallyprime/artboard.png"
            className="img-fluid"
            alt="Tally Prime"
          />
          <div
            className="button d-flex gap50">
            <a href="images/satup/tallyprime_setup5-0.exe" download className="btn btn-danger">
              <label>Download Now <IconRightArrow /></label> <span></span>
            </a>
            <Link onClick={() => { window.scrollTo({ top: 0 });}} to="/contact" className="btn btn-dark">
              <label>Contact Us Now <IconRightArrow /></label> <span></span>
            </Link>
          </div>
        </div>

        <div
          className="yourgroth d-flex align-items-center flex-column flex-sm-row gap50">
          <div className="yourgroth-picture">
            <img
              src="images/tallyprime/yourgrotht.svg"
              alt="Your growth Partner"
            />
          </div>
          <div className="yourgroth-information">
            <h3>
              Your growth <span>Partner</span>
            </h3>
            <p>
              To grow your business, and to make the right business decisions,
              you need the right insights . With features like “Go To” and
              “customizable reports” in the new Tally, you can discover and look
              at reports, slicing and dicing them the way you want. To help you
              in your growth journey, Tally enables you to manage multiple
              companies and incrementally add features such as multiple
              go-downs, multi-currency, order process, cost centres etc. This
              helps you get rid of complexities, and in turn, focus on business
              growth.
            </p>
          </div>
        </div>

        <div
          className="yourgroth d-flex flex-sm-row-reverse flex-column flex-sm-row align-items-center gap50">
          <div className="yourgroth-picture">
            <img
              src="images/tallyprime/cashflow.svg"
              alt="Better control over cash flow"
            />
          </div>
          <div className="yourgroth-information">
            <h3>
              Better control over <span>cash flow</span>
            </h3>
            <p>
              Quick and hassle-free bills receivables and payable management
              help you to get paid faster as well as managing payment timelines.
              Also, Tally facilitates easy and efficient stock movement, making
              it possible to optimize the cash flow. Besides, the insightful
              reports in the blink of an eye help you make confident decisions
              and plan the growth of your business better.
            </p>
          </div>
        </div>

        <div
          className="yourgroth d-flex flex-column flex-sm-row align-items-center gap50">
          <div className="yourgroth-picture">
            <img
              src="images/tallyprime/enhanced.svg"
              alt="Enhanced Business Efficiency"
            />
          </div>
          <div className="yourgroth-information">
            <h3>
              <span>Enhanced</span> Business Efficiency
            </h3>
            <p>
              With the speed that Tally brings, you can get things done quickly
              and save precious time, helping you do more for less. With Tally,
              you can print or view the reports being in the middle of voucher
              entry, you can load another company being in a report without
              abandoning the activity you were on it, get to know the
              irregularities or odd details from every reports that you view and
              much more. No more remembering short cut keys, you have the
              intuitive and consistent workflow that helps you do work faster.
            </p>
          </div>
        </div>

        <div
          className="yourgroth d-flex flex-sm-row-reverse flex-column flex-sm-row align-items-center gap50"
        >
          <div className="yourgroth-picture">
            <img
              src="images/tallyprime/texteasy.svg"
              alt="Tax compliance made easyeasy"
            />
          </div>
          <div className="yourgroth-information">
            <h3>
              <span>Tax</span> compliance made <span>easy</span>
            </h3>
            <p>
              Tally’s Prevention, Detection and Correction mechanism ensures
              that your books are always accurate - from GST returns to
              reconciliation, hence giving you the confidence that the returns
              you file are always correct!
            </p>
          </div>
        </div>

        <div
          className="whytally box-default d-flex align-items-center 
          flex-column-reverse justify-content-between flex-column flex-md-row gap50"
        >
          <div className="whytallyinfo">
            <div className="titletwo">
              <h2>
                Why <span>Tally?</span>
              </h2>
            </div>
            <p>
              We believe in the power of technology to make business owners
              efficient, empowered and happier, so they can focus on what
              matters most for their business. We design our products to focus
              on just that - to make our products work for you, and not the
              other way around.Tally takes this to a new level, making your
              start to automation, or your switch to Tally simpler than ever
              before. You can discover the product much more easily and make the
              product do more for you, without learning anything new. There is
              greater flexibility as the product adapts to your business and
              your way of working. And the overall look and feel will only make
              you love the product even more.
            </p>
          </div>
          <div className="whytallypicture">
            <img
              src="images/tallyprime/whytally.png"
              className="img-fluid"
              alt="why tally"
            />
          </div>
        </div>
      </div>

      <div className="rectangle-bg">
        <div className="container">
          <div
            className="business-main d-flex align-items-end flex-column flex-md-row gap50">
            <div className="business-pic">
              <img
                src="images/tallyprime/business.svg"
                className="img-fluid"
                alt="Enhanced Business Efficiency"
              />
            </div>
            <div className="business-information">
              <div className="titletwo">
                <h2>Enhanced Business Efficiency</h2>
              </div>
              <p>
                Getting started with Tally is extremely simple. You can setup
                the application in less than a minute and get started with
                invoicing even faster. It just works the way you expect it to.
                And with the consistency and intuitiveness in design, you will
                be able to discover more and learn easily, as you are using
                Tally.
              </p>
            </div>
          </div>

          <div
            className="business-main d-flex flex-md-row-reverse flex-column flex-md-row align-items-center gap50">
            <div className="business-pic">
              <img
                src="images/tallyprime/insightful.png"
                className="img-fluid"
                alt="Insightful, actionable & customizable reports"
              />
            </div>
            <div className="business-information">
              <div className="titletwo">
                <h2>Insightful, actionable & customizable reports</h2>
              </div>
              <p>
                Getting started with Tally is extremely simple. You can setup
                the application in less than a minute and get started with
                invoicing even faster. It just works the way you expect it to.
                And with the consistency and intuitiveness in design, you will
                be able to discover more and learn easily, as you are using
                Tally.
              </p>
            </div>
          </div>
          <div className='container'>
          <div className="tallypricebox-main d-flex flex-column flex-sm-row flex-md-row  gap50 justify-content-center">
            <div className="tallypricebox box-default graycolor text-center">
              <div className="tallylogo">
                <IconTallyPrimelogo />
              </div>
              <h4>Silver</h4>
              <p>
                <span>Single user edition For Standalone PCs</span>
              </p>
              <h1>₹22,500</h1>
              <p>+18% GST(₹4,050)</p>
              <div className="buybtn">
                <Link onClick={() => { window.scrollTo({ top: 0 });}} to="/contact" className="btn btn-purpal">
                  <label>Buy Now <IconRightArrow /></label><span></span>
                </Link>
              </div>
            </div>
            <div className="tallypricebox goldcolor box-default text-center">
              <div className="tallylogo">
                <IconTallyPrimelogo />
              </div>
              <h4>Gold</h4>
              <p>
                <span>
                  Unlimited multi-user edition For multiple PCs on LAN
                  environment
                </span>
              </p>
              <h1>₹67,500</h1>
              <p>+18% GST(₹12,150)</p>
              <div className="buybtn">
                <Link onClick={() => { window.scrollTo({ top: 0 });}} to="/contact" className="btn btn-purpal">
                  <label>Buy Now <IconRightArrow /></label><span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
    </PageLayout>
    </div>
  )
}
