import React, { useEffect, useState } from 'react';
import ArrowDown from '../SvgIcons/ArrowDown';
import { TabsData } from './TabsData';
import './style.css';

export default function TallyAppsTabs() {
  const [activeTab, setActiveTab] = useState(TabsData[0]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className='pt100 pb100 tally-pps-tabs'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='titletwo text-center'>
              <h2>Tally integrated mobile <span>apps</span></h2>
            </div>
          </div>
        </div>
        <div className='tallyAppsTabs-main'>
          {windowWidth <= 575 ? (
               <div className='downlist'>
               <ArrowDown/>
            <select
              className="dropdown-menu"
              onChange={(e) =>
                setActiveTab(
                  TabsData.find(
                    (tab) => tab.id === parseInt(e.target.value)
                  )
                )
              }
            >
              {TabsData.map((tab) => (
                <option key={tab.id} value={tab.id}>
                  {tab.title}
                </option>
              ))}
            </select>
            </div>
          ) : (
            <div className="tabtopheader box-default d-flex align-items-center justify-content-between">
              {TabsData.map((tab) => (
                <div
                  key={tab.id}
                  className={`tabtitlemain ${tab === activeTab ? "active" : ""}`}
                  onClick={() => setActiveTab(tab)}
                >                 
                  {tab.title}
                </div>
              ))}
               <div className={`tabactive ${TabsData.indexOf(activeTab)}`}></div>
            </div>
          )}
        </div>
        <div className="tab-content appsmain d-flex justify-content-center flex-wrap">
          {activeTab.boxes.map(appsdata => (
            <div key={appsdata.id} className={`boxapps text-center ${activeTab.id === 1 ? 'firsttab'
              : activeTab.id === 2 || activeTab.id === 4 ? 'twofourtab'
                : activeTab.id === 3 ? 'thirdtab' : ''}`}>
              <figure>
                <img src={appsdata.img} alt=''  className='img-fluid' />
              </figure>
              {appsdata.content && <figcaption dangerouslySetInnerHTML={{ __html: appsdata.content }} />}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
