import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PriceData } from './PriceData';

export default function PriceTabs() {
    const location = useLocation();
    const [activeBox, setActiveBox] = useState(null); 
    useEffect(() => {
        const pathname = location.pathname;
        const activeBox = PriceData.find(pricebox => pricebox.url === pathname)?.id;
        setActiveBox(activeBox);
    }, [location.pathname]);
 
  return (
    <section className="price-tabmain">
      <div className='Innerpage-title'>
    <h1 className='title1 box-default pricetab text-center'>Pricing</h1>
    </div>
    <div className='container'>
        <div className="d-flex align-items-center justify-content-center">
    <div className="priceitem-tab box-default d-flex align-items-center justify-content-center">
    {PriceData.map((pricebox) => (
         <div className='priceitem' key={pricebox.id}>
          <Link
            to={pricebox.url}
            className={`price-box position-relative ${activeBox === pricebox.id ? 'active' : ''}`}            
        >
         <img src={pricebox.img} alt={pricebox.title}/>         
         </Link>
         </div>
    ))}   
 </div>
 </div>
    </div>
    </section>
  )
}
