import React from 'react'

export default function IconPricing() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.1819 40H19.8478C17.1843 40 15.0261 37.8035 15.0261 35.0927V14.4467C15.0261 13.3125 15.4124 12.2162 16.1145 11.339L23.7816 1.80052C25.7095 -0.600175 29.3165 -0.600175 31.2444 1.80052L38.9115 11.339C39.6173 12.2162 39.9999 13.3125 39.9999 14.4467V35.0927C39.9999 37.8035 37.8416 40 35.1782 40H35.1819Z" fill="url(#paint0_linear_546_99)" />
            <g filter="url(#filter0_b_546_99)">
                <path d="M33.5847 16.6662L32.3366 4.40937C32.0209 1.32438 29.012 -0.69448 26.1034 0.231774L14.547 3.90276C13.4846 4.23924 12.5708 4.94243 11.9579 5.88759L0.795311 23.1273C-0.668271 25.3919 -0.0553492 28.4353 2.16974 29.9248L14.9742 38.5106C17.1993 40.0002 20.1896 39.3764 21.6532 37.1118L32.8121 19.8721C33.425 18.927 33.6962 17.7928 33.581 16.6662H33.5847ZM30.1747 7.26374C29.1197 8.89319 26.9689 9.34309 25.3679 8.26939C23.7669 7.19569 23.3248 5.0067 24.3798 3.37725C25.4347 1.7478 27.5855 1.29791 29.1866 2.37161C30.7876 3.44531 31.2296 5.63429 30.1747 7.26374Z" fill="url(#paint1_linear_546_99)" />
                <path d="M32.4777 15.6662H32.4736L32.5862 16.7678C32.6784 17.6699 32.4608 18.5759 31.9731 19.328L31.9726 19.3287L20.8137 36.5684L20.8133 36.569C19.6502 38.3687 17.2872 38.8557 15.5305 37.6796L2.72666 29.0943L2.72604 29.0938C0.961306 27.9125 0.463586 25.4829 1.63518 23.6701L12.7969 6.43169C12.797 6.43156 12.7971 6.43143 12.7972 6.4313C12.7972 6.43123 12.7973 6.43117 12.7973 6.4311C13.2867 5.67672 14.0124 5.12103 14.849 4.85609L14.8498 4.85583L24.4551 1.8046C24.1084 2.09086 23.7985 2.43501 23.5404 2.83378C22.1922 4.9161 22.7511 7.71852 24.8109 9.09991C26.8814 10.4884 29.6591 9.9 31.0141 7.80721L30.1747 7.26374L31.0141 7.80721C31.2558 7.43395 31.4362 7.03754 31.5576 6.63033L32.4777 15.6662Z" stroke="url(#paint2_linear_546_99)" strokeWidth="2" />
            </g>
            <defs>
                <filter id="filter0_b_546_99" x="-18" y="-17.9946" width="69.6069" height="75.3147" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="9" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_546_99" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_546_99" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_546_99" x1="27.5149" y1="41.6106" x2="27.5149" y2="1.81943" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF5C00" />
                    <stop offset="0.52" stopColor="#FFCA40" />
                    <stop offset="1" stopColor="#FFE0F9" />
                </linearGradient>
                <linearGradient id="paint1_linear_546_99" x1="1.8973" y1="37.1005" x2="36.3482" y2="7.65129" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.2" />
                    <stop offset="1" stopColor="white" stopOpacity="0.49" />
                </linearGradient>
                <linearGradient id="paint2_linear_546_99" x1="2.37227" y1="3.0974" x2="35.1766" y2="33.0805" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>


    )
}
