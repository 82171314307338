import React from "react";

export default function IconTallyPrimelogo() {
  return (
    <svg
      width="392"
      height="82"
      viewBox="0 0 392 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33_2881)">
        <path
          d="M0 0.000976562H79.2882V40.9548H0V0.000976562Z"
          fill="#3069B1"
        />
        <path d="M0 40.9907H39.6516V81.9394H0V40.9907Z" fill="#FFC032" />
        <path
          d="M39.6516 40.9907H79.2887V81.9394H39.6516V40.9907Z"
          fill="#90C4E9"
        />
        <path
          d="M120.343 64.9624V24.9464H105.586V19.5908H141.09V24.9464H126.268V64.9624H120.343Z"
          fill="#3069B1"
        />
        <path
          d="M160.244 48.4661C158.249 49.2913 155.252 49.9934 151.257 50.5725C149 50.9005 147.402 51.2746 146.464 51.6846C145.526 52.0946 144.807 52.7045 144.296 53.4938C143.79 54.2881 143.532 55.1748 143.532 56.1434C143.532 57.6296 144.087 58.8648 145.199 59.8539C146.305 60.8481 147.933 61.3401 150.067 61.3401C152.185 61.3401 154.071 60.8738 155.723 59.9308C157.371 58.9929 158.581 57.7116 159.356 56.0819C159.946 54.8211 160.244 52.9659 160.244 50.5059V48.4661ZM160.7 60.9096C158.666 62.6624 156.701 63.8975 154.82 64.6201C152.935 65.3428 150.915 65.7066 148.757 65.7066C145.194 65.7066 142.45 64.82 140.535 63.057C138.624 61.294 137.667 59.039 137.667 56.2971C137.667 54.6879 138.024 53.217 138.748 51.8845C139.473 50.5571 140.42 49.486 141.591 48.6814C142.763 47.8768 144.082 47.272 145.546 46.8569C146.628 46.5699 148.255 46.288 150.434 46.0215C154.875 45.4834 158.145 44.8428 160.244 44.0996C160.264 43.3411 160.273 42.8543 160.273 42.6493C160.273 40.3789 159.752 38.7799 158.715 37.8523C157.311 36.5915 155.222 35.9663 152.453 35.9663C149.863 35.9663 147.953 36.4224 146.722 37.3398C145.492 38.2571 144.584 39.8818 143.993 42.2136L138.614 41.4705C139.101 39.1438 139.904 37.2578 141.026 35.8228C142.147 34.3878 143.765 33.2859 145.884 32.512C148.002 31.7381 150.454 31.3538 153.247 31.3538C156.016 31.3538 158.269 31.6818 159.996 32.3429C161.732 33.004 163.003 33.8343 163.816 34.8336C164.635 35.833 165.201 37.0989 165.528 38.6261C165.712 39.5743 165.801 41.286 165.801 43.7614V51.1926C165.801 56.3689 165.92 59.6438 166.153 61.0173C166.387 62.3908 166.853 63.7028 167.548 64.9635H161.797C161.231 63.8053 160.864 62.4523 160.7 60.9096Z"
          fill="#3069B1"
        />
        <path
          d="M179.7 19.5939H174.202V64.9655H179.7V19.5939Z"
          fill="#3069B1"
        />
        <path
          d="M193.603 19.5939H188.105V64.9655H193.603V19.5939Z"
          fill="#3069B1"
        />
        <path
          d="M201.89 77.6197L201.28 72.3871C202.481 72.7202 203.528 72.8842 204.426 72.8842C205.646 72.8842 206.624 72.6792 207.358 72.2692C208.093 71.8541 208.693 71.275 209.159 70.5319C209.507 69.9784 210.063 68.5946 210.842 66.3857C210.941 66.0782 211.105 65.6221 211.328 65.0225L199.017 32.0944H204.942L211.7 51.1286C212.568 53.5425 213.357 56.0794 214.047 58.7392C214.677 56.1819 215.431 53.686 216.31 51.2516L223.247 32.0944H228.745L216.399 65.5196C215.074 69.1276 214.047 71.6184 213.318 72.9765C212.335 74.8164 211.219 76.1591 209.953 77.015C208.693 77.8709 207.185 78.3014 205.433 78.3014C204.371 78.3014 203.195 78.0707 201.89 77.6197Z"
          fill="#3069B1"
        />
        <path
          d="M240.043 41.164H251.624C255.45 41.164 258.169 40.4414 259.782 38.9961C261.389 37.5509 262.193 35.5214 262.193 32.8974C262.193 31.0011 261.722 29.3765 260.774 28.0286C259.826 26.6756 258.576 25.7839 257.033 25.3483C256.035 25.0818 254.19 24.9434 251.505 24.9434H240.043V41.164ZM234.118 64.9645V19.5929H251.014C253.986 19.5929 256.259 19.7364 257.827 20.0234C260.025 20.3975 261.866 21.1048 263.354 22.1451C264.843 23.1855 266.039 24.6461 266.947 26.527C267.85 28.4028 268.302 30.4681 268.302 32.7129C268.302 36.572 267.091 39.8366 264.669 42.5119C262.248 45.182 257.866 46.5145 251.53 46.5145H240.043V64.9645H234.118Z"
          fill="#3069B1"
        />
        <path
          d="M275.085 64.9624V32.0958H280.032V37.0773C281.292 34.7454 282.459 33.2079 283.53 32.4648C284.597 31.7268 285.773 31.3527 287.058 31.3527C288.909 31.3527 290.795 31.9523 292.71 33.1464L290.82 38.3175C289.47 37.5129 288.13 37.108 286.78 37.108C285.58 37.108 284.498 37.477 283.545 38.2048C282.588 38.9377 281.908 39.9575 281.501 41.2593C280.886 43.2375 280.578 45.4054 280.578 47.7578V64.9624H275.085Z"
          fill="#3069B1"
        />
        <path
          d="M296.01 32.0937H301.508V64.9603H296.01V32.0937ZM296.01 19.5939H301.508V26.0001H296.01V19.5939Z"
          fill="#3069B1"
        />
        <path
          d="M309.883 64.9624V32.0958H314.801V36.7083C315.818 35.099 317.177 33.8024 318.87 32.8235C320.557 31.8447 322.482 31.3527 324.64 31.3527C327.042 31.3527 329.012 31.86 330.55 32.8697C332.093 33.8793 333.17 35.2938 333.805 37.108C336.371 33.2694 339.715 31.3527 343.829 31.3527C347.044 31.3527 349.52 32.2547 351.252 34.0587C352.983 35.8678 353.847 38.6455 353.847 42.4022V64.9624H348.379V44.2574C348.379 42.028 348.2 40.4239 347.843 39.445C347.486 38.4662 346.84 37.6769 345.903 37.0773C344.965 36.4777 343.868 36.1804 342.608 36.1804C340.325 36.1804 338.43 36.9492 336.921 38.4867C335.418 40.0242 334.659 42.4842 334.659 45.8667V64.9624H329.161V43.6065C329.161 41.1312 328.714 39.2759 327.816 38.0357C326.923 36.8005 325.454 36.1804 323.42 36.1804C321.872 36.1804 320.438 36.5904 319.123 37.4155C317.813 38.2458 316.86 39.4502 316.269 41.0389C315.679 42.6277 315.381 44.9185 315.381 47.9115V64.9624H309.883Z"
          fill="#3069B1"
        />
        <path
          d="M366.163 45.3706H384.274C384.036 42.6083 383.341 40.5326 382.2 39.154C380.448 37.0066 378.176 35.9304 375.387 35.9304C372.861 35.9304 370.737 36.7914 369.016 38.5031C367.299 40.2149 366.341 42.5058 366.163 45.3706ZM384.219 54.3753L389.896 55.0876C389.003 58.4548 387.345 61.0634 384.919 62.9186C382.493 64.779 379.401 65.7066 375.63 65.7066C370.886 65.7066 367.12 64.2255 364.341 61.2633C361.563 58.301 360.173 54.1498 360.173 48.8044C360.173 43.2796 361.578 38.9849 364.391 35.9304C367.2 32.8759 370.842 31.3538 375.327 31.3538C379.664 31.3538 383.212 32.8503 385.956 35.8381C388.71 38.8311 390.079 43.0388 390.079 48.4661C390.079 48.7941 390.07 49.2913 390.05 49.9524H365.855C366.058 53.5655 367.071 56.3279 368.882 58.2446C370.693 60.1665 372.951 61.1249 375.66 61.1249C377.679 61.1249 379.401 60.5868 380.825 59.5156C382.249 58.4445 383.381 56.7276 384.219 54.3753Z"
          fill="#3069B1"
        />
      </g>
      <defs>
        <clipPath id="clip0_33_2881">
          <rect width="392" height="82" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
