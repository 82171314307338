import React from 'react'

export default function IconAbout() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="18" stroke="url(#paint0_linear_546_590)" strokeWidth="4" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20 7.5C21.1046 7.5 22 8.79524 22 8.79524L22 22.2048C22 22.6676 21.1046 23.5 20 23.5C18.8954 23.5 18 22.6676 18 22.2048L18 8.79524C18 8.67551 18.8954 7.5 20 7.5Z" fill="url(#paint1_linear_546_590)" />
            <g filter="url(#filter0_b_546_590)">
                <ellipse cx="20.0115" cy="28.2759" rx="2.98851" ry="2.98851" transform="rotate(90 20.0115 28.2759)" fill="#BFB5FF" fillOpacity="0.6" />
                <path d="M20.0115 31.0644C18.4714 31.0644 17.223 29.8159 17.223 28.2759C17.223 26.7358 18.4714 25.4874 20.0115 25.4874C21.5515 25.4874 22.8 26.7358 22.8 28.2759C22.8 29.8159 21.5515 31.0644 20.0115 31.0644Z" stroke="url(#paint2_linear_546_590)" strokeWidth="0.4" />
            </g>
            <defs>
                <filter id="filter0_b_546_590" x="4.88398" y="13.1484" width="30.255" height="30.255" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.06948" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_546_590" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_546_590" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_546_590" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6E5CDB" />
                    <stop offset="1" stopColor="#917FFB" />
                </linearGradient>
                <linearGradient id="paint1_linear_546_590" x1="21.125" y1="12.3088" x2="14.8658" y2="17.1348" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#917FFB" />
                    <stop offset="1" stopColor="#3F2DAF" />
                </linearGradient>
                <linearGradient id="paint2_linear_546_590" x1="15.5287" y1="24.5402" x2="22.2529" y2="30.5172" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>

    )
}
