import React from 'react'

export default function IconMobileAppSolution() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.375 23.875V3.4375C25.375 1.54206 23.8329 0 21.9375 0H9.4375C7.54206 0 6 1.54206 6 3.4375V23.875H25.375ZM18.1875 20.125H13.1875C12.6698 20.125 12.25 19.7052 12.25 19.1875C12.25 18.6698 12.6698 18.25 13.1875 18.25H18.1875C18.7052 18.25 19.125 18.6698 19.125 19.1875C19.125 19.7052 18.7052 20.125 18.1875 20.125ZM10.6875 8.5625H11.7354C11.8369 8.13475 12.0056 7.73281 12.2307 7.369L11.4891 6.62738C11.1229 6.26125 11.1229 5.66769 11.4891 5.30156C11.8551 4.93544 12.4487 4.93544 12.8149 5.30156L13.5565 6.04319C13.9203 5.81806 14.3223 5.64937 14.75 5.54787V4.5C14.75 3.98225 15.1698 3.5625 15.6875 3.5625C16.2052 3.5625 16.625 3.98225 16.625 4.5V5.54787C17.0527 5.64937 17.4547 5.81806 17.8185 6.04319L18.5601 5.30156C18.9262 4.93544 19.5198 4.93544 19.8859 5.30156C20.2521 5.66769 20.2521 6.26125 19.8859 6.62738L19.1443 7.369C19.3694 7.73281 19.5381 8.13475 19.6396 8.5625H20.6875C21.2052 8.5625 21.625 8.98225 21.625 9.5C21.625 10.0177 21.2052 10.4375 20.6875 10.4375H19.6396C19.5381 10.8652 19.3694 11.2672 19.1443 11.631L19.8859 12.3726C20.2521 12.7388 20.2521 13.3323 19.8859 13.6984C19.7029 13.8815 19.4629 13.9731 19.2231 13.9731C18.9832 13.9731 18.7432 13.8816 18.5602 13.6984L17.8186 12.9568C17.4547 13.1819 17.0528 13.3506 16.6251 13.4521V14.5C16.6251 15.0177 16.2053 15.4375 15.6876 15.4375C15.1698 15.4375 14.7501 15.0177 14.7501 14.5V13.4521C14.3223 13.3506 13.9204 13.1819 13.5566 12.9568L12.8149 13.6984C12.6319 13.8815 12.3919 13.9731 12.1521 13.9731C11.9122 13.9731 11.6722 13.8816 11.4892 13.6984C11.1231 13.3323 11.1231 12.7388 11.4892 12.3726L12.2308 11.631C12.0057 11.2672 11.837 10.8652 11.7355 10.4375H10.6875C10.1698 10.4375 9.75 10.0177 9.75 9.5C9.75 8.98225 10.1698 8.5625 10.6875 8.5625Z" fill="#FBBE24" />
            <path d="M6 25.75V28.5625C6 30.4579 7.54206 32 9.4375 32H21.9375C23.8329 32 25.375 30.4579 25.375 28.5625V25.75H6ZM16.9375 29.8125H14.4375C13.9198 29.8125 13.5 29.3928 13.5 28.875C13.5 28.3572 13.9198 27.9375 14.4375 27.9375H16.9375C17.4552 27.9375 17.875 28.3572 17.875 28.875C17.875 29.3928 17.4552 29.8125 16.9375 29.8125Z" fill="#FBBE24" />
        </svg>

    )
}
