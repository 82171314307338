import React from "react";
import { Link } from "react-router-dom";
import IconRightArrow from "../SvgIcons/IconRightArrow";
import "./style.css";

export default function TallyPrimeRelease() {
  return (
    <section
      className="tallyprimerelease mb100">
      <div className="container">
        <div className="businessactivities d-flex flex-column flex-sm-row align-item-center">
          <div className="releaseinfo">
            <div className="titletwo">
              <h2>
              <label className="hide"><span>//</span> 03.</label> Sales and stock <span>visibility</span>
              </h2>
            </div>
            <p>
              GS is pursuing main course of business activities is related to
              Sales and stock Visibility. Stock and Sales Visibility is solution
              developed for those Principal Companies having Distribution
              Network across various geography. Global Software has Developed
              DMS Solution on platform of Tally along with the intermediate
              Application of Shoper Ho. The Solution is giving visibility of
              Sales, Stocks, Promotions, trade Discounts, Claims in terms of any
              Geography along with Comparitive statements.
            </p>
            <Link
              to="/tally-prime-release"
              onClick={() => {
                window.scrollTo({ top: 0 });
              }}
              className="btn btn-danger"
            >
             <label> Read More <IconRightArrow /></label>
              <span></span>
            </Link>
          </div>
          <div
            className="releasepicture">
            <figure>
              <img
                src="images/sales-stock-visibility.svg"
                alt="Sales and stock visibility"
                className="img-fluid"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}
