import React from 'react'
import IconMobileAppSolution from '../SvgIcons/IconMobileAppSolution'
import IconTallyConnect from '../SvgIcons/IconTallyConnect'
import IconTallyEnterpriseSolution from '../SvgIcons/IconTallyEnterpriseSolution'
import IconTallyIntegrators from '../SvgIcons/IconTallyIntegrators'
import IconTallySales from '../SvgIcons/IconTallySales'
import './style.css'

export default function WhatWeOffer() {
    return (
        <section className="pb100 whatweoffer">
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='titletwo text-center'>
                            <h2>What we <span>offer?</span></h2>
                        </div>
                    </div>
                </div>
                <div className='whatweoffer-main'>

                    <div className='whatofferBox'>
                        <span>
                            <IconTallySales />
                        </span>
                        <div className='information'>
                            <h4>Tally Sales and Service</h4>
                            <p>Explore seamless solutions with our Tally Sales and Service Support.</p>
                        </div>
                    </div>
                    <div className='whatofferBox'>
                        <span>
                            <IconTallyEnterpriseSolution />
                        </span>
                        <div className='information'>
                            <h4>Tally Enterprise Solution</h4>
                            <p>Discover unparalleled solutions with our Tally Enterprise Solution.</p>
                        </div>
                    </div>
                    <div className='whatofferBox'>
                        <span>
                            <IconTallyIntegrators />
                        </span>
                        <div className='information'>
                            <h4>Tally Integrators</h4>
                            <p>Unlock advanced ERP solutions with our Tally Integration Solutions</p>
                        </div>
                    </div>
                    <div className='whatofferBox'>
                        <span>
                            <IconTallyConnect />
                        </span>
                        <div className='information'>
                            <h4>Tally Connect</h4>
                            <p>In the era of digital adoption, geographical challenges in the distribution of goods persist.
                                Tally Connect addresses this by seamlessly supporting businesses with diverse needs.</p>
                        </div>
                    </div>
                    <div className='whatofferBox'>
                        <span>
                            <IconMobileAppSolution />
                        </span>
                        <div className='information'>
                            <h4>Tally Integrated Mobile App Solution</h4>
                            <p>Elevate your business with our Tally Integrated Mobile App Solution Partner.
                                Benefit from expert guidance, sales support, and dedicated service for seamlessly integrating</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
