import React, { useEffect } from 'react';
import $ from 'jquery';

export function useSmoothScroll(selector, speed = 450) {
  useEffect(() => {
    function SmoothScroll(selector, speed) {
      this.selector = selector;
      this.speed = speed;
      this.init();
    }

    SmoothScroll.prototype = {
      constructor: SmoothScroll,

      init() {
        this._clickHandler = this._clickHandler.bind(this);
        $(this.selector).on('click', this._clickHandler);
      },

      _clickHandler(e) {
        e.preventDefault();
        const target = $(e.target.getAttribute('href'));
        if (target.length) {
          $('html, body').stop().animate(
            {
              scrollTop: target.offset().top,
            },
            this.speed
          );
        }
      },

      destroy() {
        $(this.selector).off('click', this._clickHandler);
      },
    };

    $.fn.smoothScroll = function (speed) {
      this.each((index, el) => {
        new SmoothScroll(el, speed);
      });
    };

    $(selector).smoothScroll(speed);

    return () => {
      $(selector).smoothScroll('destroy');
    };
  }, [selector, speed]);
}

export function SmoothScrollContainer({ children = null }) {
  useSmoothScroll('.smooth-scroll-link');

  return <>{children}</>;
}
