import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import IconArowRightDown from '../SvgIcons/IconArowRightDown';
import { SupportData } from './SupportData';

export default function TallySupportBoxes() {
    const location = useLocation();
    const [activeBox, setActiveBox] = useState(null); // State to keep track of active box

    useEffect(() => {
        const pathname = location.pathname;
        const activeBox = SupportData.find(supportbox => supportbox.url === pathname)?.id;
        setActiveBox(activeBox);
    }, [location.pathname]);
 
  return (
    <section className="d-flex align-items-center pb100 ">
    <div className='container'>
    <div className='row'>
   
    {SupportData.map((supportbox,index) => (
        <div className={index >= SupportData.length - 2 ? 'col-6 col-sm-3 col-md-3 mt-3 mt-sm-0' : 'col-6 col-sm-3 col-md-3'} key={supportbox.id}>
          <Link
            to={supportbox.url}
            className={`support-box position-relative ${activeBox === supportbox.id ? 'active' : ''}`}>
         <img src={supportbox.img} alt={supportbox.title} className='img-fluid'/>
         <div className='box-title d-flex justify-content-between align-items-end position-absolute'>
         <h5 dangerouslySetInnerHTML={{ __html: supportbox.title }} />
          <IconArowRightDown />
          </div>
         </Link>
         </div>
    ))}   
    </div>
    </div>
    </section>
  )
}
