import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import GlobalLogo from "../SvgIcons/GlobalLogo";
import IconAbout from "../SvgIcons/IconAbout";
import IconContact from "../SvgIcons/IconContact";
import IconDownloads from "../SvgIcons/IconDownloads";
import IconEvents from "../SvgIcons/IconEvents";
import IconHome from "../SvgIcons/IconHome";
import IconMyAccount from "../SvgIcons/IconMyAccount";
import IconPricing from "../SvgIcons/IconPricing";
import IconProducts from "../SvgIcons/IconProducts";
import IconServices from "../SvgIcons/IconServices";
import IconTallySupport from "../SvgIcons/IconTallySupport";
import IconTallyprime from "../SvgIcons/IconTallyprime";
import IconWebsite from "../SvgIcons/IconWebsite";
import "./style.css";

export default function Navigation() {
  const [hoverHint, setHoverHint] = useState(false);  
  const location = useLocation();
  useEffect(() => {
    document.body.classList.remove("hidemobile");
  }, [location]);

  //Mobile Click open menu end//

  // desktop menu hover effect add className//
  function showHoverHint() {
    setHoverHint(true);
  }
  function hideHoverHint() {
    setHoverHint(false);
  }

  // click open dropdown menu mobile view

  const [dropdownIndex, setDropdownIndex] = useState();
  const dropdownClick = (index) => {
    setDropdownIndex(index);
  };

  return (
    <div
      className={hoverHint ? "navigation show-hint" : "navigation hide-hint"}
    >
      <ul className="d-flex">
        <li>
          <Link to="/" className="menubox homemenu">
            <IconHome />
            <span>Home</span>
          </Link>
        </li>

        <li
          onMouseOver={showHoverHint}
          onMouseOut={hideHoverHint}
          onClick={() => dropdownClick(0)}
          className={dropdownIndex === 0 ? "showmenu" : "hidemenu"}
        >
          <Link className="menubox productsmenu">
            <IconProducts />
            <span>Products</span>
          </Link>
          <div className={dropdownIndex ? "dropdownhover active" : "dropdownhover"}>
            <ul className="submenu">
              <li>
                <Link to="/tally-prime">
                  <div className="icontop">
                    <img
                      src="../images/tally-logo-center.png"
                      alt="Tally Center"
                    />
                  </div>
                  Tally Products
                </Link>
              </li>
              <li>
                <Link to="/our-products">
                  <div className="icontop">
                    <img src="../images/gl.png" alt="Global Software" />
                  </div>
                  Our Solutions & Products
                </Link>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <Link to="/tally-support" className="menubox servicesmenu">
            <IconServices />
            <span>Services</span>
          </Link>       
        </li>

        <li>
          <Link to="/price-india" className="menubox pricingmenu">
            <IconPricing />
            <span>Pricing</span>
          </Link>
        </li>

        <li>
          <Link to="/events" className="menubox eventsmenu">
            <IconEvents />
            <span>Events</span>
          </Link>
        </li>

        <div className="logo">
          <Link
            to="/"
            onClick={() => {
              window.scrollTo({ top: 0 });
            }}
          >
            <GlobalLogo/>
          </Link>
        </div>

        <li
          onMouseOver={showHoverHint}
          onMouseOut={hideHoverHint}
          onClick={() => dropdownClick(0)}
          className={dropdownIndex === 0 ? "showmenu" : "hidemenu"}
        >
          <Link className="menubox downloadsmenu">
            <IconDownloads />
            <span>Downloads</span>
          </Link>
          <div className={dropdownIndex ? "dropdownhover active" : "dropdownhover"}>
            <ul className="submenu">
              <li>
                <a href="images/satup/tallyprime_setup5-0.exe" download>
                  <div className="icontop">
                    <IconTallyprime />
                  </div>
                  Tallyprime
                </a>
              </li>
              <li>
              <a href="images/satup/TallyPrimeServer_setup4-0.exe" download>
                  <div className="icontop">                    
                    <img
                      src="../images/tallyprime-server.png"
                      alt=" TallyPrime Server"
                    />
                  </div>
                  TallyPrime Server
                </a>
              </li>

              <li>
              <a href="images/satup/AnyDesk.exe" download>
                  <div className="icontop">
                    
                    <img src="../images/anydesk.png" alt="anydesk" />
                  </div>
                  AnyDesk
                </a>
              </li>
              <li>
              <a href="images/satup/UltraViewer_Desktop.exe" download>
                  <div className="icontop">
                    <img src="../images/UltraViewer.png" alt="UltraViewer" width={23} height={23} />
                  </div>
                  Ultra Viewer
                </a>
              </li>
              <li>
               <Link to="https://play.google.com/store/apps/details?id=globalsoftwares.com.global_query&pli=1" target="_blank">
                  <div className="icontop">
                    <IconTallySupport />
                  </div>
                  Global Tally Care App
                </Link>                  
              </li>
            </ul>
          </div>
        </li>
          <li>
          <Link to="/contact" className="menubox contactmenu">
            <IconContact />
            <span>Contact</span>
          </Link>
        </li>
        <li>
          <Link to="/about" className="menubox aboutmenu">
            <IconAbout />
            <span>About</span>
          </Link>
        </li>
        <li>
          <Link to="https://globalinfosoft.net/crf/" target="_blank" className="menubox myaccountmenu">
            <IconMyAccount />
            <span>My Account</span>
          </Link>
        </li>        
        <li
          onMouseOver={showHoverHint}
          onMouseOut={hideHoverHint}
          onClick={() => dropdownClick(0)}
          className={dropdownIndex === 0 ? "showmenu" : "hidemenu"}
        >
          <Link className="menubox websitemenu">
            <IconWebsite />            
          </Link>
          <div className={dropdownIndex ? "dropdownhover active" : "dropdownhover"}>
            <ul className="submenu">
              <li>
                <Link to="https://globalsoftwares.net/">                
                  <div className="icontop">
                    <img
                      src="../images/flegs/india.png"
                      alt="India"
                      className="img-fluid"
                    />
                  </div>
                  India
                </Link>
              </li>
              <li>
               <Link to="https://www.tgstl.net/">
                  <div className="icontop">
                    <img
                      src="../images/flegs/tanzania.png"
                      alt="Tanzania"
                      className="img-fluid"
                    />
                  </div>
                  Tanzania
                </Link>
              </li>
              <li>
                <Link >
                  <div className="icontop">
                    <img
                      src="../images/flegs/united-arab-emirates.png"
                      alt="UAE"
                      className="img-fluid"
                    />
                  </div>
                  UAE
                </Link>
              </li>
              <li>
                <Link to="http://tallyangola.com/">
                  <div className="icontop">
                    <img
                      src="../images/flegs/angola.png"
                      alt="Angola"
                      className="img-fluid"
                    />
                  </div>
                  Angola
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
}
