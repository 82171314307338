import React from "react";

export default function IconBurger() {
  return (
    <svg
      width="249"
      height="171"
      viewBox="0 0 249 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.67651 6.55859H242.443M6.67651 85.1477H242.443M6.67651 163.737H242.443"
        stroke="black"
        strokeWidth="13.0981"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
