import React from "react";
import ApplicationScanApp from "../../component/ApplicationScanApp";
import PageLayout from "../../component/PageLayout";
import TallySupportBoxes from "../../component/TallySupportBoxes";
import "./style.css";
import GoogleTagManager from "../../component/GoogleTagManager";

export default function TallySupport() {
  return (
    <div className="home-main supportpage">
      <GoogleTagManager/>
      <PageLayout>
        <TallySupportBoxes />

        <section  className="tallysupport-main">
          <div className="container">
          <div className="titletwo onlyone phonetitlecenter">
                  <h2>Tally <span>Support</span> </h2>
                </div>
            <div className="tallysupport-boxmain d-flex flex-column flex-md-row justify-content-between gap100">
              <div className="tallysupport-pic">
                <img src="images/ServicesSupport/global.svg" alt="" />
              </div>
              <div className="tallysupport-info">
                <div className="titletwo">
                  <h2>
                    Sign up for our  <span>New Application Global Tally Care</span> <br></br>and get
                    instant support online.
                  </h2>
                </div>
                <p>
                  <span>
                    Easy to use Mobile app and Web Portal for getting Tally
                    Support <strong>Instantly</strong>
                  </span>
                </p>
                <p>
                  "<label>Global Tally Care</label> (<label>GTC</label>) is your
                  dedicated support platform for Tally Prime, offering a
                  seamless experience across Android, web, and direct
                  integration within Tally software. Streamline issue reporting
                  and resolution through our user-friendly interface. Benefit
                  from accessibility and flexibility, ensuring optimal user
                  experience on every device. With regular updates and a
                  commitment to user feedback, GTC stands as the go-to solution
                  for Tally Prime users seeking efficient, reliable, and
                  comprehensive support. Elevate your Tally Prime experience
                  with GTC – where support meets innovation."
                </p>
              </div>
            </div>
          </div>
        </section>

        <ApplicationScanApp/>
      </PageLayout>
    </div>
  );
}
