export const SupportData = [
    {
        id:1,
        img:'images/ServicesSupport/support1.png',
        title:'Tally <br/> Support',
        url:'/tally-support'
    },
    {
        id:2,
        img:'images/ServicesSupport/support2.png',
        title:'Corporate Tally <br/>Implementation',
        url:'/corporate-implementation'
    },
    {
        id:3,
        img:'images/ServicesSupport/support3.png',
        title:'Our Value <br/>Added Services',
        url:'/value-added-services'
    },
    {
        id:4,
        img:'images/ServicesSupport/support4.png',
        title:'Annual Maintenance <br/>Contract',
        url:'/annual-maintainance-contract'
    }
]