export const OurModualesOne = [
    {
        id:1,
        img:'images/Our-Products/module-pic1/recycle-bin.svg',
        title:'Recycle Bin Module',
        url:"#"       
    },
    {
        id:2,
        img:'images/Our-Products/module-pic1/party-gstin.svg',
        title:'Party GSTIN No. Details Display in Sales Entry & Purchase Entry',
        url:"#"       
    },
    {
        id:3,
        img:'images/Our-Products/module-pic1/stock-item.svg',
        title:'Stock Item HSN / SAC Code Display On Sales Entry & purchase Entry',
        url:"#"        
    },
    {
        id:4,
        img:'images/Our-Products/module-pic1/multiple-narration.svg',
        title:'Multiple Narration',
        url:"#"        
    },
    {
        id:5,
        img:'images/Our-Products/module-pic1/unlimited-description.svg',
        title:'Unlimited Description In Stock Item',
        url:"#"        
    },
    {
        id:6,
        img:'images/Our-Products/module-pic1/sales-invoice.svg',
        title:'Party O/S in sales Invoice',
        url:"#"       
    },
    {
        id:7,
        img:'images/Our-Products/module-pic1/detailed-balance.svg',
        title:'Detailed Balance Sheet and P & L',
        url:"#"       
    },
    {
        id:8,
        img:'images/Our-Products/module-pic1/multi-voucher.svg',
        title:'Multi Voucher printing As Per Selection Of Vch.No. “From-To”',
        url:"#"       
    },
    {
        id:9,
        img:'images/Our-Products/module-pic1/document-management.svg',
        title:'Document Management System',
        url:"#"       
    },
    {
        id:10,
        img:'images/Our-Products/module-pic1/negative-stock.svg',
        title:'Negative Stock Blocking',
        url:"#"       
    },
    {
        id:11,
        img:'images/Our-Products/module-pic1/price-list.svg',
        title:'Price List Import From Excel',
        url:"#"       
    },
    {
        id:12,
        img:'images/Our-Products/module-pic1/authentication.svg',
        title:'First Level Voucher Authentication',
        url:"#"       
    },
    {
        id:13,
        img:'images/Our-Products/module-pic1/cost-center.svg',
        title:'Cost Center Wise Profit & Loss A/C',
        url:"#"       
    },
]


export const OurModualesTwo = [
    {
        id:1,
        img:'images/Our-Products/module-pic2/address-label.svg',
        title:'Address Label Printing Module',
        url:"#"       
    },
    {
        id:2,
        img:'images/Our-Products/module-pic2/system-date.svg',
        title:'System Date As entry date in Tally',
        url:"#"       
    },
    {
        id:3,
        img:'images/Our-Products/module-pic2/grid-line.svg',
        title:'Grid Line In Report',
        url:"#"        
    },
    {
        id:4,
        img:'images/Our-Products/module-pic2/group-wise.svg',
        title:'Group Wise Stock Item Filter hile Invoicing',
        url:"#"        
    },
    {
        id:5,
        img:'images/Our-Products/module-pic2/voucher-create.svg',
        title:'Voucher Create Alter Date & Time Details In Print /Voucher/ register',
        url:"#"        
    },
    {
        id:6,
        img:'images/Our-Products/module-pic2/envelope.svg',
        title:'Envelope Printing Module',
        url:"#"       
    },
    {
        id:7,
        img:'images/Our-Products/module-pic2/instead.svg',
        title:'Buyer Name Instead of Cash in Register',
        url:"#"       
    },
    {
        id:8,
        img:'images/Our-Products/module-pic2/negative.svg',
        title:'Negative Cash Blocking',
        url:"#"       
    },
    {
        id:9,
        img:'images/Our-Products/module-pic2/utility.svg',
        title:'Auto Backup Utility',
        url:"#"       
    },
    {
        id:10,
        img:'images/Our-Products/module-pic2/security-module.svg',
        title:'Voucher Type Security Module',
        url:"#"       
    },
    {
        id:11,
        img:'images/Our-Products/module-pic2/godown.svg',
        title:'Godown Security Module',
        url:"#"       
    },
    {
        id:12,
        img:'images/Our-Products/module-pic2/invoice-printing.svg',
        title:'Sign & Seal on-Invoice Printing',
        url:"#"       
    },
    {
        id:13,
        img:'images/Our-Products/module-pic2/export-invoice.svg',
        title:'Export Invoice',
        url:"#"       
    },
]


export const OurModualesThree = [
    {
        id:1,
        img:'images/Our-Products/module-pic3/sma-solution.svg',
        title:'SMS Solution',
        url:"#"       
    },
    {
        id:2,
        img:'images/Our-Products/module-pic3/userwise-dashboard.svg',
        title:'User Wise Dashboard',
        url:"#"       
    },
    {
        id:3,
        img:'images/Our-Products/module-pic3/step-voucher.svg',
        title:'Two - Step Voucher Authentication',
        url:"#"        
    },
    {
        id:4,
        img:'images/Our-Products/module-pic3/whatsApp-module.svg',
        title:'WhatsApp Module',
        url:"#"        
    },
    {
        id:5,
        img:'images/Our-Products/module-pic3/brokerage-management.svg',
        title:'Brokerage Management(Brokerage + Outstanding)',
        url:"#"        
    },
    {
        id:6,
        img:'images/Our-Products/module-pic3/pre-defined.svg',
        title:'Import From Excel in Pre-defined Format per Voucher Type',
        url:"#"       
    },
    {
        id:7,
        img:'images/Our-Products/module-pic3/accounting-master.svg',
        title:'Inventory / Accounting Master Import From Excel',
        url:"#"       
    },
    {
        id:8,
        img:'images/Our-Products/module-pic3/printer-selection.svg',
        title:'Auto printer Selection - Voucher Wise',
        url:"#"       
    },
    {
        id:9,
        img:'images/Our-Products/module-pic3/restriction.svg',
        title:'Rate & Amount Restriction For User In Delivery Note & Receipt Note Entry',
        url:"#"       
    },
    {
        id:10,
        img:'images/Our-Products/module-pic3/auto-receipt.svg',
        title:'Auto Receipt while Billing',
        url:"#"       
    },
    {
        id:11,
        img:'images/Our-Products/module-pic3/neft-management.svg',
        title:'NEFT (RTGS Payement Management)',
        url:"#"       
    },
    {
        id:12,
        img:'images/Our-Products/module-pic3/email-management.svg',
        title:'Email Management (Sales or Reciept)',
        url:"#"       
    },
    {
        id:13,
        img:'images/Our-Products/module-pic3/ledger-security.svg',
        title:'Group Ledger Security',
        url:"#"       
    },
]