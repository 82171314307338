import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ApplicationScanApp from '../../component/ApplicationScanApp'
import PageLayout from '../../component/PageLayout'
import PriceTabs from '../../component/PriceTabs'
import { IndiaPriceData } from '../../component/PriceTabs/IndiaPriceData'
import GoogleTagManager from '../../component/GoogleTagManager'

export default function PriceTanzania() {
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    // Simulate page load animation with a delay
    setTimeout(() => setPageLoaded(true), 500);
}, []);
    const customPrices = ['$ 630', '$ 1,890', '$ 9,450'];
    const customTex = ['(+ Tax)', '(+ Tax)', '(+ Tax)'];
  return (
    <div className="home-main">
      <GoogleTagManager/>
    <PageLayout>
      <PriceTabs />
      <div className="container">
      <div className={`price-table-main d-flex flex-column flex-sm-row flex-md-row flex-wrap justify-content-between ${pageLoaded ? 'loaded' : ''}`}>
          {IndiaPriceData.map((pricetable, index) => (
             <div
             key={pricetable.id}
             className={`tallypricebox box-default text-center ${
               index === 0 ? 'first-box' : index === 1 ? 'goldcolor second-box' : 'bluecolor third-box'
             }`}>
              <div className="tallylogo">{pricetable.logo ? (pricetable.logo) : (<img src={pricetable.logoSrc} alt=""/>)}</div>
              
              <h4>{pricetable.title}</h4>
              <h1>{customPrices[index]}</h1>
              <p>{customTex[index]}</p>
              <ul>
                  {Object.values(pricetable.condition).map((description, index) => (
                    <li key={index}>{description}</li>
                  ))}
                </ul>
             
              <div className="buybtn">
                <Link onClick={() => { window.scrollTo({ top: 0 });}} to="/contact" className="btn">
                  <label>{pricetable.btn}
                  {pricetable.icon}</label>
                  <span></span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ApplicationScanApp/>
    </PageLayout>
  </div>
  )
}
