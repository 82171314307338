import IconRightArrow from "../SvgIcons/IconRightArrow";
import IconTallyPrimelogo from "../SvgIcons/IconTallyPrimelogo";

export const  IndiaPriceData =[
    {
        id:1,
        logo:<IconTallyPrimelogo/>,
        title:'SILVER',
        price:'₹ 22,500',
        condition:{
            data1:"Single user edition for standalone PCs",
            data2:"Remote Access",
            data3:"One User",
            data4:"One Remote User",
        },
        btn:'Contact Us',
        icon:<IconRightArrow/> ,
    },
    {
        id:2,
        logo:<IconTallyPrimelogo/>,
        title:'GOLD',
        price:'₹ 67,500',
        condition:{
            data1:"Unlimited user edition for multiple PCs",
            data2:"Remote Access",
            data3:"Unlimited Users",
            data4:"Ten Remote Users",
        },
        btn:'Contact Us',
        icon:<IconRightArrow/> ,
    },
    {
        id:3,
        logoSrc:'images/primeserver.svg',
        title:'SERVER',
        price:'₹ 2,70,000',
        condition:{
            data1:"High Concurrency",
            data2:"Secured by design",
            data3:"Unlimited Users",
            data4:"Twenty Remote Users",
        },
        btn:'Contact Us',
        icon:<IconRightArrow/> ,
    }
]