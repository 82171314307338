import React from "react";
import PageLayout from "../../component/PageLayout";
import "./style.css";
import OurClient from "../../component/OurClient";
import GoogleTagManager from "../../component/GoogleTagManager";
import IndustriesWeServer from "../../component/IndustriesWeServer";

export default function AboutUs() {
  return (
    <div className="home-main about-page">
      <GoogleTagManager/>
      <PageLayout>
        <div className="Innerpage-title">
          <h1 className="title1 text-center box-default">About</h1>
        </div>

        <section className="about-banner">
          <div className="container">
            <div className="aboutbanner-box pb100 pt100 mb100">
              <div
                className="hometitle d-flex flex-column">
                <label>Welcome to</label>
                <h1>
                   <span>G</span>lobal <span>S</span>oftware
                </h1>
                <h6>Your Enterprise Excellence Begins Here!</h6>
              </div>           
            </div>
          </div>
        </section>
        <section className="who-are-main pt100">
          <div className="container">
              <div className="technologymain d-flex align-items-center">
              <div
                className="col-12 col-md-4 text-center">
                <img
                  src="images/about/who-we-are.svg"
                  className="img-fluid"
                  alt="who we are"
                />
              </div>
              <div
                className="col-12 col-md-8">
                <div className="technologybox">
                  <div className="titletwo">
                    <h2>
                      Who Are <span>We?</span>
                    </h2>
                  </div>
                  <p>
                    Global Software (GS) is a software technology & innovation
                    company. We are pleased to introduce ourselves as one of the
                    leading commercial software application vendor and service
                    providers. GS, continuously strives to improve its software
                    engineering and delivery processes by applying industry
                    standard methodologies and quality assurance programs. We
                    have a high quality infrastructure to ensure maximum
                    reliability and efficiency at all times and a very creative
                    team that understands the client's requirements and delivers
                    tailor-fit solutions with access to top-level technical and
                    creative talent in many disciplines. GS deals in Tally based
                    enterprise Solutions, Distribution Management
                    Applications(DMS), CRM and Mobile based Integrated
                    Applications.
                  </p>
                  <p>
                    Global Software is a five-star Sales & Solution channel
                    partner of Tally Software since 2002. Global Software, with
                    a rich experience in the implementation of Enterprise
                    business solutions in Tally enterprise framework – offers
                    complete Tally solutions, which includes consultancy,
                    design, development (customization and Integration),
                    implementation and training. We add value to productivity of
                    our customers by bringing excellent understanding of the
                    business processes to the table. Our experienced project
                    management team with proven technical capabilities ensures
                    that our customers receive Intelligent Solutions in the
                    timeliest, cost efficient & reliable manner. We follow a
                    well-defined methodology that enables customers to explore
                    the benefits of Tally to its fullest, and in a most
                    cost-effective manner possible. The secret of GS success
                    lies in its flexibility, responsiveness to customers,
                    encouragement and reward to employees and well satisfied
                    business partners. GS always focused on providing
                    off-the-shelf software solution that are designed
                    specifically for small-to-Mid Enterprise (SME) and
                    distributed enterprise markets. GS an excellent track record
                    in IT consultancy and services, serving customers in varied
                    sectors such as Manufacturing, Retail, Service sector,
                    Distribution, Distribution of FMCG, Industrial Oil,
                    Transport, Finance & Government and for all type of business
                    application solutions.
                  </p>
                  <p>
                    Presence: Established way back in 2002 Global Sofware has
                    progressed vertically & horizontally across three continents
                    across the globe in the span of 20 years. Started Africa
                    Operations in the year 2011 in Tanzania, Now Global Group
                    Companies are opeative in Dubai, UAE and Angola in
                    South-west Country of Africa.
                  </p>
                </div>
              </div>
              </div>
            
          </div>
        </section>

        <section className="ourgroup-main">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-12">
                <div className="who-are-info">
                  <img
                    src="images/about/our-group.svg"
                    className="img-fluid"
                    alt="our group"/>
                  <div className="titletwo">
                    <h2>
                      Our <span>Group</span>
                    </h2>
                  </div>
                  <p>
                    Global Group is a renowned Enterprise Consulting and
                    Implementation service company catering business
                    augmentation across the globe. We assist our clients to
                    optimize their business processes by incorporating
                    respective Software Application into day to day business
                    activities to smoothen the real-time and practical problems
                    of the business life cycle. We enable our clients by not
                    only enhancing their productivity immensely but also
                    extending the impact to their clients and suppliers. Global
                    Group, with rich experience in the implementation of
                    Enterprise business solutions in Tally enterprise framework
                    - offers complete Tally solutions, which includes
                    consultancy, design, development, implementation, and
                    training.
                  </p>
                  <p>
                    We add value to the productivity of our customers by
                    bringing an excellent understanding of the business
                    processes of various kinds. The organization has in house
                    competence to understand fully, the complexities of any
                    business environment and offers suitable solutions on
                    Tally's robust and trusted framework with extension of "any"
                    third party data integration as part of solutions.
                  </p>
                  <p>
                    Established in the year 2002, Global group has extended
                    their Arms in the African continent and Gulf Region with the
                    intention to serve overseas clients to impart local touch
                    along with the solution framework.Global Group Companies has
                    been accredited with various titles by Tally Solutions Pvt.
                    Ltd., Bangalore, India according to the policies of Eco
                    SystemsGlobal Software, Ahmedabad, India - 5 Star Tally
                    Sales & Solution Partner
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="about-flegs">
          <div className="container">
            <ul className="flegsbox d-flex justify-content-between  flex-wrap">
              <li className="d-flex align-items-center">
                <img
                  src="images/flegs/india.png"
                  className="img-fluid"
                  alt="india"
                />
                <p>India</p>
              </li>
              <li className="d-flex align-items-center">
                <img
                  src="images/flegs/tanzania.png"
                  className="img-fluid"
                  alt="Tanzania"
                />
                <p>Tanzania</p>
              </li>
              <li className="d-flex align-items-center">
                <img
                  src="images/flegs/united-arab-emirates.png"
                  className="img-fluid"
                  alt="UAE"
                />
                <p>U.A.E</p>
              </li>
              <li className="d-flex align-items-center">
                <img
                  src="images/flegs/angola.png"
                  className="img-fluid"
                  alt="angola"
                />
                <p>Angola</p>
              </li>
              <div className="liquid"></div>
            </ul>
          </div>
        </section>

       <IndustriesWeServer/>

        <section className="our-mission-main">
          <div className="container">
          <div className="d-flex missionmission justify-content-between flex-column flex-md-row-reverse align-items-center">
            <div className="missionpicture">
            <img
              src="images/about/our-mission.svg"
              className="img-fluid"
              alt="our group"
            />
          </div>  
            <div
              className="titletwo">
              <h2>
                Our <span>Mission</span>
              </h2>
              <p className="mb" style={{ marginTop: 15 }}>
                Our Mission is to be a pioneer and leader in providing the most
                valued solution in the software industry Across the Globe. We
                aim to empower our clients to exponential growth and excellence
                by providing innovative products and solutions. Also, the vision
                of the company is to provide the highest quality services
                thereby increasing customer satisfaction and loyalty.
              </p>
            </div>
          </div>
          </div>
        </section>
        <section>
        <div className="container">
        <div className="mt100 pt100 experienced mb100">
          
        <div
              style={{ marginBottom: "30px" }}
              className="titletwo">
              <h2>
                Our Core <span>Values</span>
              </h2>
            </div>
            <p style={{ marginBottom: "30px" }}>
              <strong style={{ marginBottom: "15px", display: "inline-block" }}>
                Teamwork
              </strong>
              <br></br>
              Our employees strive to work with the customers directly with
              freedom of learning the business process to come up with efficient
              results in turn with respective and desired solution. On the
              insides of our workplace, we make sure all our employees are
              collectively responsible for the goals & targets of the
              organization.We take pride of having most experienced and
              technology driven team members on board of Global Software. All
              team members are value added assets of the Organization.
            </p>
            <p style={{ marginBottom: "30px" }}>
              <strong style={{ marginBottom: "15px", display: "inline-block" }}>
                Customer Satisfaction
              </strong>
              <br></br> At Global Software, customer satisfaction is believed to
              be of utmost importance. All members are happens to be extremely
              motivated to provide premium customer satisfaction & strive
              towards improvement on a daily basis to ensure providing the best
              services & products to our customers.
            </p>
            <p style={{ marginBottom: "30px" }}>
              <strong style={{ marginBottom: "15px", display: "inline-block" }}>
                Loyalty
              </strong>
              <br></br> Loyalty is Very Important Factor in organizational
              values in terms of retention of the customers and manpower
              precisely, in Service Industry like us. We are very proud to say
              that we have very less attrition ratio both in Customer and
              Employee Segments.
            </p>
            <p style={{ marginBottom: "0" }}>
              <strong style={{ marginBottom: "15px", display: "inline-block" }}>
                Innovation
              </strong>
              <br></br> Every individual has to adopt newer concepts and
              technology and have to cop-up with the enhancements takes places
              surrounding us. At Global Software also we are striving to adopt
              various technology time to time to abreast knowledge and working
              for better delivery of the solutions we design.
            </p>
            </div>
            </div>
        </section>
        <OurClient />
      </PageLayout>
    </div>
  );
}
