export const TallyProductData = [
    {
        id: 1,
        title: "TallyPrime",  
        url:'/tally-prime',
       
         
    },
    {
        id: 2,
        title: "TallyPrime Server", 
        url:'/tally-prime-server',       
    },
    {
        id: 3,
        title: "TallyPrime using AWS",  
        url:'/tally-prime-using-aws',
       
    },
    {
        id: 4,
        title: "Tally Software Service(TSS)", 
        url:'/tally-software-service',
              
    }

];

