import React, { useState } from "react";
import Navigation from "../Navigation";
import NavigationPhone from "../NavigationPhone";
import "./style.css";

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
    return (
        <header className={isNavOpen ? 'isopenmenu':''}>
            <div className="container">
            <NavigationPhone isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="headertop d-flex">
                         <Navigation />                      
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
