import React from 'react'

export default function IconTallySales() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.121 29.2421H28.1249V9.90623H29.0038C29.323 9.90623 29.6174 9.73283 29.7729 9.45305C29.9272 9.17418 29.9186 8.83269 29.7487 8.56133L25.3542 1.38507C25.0332 0.871643 24.1854 0.871643 23.8643 1.38507L19.4697 8.56133C19.2998 8.83269 19.2912 9.17418 19.4458 9.45305C19.601 9.73283 19.8955 9.90623 20.2148 9.90623H21.0937V29.2421H19.3359V14.3008C19.3359 13.8152 18.9426 13.4219 18.457 13.4219H13.1836C12.6978 13.4219 12.3047 13.8152 12.3047 14.3008V29.2421H10.5469V19.5742C10.5469 19.0886 10.1535 18.6953 9.66795 18.6953H4.39452C3.90874 18.6953 3.51562 19.0886 3.51562 19.5742V29.2421H0.878905C0.393127 29.2421 0 29.6354 0 30.121C0 30.6068 0.393127 30.9999 0.878905 30.9999H29.121C29.6066 30.9999 29.9999 30.6068 29.9999 30.121C29.9999 29.6354 29.6066 29.2421 29.121 29.2421Z" fill="#FBBE24" />
            <path d="M2.63671 8.14842C3.12158 8.14842 3.51562 8.54247 3.51562 9.02733C3.51562 9.51237 3.12158 9.90623 2.63671 9.90623C2.32324 9.90623 2.03155 9.73814 1.87445 9.46587C1.63238 9.04619 1.09424 8.89952 0.673827 9.1458C0.253967 9.38878 0.109863 9.92601 0.353576 10.3466C0.669798 10.8941 1.17773 11.2846 1.75781 11.4925V12.4725C1.75781 12.9584 2.15094 13.3514 2.63671 13.3514C3.12231 13.3514 3.51562 12.9584 3.51562 12.4725V11.502C4.53643 11.1382 5.27343 10.1717 5.27343 9.02733C5.27343 7.57347 4.09057 6.39061 2.63671 6.39061C2.15167 6.39061 1.75781 5.99676 1.75781 5.51171C1.75781 5.02685 2.15167 4.63281 2.63671 4.63281C2.95001 4.63281 3.24188 4.80108 3.3988 5.07317C3.64013 5.49212 4.17571 5.63622 4.5996 5.39342C5.01928 5.15044 5.16356 4.61321 4.91967 4.19244C4.60345 3.64495 4.09551 3.25439 3.51562 3.04675V1.8789C3.51562 1.39331 3.12231 1 2.63671 1C2.15094 1 1.75781 1.39331 1.75781 1.8789V3.03686C0.736998 3.40106 0 4.3673 0 5.51171C0 6.96556 1.18286 8.14842 2.63671 8.14842Z" fill="#FBBE24" />
        </svg>

    )
}
