import React from "react";

export default function IconClose() {
  return (
    <svg
      width="176"
      height="176"
      viewBox="0 0 176 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M173.069 160.649C173.885 161.464 174.533 162.432 174.975 163.498C175.417 164.564 175.644 165.706 175.645 166.86C175.645 168.014 175.418 169.156 174.977 170.222C174.535 171.288 173.888 172.257 173.072 173.072C172.257 173.888 171.288 174.535 170.222 174.977C169.156 175.418 168.014 175.645 166.86 175.645C165.706 175.644 164.564 175.417 163.498 174.975C162.432 174.533 161.464 173.885 160.649 173.069L87.8184 100.238L14.988 173.069C13.3407 174.714 11.1075 175.638 8.77932 175.637C6.45112 175.636 4.21849 174.711 2.57221 173.065C0.925926 171.418 0.000730484 169.186 4.32375e-07 166.858C-0.000729619 164.529 0.923068 162.296 2.56832 160.649L75.3987 87.8184L2.56832 14.988C0.923068 13.3407 -0.000729619 11.1075 4.32375e-07 8.77932C0.000730484 6.45112 0.925926 4.21849 2.57221 2.57221C4.21849 0.925926 6.45112 0.000730484 8.77932 4.32375e-07C11.1075 -0.000729619 13.3407 0.923068 14.988 2.56832L87.8184 75.3987L160.649 2.56832C162.296 0.923068 164.529 -0.000729619 166.858 4.32375e-07C169.186 0.000730484 171.418 0.925926 173.065 2.57221C174.711 4.21849 175.636 6.45112 175.637 8.77932C175.638 11.1075 174.714 13.3407 173.069 14.988L100.238 87.8184L173.069 160.649Z"
        fill="white"
      />
    </svg>
  );
}
