import React from 'react'

export default function IconEvents() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_546_38)">
                <path d="M0 13.6762C0 11.3413 0 10.1738 0.38492 9.24519C0.965485 7.84457 2.15184 6.7373 3.65251 6.19544C4.64747 5.83618 5.89831 5.83618 8.4 5.83618H31.6C34.1017 5.83618 35.3525 5.83618 36.3475 6.19544C37.8482 6.7373 39.0345 7.84457 39.6151 9.24519C40 10.1738 40 11.3413 40 13.6762V25.1695H0V13.6762Z" fill="url(#paint0_linear_546_38)" />
            </g>
            <g filter="url(#filter1_b_546_38)">
                <rect x="5.71411" width="8.57143" height="10.6667" rx="4.28571" fill="#FFB7B5" fillOpacity="0.6" />
                <rect x="5.89465" y="0.180537" width="8.21035" height="10.3056" rx="4.10518" stroke="url(#paint1_linear_546_38)" strokeWidth="0.361074" />
            </g>
            <g filter="url(#filter2_b_546_38)">
                <rect x="25.7141" width="8.57143" height="10.6667" rx="4.28571" fill="#FFB7B5" fillOpacity="0.6" />
                <rect x="25.8946" y="0.180537" width="8.21035" height="10.3056" rx="4.10518" stroke="url(#paint2_linear_546_38)" strokeWidth="0.361074" />
            </g>
            <g filter="url(#filter3_b_546_38)">
                <path d="M0 23.9999C0 21.3811 0 20.0716 0.309314 19.0025C1.05805 16.4146 3.08135 14.3913 5.66927 13.6426C6.73837 13.3333 8.0478 13.3333 10.6667 13.3333H29.3333C31.9522 13.3333 33.2616 13.3333 34.3307 13.6426C36.9186 14.3913 38.942 16.4146 39.6907 19.0025C40 20.0716 40 21.3811 40 23.9999C40 27.9282 40 29.8924 39.536 31.496C38.4129 35.3779 35.378 38.4128 31.4961 39.536C29.8924 39.9999 27.9283 39.9999 24 39.9999H16C12.0717 39.9999 10.1076 39.9999 8.5039 39.536C4.62203 38.4128 1.58707 35.3779 0.46397 31.496C0 29.8924 0 27.9282 0 23.9999Z" fill="#FFB7B5" fillOpacity="0.6" />
                <path d="M0.180537 23.9999C0.180537 21.3685 0.18233 20.091 0.482738 19.0527C1.21426 16.5243 3.19104 14.5475 5.71944 13.816C6.75776 13.5156 8.03527 13.5138 10.6667 13.5138H29.3333C31.9647 13.5138 33.2422 13.5156 34.2806 13.816C36.809 14.5475 38.7857 16.5243 39.5173 19.0527C39.8177 20.091 39.8195 21.3685 39.8195 23.9999C39.8195 27.9408 39.8177 29.873 39.3626 31.4459C38.2567 35.2682 35.2683 38.2566 31.4459 39.3625C29.8731 39.8176 27.9408 39.8194 24 39.8194H16C12.0592 39.8194 10.1269 39.8176 8.55407 39.3625C4.73172 38.2566 1.74328 35.2682 0.637395 31.4459C0.18233 29.873 0.180537 27.9408 0.180537 23.9999Z" stroke="url(#paint3_linear_546_38)" strokeWidth="0.361074" />
            </g>
            <g filter="url(#filter4_b_546_38)">
                <rect x="28.5713" y="21.3333" width="2.66667" height="17.1429" rx="1.33333" transform="rotate(90 28.5713 21.3333)" fill="url(#paint4_linear_546_38)" fillOpacity="0.9" />
                <rect x="28.4269" y="21.4777" width="2.37781" height="16.854" rx="1.1889" transform="rotate(90 28.4269 21.4777)" stroke="url(#paint5_linear_546_38)" strokeWidth="0.288859" />
            </g>
            <g filter="url(#filter5_b_546_38)">
                <rect x="28.5713" y="29.3333" width="2.66667" height="17.1429" rx="1.33333" transform="rotate(90 28.5713 29.3333)" fill="url(#paint6_linear_546_38)" fillOpacity="0.9" />
                <rect x="28.4269" y="29.4777" width="2.37781" height="16.854" rx="1.1889" transform="rotate(90 28.4269 29.4777)" stroke="url(#paint7_linear_546_38)" strokeWidth="0.288859" />
            </g>
            <defs>
                <filter id="filter0_b_546_38" x="-7.85132" y="-2.01514" width="55.7026" height="35.0359" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.92566" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_546_38" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_546_38" result="shape" />
                </filter>
                <filter id="filter1_b_546_38" x="-2.13721" y="-7.85132" width="24.2742" height="26.3694" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.92566" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_546_38" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_546_38" result="shape" />
                </filter>
                <filter id="filter2_b_546_38" x="17.8628" y="-7.85132" width="24.2742" height="26.3694" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.92566" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_546_38" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_546_38" result="shape" />
                </filter>
                <filter id="filter3_b_546_38" x="-7.85132" y="5.48193" width="55.7026" height="42.3694" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.92566" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_546_38" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_546_38" result="shape" />
                </filter>
                <filter id="filter4_b_546_38" x="3.57715" y="13.4819" width="32.8455" height="18.3694" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.92566" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_546_38" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_546_38" result="shape" />
                </filter>
                <filter id="filter5_b_546_38" x="3.57715" y="21.4819" width="32.8455" height="18.3694" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.92566" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_546_38" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_546_38" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_546_38" x1="-4.17808" y1="15.5172" x2="7.40643" y2="27.5227" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF6161" />
                    <stop offset="1" stopColor="#F52828" />
                </linearGradient>
                <linearGradient id="paint1_linear_546_38" x1="5.28554" y1="0.761905" x2="13.5249" y2="10.8738" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="0.765625" stopColor="white" stopcpacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_546_38" x1="25.2855" y1="0.761905" x2="33.5249" y2="10.8738" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="0.765625" stopColor="white" stopcpacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_546_38" x1="-2" y1="15.238" x2="13.4225" y2="50.5695" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="0.765625" stopColor="white" stopcpacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_546_38" x1="33.2371" y1="19.3099" x2="29.3233" y2="35.487" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopcpacity="0" />
                    <stop offset="0.979167" stopColor="white" />
                </linearGradient>
                <linearGradient id="paint5_linear_546_38" x1="29.9046" y1="21.3333" x2="29.9046" y2="38.4761" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopcpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient id="paint6_linear_546_38" x1="32.1047" y1="29.1494" x2="29.3039" y2="42.743" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopcpacity="0" />
                    <stop offset="0.979167" stopColor="white" />
                </linearGradient>
                <linearGradient id="paint7_linear_546_38" x1="29.9046" y1="29.3333" x2="29.9046" y2="46.4761" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopcpacity="0" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
            </defs>
        </svg>


    )
}
