export const TabsData = [
    {
        id: 1,
        title: "Sales App",
        boxes: [
            { id: 1, img: 'images/revin.png', content: "Revin Sales Mobile Application is specially designed for Salesperson for the Make Invoice on the customer place. Provides easy feature to make the collection with the printing of Invoice and receipts, Stock Statements and Collection reports. The app is having live integration with TallyPrime." },
            { id: 2, img: 'images/aquasales.png', content: "Mobile application integrated with TallyPrime for Water Bottle Suppliers. Having a feature of creating Sales Invoice, Collecting the payments, Viewing the Stock and Customer outstanding and printing the Invoices and Collection receipt with the bluetooth printer." },
            { id: 3, img: 'images/gars.png', content: "GARS Mobile Application is specially designed for Salesperson for the Make Invoice. Provides easy feature to make the Invoice with the printing. Salesperson transfer the stock from one place to other. Stock Statements and  Transfer reports. The app is having live integration with Tally prime." },
            { id: 4, img: 'images/globalapp.png', content: "Tally Product Sales Order for Global software" },
            { id: 5, img: 'images/mb-logo.png', content: "Welcome to MBSO, the comprehensive solution designed to revolutionize how wholesalers manage sales orders. With MBSO, you can effortlessly create, edit, and manage sales orders directly from your Android device, eliminating the hassle of paperwork and delays. Our seamless integration with TallyPrime ensures that your sales orders are synced in real-time, providing you with accurate and up-to-date accounting records with every transaction." },
            { id: 6, img: 'images/gpcropcare.png', content: "Mobile application integrated with TallyPrime for Voucher Authentication.Exciting new Android mobile application integrated with TallyPrime to authorize Sales transaction on the go." },
            
        ]
    },
    {
        id: 2,
        title: "Employee Tracking",
        boxes: [
            { id: 7, img: 'images/fieldtracking.png', content: "The Field Tracking Application is used for salesperson visit tracking. The Field Tracking application is specially designed for salesperson visit tracking.The Field Tracking application is specially designed for salesperson visit tracking. <br> It maintains the visit record of the salesperson's visit. It also tracks the GPS location of the salesperson's activity. It helps the company to maintain a record of customer visits of salesperson reporting." },
        ]
    },
    {
        id: 3,
        title: "Enterprises",
        boxes: [
            { id: 8, img: 'images/vk-enterprises.png', content: "Welcome to VKExhibitions, the comprehensive solution designed to revolutionize how to manage complete the project . With VKExhibitions, you can effortlessly create and manage project use foul item in recode directly from your Android device, eliminating the hassle of paperwork and delays. Our account integration with TallyPrime ensures that your purchase order are synced in real-time, providing you with accurate and up-to-date accounting records with every transaction." },
        ]
    },
    {
        id: 4,
        title: "Quotation Management",
        boxes: [
            { id: 9, img: 'images/gover.png', content: "Mobile application integrated with TallyPrime for Gover. Having a feature of creating Sales Order, Viewing the Sales Order with Edit and.Scan Item Barcode." },]
    }

];