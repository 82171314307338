import React from 'react'

export default function IconArowRightDown() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_554_2787)">
    <path d="M5.00746 24.9925L25 25L24.9975 4.99999L22.5 4.99752L22.5 20.7325L1.7675 -1.01552e-06L1.83009e-06 1.7675L20.7325 22.5L4.99999 22.5L5.00746 24.9925Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_554_2787">
    <rect width="25" height="25" fill="white" transform="translate(25) rotate(90)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}
