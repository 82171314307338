import React from 'react'

export default function IconServices() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7436 0C16.2308 0 17.4359 1.20513 17.4359 2.69231V13.3333H12.0513V2.69231C12.0513 1.20513 13.2564 0 14.7436 0Z" fill="url(#paint0_linear_2_1675)" />
            <path d="M25.5129 0C27 0 28.2052 1.20513 28.2052 2.69231V13.3333H22.8206V2.69231C22.8206 1.20513 24.0257 0 25.5129 0Z" fill="url(#paint1_linear_2_1675)" />
            <path d="M0 25.2564C0 23.7692 1.20513 22.5641 2.69231 22.5641H13.3333V27.9487H2.69231C1.20513 27.9487 0 26.7436 0 25.2564Z" fill="url(#paint2_linear_2_1675)" />
            <path d="M0 14.4871C0 13 1.20513 11.7948 2.69231 11.7948H13.3333V17.1794H2.69231C1.20513 17.1794 0 15.9743 0 14.4871Z" fill="url(#paint3_linear_2_1675)" />
            <path d="M25.2564 40C23.7692 40 22.5641 38.7949 22.5641 37.3077V26.6667H27.9487V37.3077C27.9487 38.7949 26.7436 40 25.2564 40Z" fill="url(#paint4_linear_2_1675)" />
            <path d="M14.4871 40C13 40 11.7948 38.7949 11.7948 37.3077L11.7948 26.6667H17.1794V37.3077C17.1794 38.7949 15.9743 40 14.4871 40Z" fill="url(#paint5_linear_2_1675)" />
            <path d="M39.9999 14.7436C39.9999 16.2308 38.7947 17.4359 37.3076 17.4359H26.6665V12.0513H37.3076C38.7947 12.0513 39.9999 13.2564 39.9999 14.7436Z" fill="url(#paint6_linear_2_1675)" />
            <path d="M39.9999 25.5129C39.9999 27 38.7947 28.2052 37.3076 28.2052H26.6665V22.8206H37.3076C38.7947 22.8206 39.9999 24.0257 39.9999 25.5129Z" fill="url(#paint7_linear_2_1675)" />
            <g filter="url(#filter0_b_2_1675)">
                <path d="M29.3588 6.66675H10.6409C8.44589 6.66675 6.6665 8.44613 6.6665 10.6411V29.3591C6.6665 31.554 8.44589 33.3334 10.6409 33.3334H29.3588C31.5538 33.3334 33.3332 31.554 33.3332 29.3591V10.6411C33.3332 8.44613 31.5538 6.66675 29.3588 6.66675Z" fill="url(#paint8_linear_2_1675)" />
                <path d="M10.6409 7.66675H29.3588C31.0015 7.66675 32.3332 8.99841 32.3332 10.6411V29.3591C32.3332 31.0018 31.0015 32.3334 29.3588 32.3334H10.6409C8.99817 32.3334 7.6665 31.0018 7.6665 29.3591V10.6411C7.6665 8.99841 8.99817 7.66675 10.6409 7.66675Z" stroke="url(#paint9_linear_2_1675)" strokeWidth="2" />
            </g>
            <path d="M26.6667 13.3333H13.3334V26.6666H26.6667V13.3333Z" fill="black" fillOpacity="0.75" />
            <defs>
                <filter id="filter0_b_2_1675" x="-11.3335" y="-11.3333" width="62.6666" height="62.6667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="9" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_1675" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2_1675" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_2_1675" x1="4.41281" y1="-2.54615" x2="42.1667" y2="33.9564" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B14DFF" />
                    <stop offset="0.99" stopColor="#652AC6" />
                </linearGradient>
                <linearGradient id="paint1_linear_2_1675" x1="10.0898" y1="-7.4718" x2="47.8436" y2="29.0308" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B14DFF" />
                    <stop offset="0.99" stopColor="#652AC6" />
                </linearGradient>
                <linearGradient id="paint2_linear_2_1675" x1="-7.58974" y1="10.6974" x2="30.2128" y2="47.2487" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B14DFF" />
                    <stop offset="0.99" stopColor="#652AC6" />
                </linearGradient>
                <linearGradient id="paint3_linear_2_1675" x1="-2.65897" y1="4.69739" x2="35.1462" y2="41.2461" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B14DFF" />
                    <stop offset="0.99" stopColor="#652AC6" />
                </linearGradient>
                <linearGradient id="paint4_linear_2_1675" x1="-2.26922" y1="5.94615" x2="35.4846" y2="42.4487" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B14DFF" />
                    <stop offset="0.99" stopColor="#652AC6" />
                </linearGradient>
                <linearGradient id="paint5_linear_2_1675" x1="-7.94364" y1="10.8718" x2="29.8102" y2="47.3744" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B14DFF" />
                    <stop offset="0.99" stopColor="#652AC6" />
                </linearGradient>
                <linearGradient id="paint6_linear_2_1675" x1="9.68449" y1="-7.34617" x2="47.4896" y2="29.2051" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B14DFF" />
                    <stop offset="0.99" stopColor="#652AC6" />
                </linearGradient>
                <linearGradient id="paint7_linear_2_1675" x1="4.75372" y1="-1.34355" x2="42.5563" y2="35.2052" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B14DFF" />
                    <stop offset="0.99" stopColor="#652AC6" />
                </linearGradient>
                <linearGradient id="paint8_linear_2_1675" x1="8.17199" y1="31.8279" x2="31.8277" y2="8.17224" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.2" />
                    <stop offset="1" stopColor="white" stopOpacity="0.49" />
                </linearGradient>
                <linearGradient id="paint9_linear_2_1675" x1="8.54887" y1="8.76403" x2="30.8397" y2="32.5981" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>

    )
}
