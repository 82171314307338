import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import AboutUs from '../../pages/About'
import Home from '../../pages/Home'
import TallyPrime from '../../pages/TallyPrime'
import TallySupport from '../../pages/Support/TallySupport'
import CorporateImplementation from '../../pages/CorporateImplementation'
import ValueAddedServices from '../../pages/ValueAddedServices'
import AnnualMaintainanceContract from '../../pages/AnnualMaintainanceContract'
import PriceIndia from '../../pages/PriceIndia'
import PriceTanzania from '../../pages/PriceTanzania'
import PriceUnitedArabEmirates from '../../pages/PriceUnitedArabEmirates'
import PriceAngola from '../../pages/PriceAngola'
import OurProducts from '../../pages/OurProducts'
import Careers from '../../pages/Careers'
import Events from '../../pages/Events'
import Contact from '../../pages/Contact'
import TallyPrimeServer from '../../pages/TallyPrimeServer'
import TallySoftwareService from '../../pages/TallySoftwareService'
import TallyPrimeusingAWS from '../../pages/TallyPrimeusingAWS'
import TallyPrimeReleasePage from '../../pages/TallyPrimeReleasePage'
import TermsAndCondition from '../../pages/TermsAndCondition'
import PrivacyPolicy from '../../pages/PrivacyPolicy'
import TagManagerNew from '../GoogleTagManager/TagManagerNew'
import EventDetails from '../../pages/Events/EventDetails'
import TellyprimeNow from '../../pages/TellyprimeNow'


export default function NavigationRouting() {
    const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-2432RFY383', {
        page_path: location.pathname,
      });
    }
  }, [location]);

    return (
        <>
        <TagManagerNew/>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="tally-prime-release" element={<TallyPrimeReleasePage/>} />
            <Route path="tally-prime" element={<TallyPrime />} />
            <Route path="tally-prime-server" element={<TallyPrimeServer/>} />
            <Route path="tally-software-service" element={<TallySoftwareService />} />
            <Route path="tally-prime-using-aws" element={<TallyPrimeusingAWS />} />            
            <Route path="our-products" element={<OurProducts/>} />
            <Route path="tally-support" element={<TallySupport />} />
            <Route path="corporate-implementation" element={<CorporateImplementation />} />
            <Route path="value-added-services" element={<ValueAddedServices />} />
            <Route path="annual-maintainance-contract" element={<AnnualMaintainanceContract/>} />
            <Route path="price-india" element={<PriceIndia/>} />
            <Route path="price-tanzania" element={<PriceTanzania/>} />
            <Route path="price-united-arab-emirates" element={<PriceUnitedArabEmirates/>} />
            <Route path="price-angola" element={<PriceAngola/>} />
            <Route path="events" element={<Events/>} />
            <Route path="event-details" element={<EventDetails/>} />            
            <Route path="career" element={<Careers/>} />
            <Route path="about" element={<AboutUs />} />
            <Route path="contact" element={<Contact />} /> 
            <Route path="terms-and-condition" element={<TermsAndCondition />} /> 
            <Route path="privacy-policy" element={<PrivacyPolicy/>} />  
            <Route path="tallyprime-now" element={<TellyprimeNow/>} />  
            
        </Routes>
        </>
    )
}
