import React from 'react'

export default function IconDownloads() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.2375 22.586C27.8208 22.211 27.2557 22.0004 26.6664 22.0004C26.0772 22.0004 25.512 22.211 25.0953 22.586L22.222 25.172V2C22.222 1.46957 21.9879 0.960859 21.5711 0.585786C21.1544 0.210714 20.5891 0 19.9998 0C19.4104 0 18.8452 0.210714 18.4284 0.585786C18.0117 0.960859 17.7775 1.46957 17.7775 2V25.172L14.9042 22.586C14.4851 22.2217 13.9238 22.0201 13.3411 22.0246C12.7584 22.0292 12.2011 22.2395 11.7891 22.6104C11.377 22.9812 11.1433 23.4828 11.1383 24.0072C11.1332 24.5316 11.3572 25.0368 11.762 25.414L18.4287 31.414C18.8454 31.7889 19.4105 31.9996 19.9998 31.9996C20.589 31.9996 21.1541 31.7889 21.5709 31.414L28.2375 25.414C28.6541 25.0389 28.8882 24.5303 28.8882 24C28.8882 23.4697 28.6541 22.961 28.2375 22.586Z" fill="url(#paint0_linear_2_1721)" />
            <g filter="url(#filter0_bii_2_1721)">
                <path d="M33.3333 14H22.2222V25.172L25.0956 22.586C25.5147 22.2217 26.076 22.0201 26.6587 22.0246C27.2413 22.0292 27.7987 22.2395 28.2107 22.6104C28.6227 22.9812 28.8564 23.4828 28.8615 24.0072C28.8666 24.5316 28.6426 25.0368 28.2378 25.414L21.5711 31.414C21.1544 31.7889 20.5893 31.9996 20 31.9996C19.4107 31.9996 18.8456 31.7889 18.4289 31.414L11.7622 25.414C11.3574 25.0368 11.1334 24.5316 11.1385 24.0072C11.1436 23.4828 11.3773 22.9812 11.7893 22.6104C12.2013 22.2395 12.7587 22.0292 13.3413 22.0246C13.924 22.0201 14.4853 22.2217 14.9044 22.586L17.7778 25.172V14H6.66667C4.8991 14.0016 3.20443 14.6342 1.95457 15.7591C0.704711 16.884 0.00176492 18.4092 0 20V34C0.00176492 35.5908 0.704711 37.116 1.95457 38.2409C3.20443 39.3658 4.8991 39.9984 6.66667 40H33.3333C35.1009 39.9984 36.7956 39.3658 38.0454 38.2409C39.2953 37.116 39.9982 35.5908 40 34V20C39.9982 18.4092 39.2953 16.884 38.0454 15.7591C36.7956 14.6342 35.1009 14.0016 33.3333 14Z" fill="white" fillOpacity="0.2" />
            </g>
            <defs>
                <filter id="filter0_bii_2_1721" x="-4" y="10" width="48" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_1721" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2_1721" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="-4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2_1721" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-4" dy="-4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect2_innerShadow_2_1721" result="effect3_innerShadow_2_1721" />
                </filter>
                <linearGradient id="paint0_linear_2_1721" x1="20.0132" y1="0" x2="20.0132" y2="31.9996" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FF0000" />
                </linearGradient>
            </defs>
        </svg>

    )
}
