import React from "react";
import PageLayout from "../../component/PageLayout";
import "./style.css";
import GoogleTagManager from "../../component/GoogleTagManager";

export default function TallyPrimeReleasePage() {
  return (
    <div className="home-main relasepage">
      <GoogleTagManager/>
      <PageLayout>
        <div className="box-default relasepagesection-main">
          <div className="container">
            <div className="sales-management d-flex align-items-center justify-content-between flex-column flex-md-row">
              <div className="titletwo">
                <h2>
                  <span>Sales and Stock Visibility</span> 
                </h2>
                <h3>Distributors Management System</h3>
              </div>
              <div className="visibility-pic">
                <img
                  src="images/tally-prime-release.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>

        <section className="TallyPrimeRelease-main mt100">
          <div className="container">
            <div className="titletwo text-center">
              <h2><span>Challenges</span> Faced</h2>
            </div>
            <div className="ourpointlist d-flex flex-wrap">
              <ul>
                <li>Pain Area</li>
                <li>Non Availability of Market Data</li>
                <li>Unstructured Data</li>                
              </ul>
              <ul>
                <li>Consolidation is virtually impossible</li>               
                <li>Misappropriation of Schemes</li>
                <li>Pain Areas Of Other DMS</li>
                <li>Duplication of work for the Distributor</li>
              </ul>
              <ul>
                 <li>Additional software installation required</li>
                <li>Fresh training required for Distributor</li>
                <li>Huge Support & license cost</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="implementationadvantage pt100">
          <div className="implementationadvantagebg">            
          </div>
          <div className="container">
            <div className="advantage-list">
              <div className="titletwo">
                <h2>
                  Advantage Statement : <span>Adoption of Tally based DMS</span>
                </h2>
              </div>
              <ul className="d-flex flex-wrap">
                <li className="col-12 col-md-6">Minimal Change Management</li>
                <li className="col-12 col-md-6">Sales Analytics and Trends</li>
                <li className="col-12 col-md-6">Stock Out Scenarios</li>
                <li className="col-12 col-md-6">Order Management</li>
                <li className="col-12 col-md-6">
                  Price Circulation and Control
                </li>
                <li className="col-12 col-md-6">Stock Movement</li>
                <li className="col-12 col-md-6">Better Procurement Planning</li>
                <li className="col-12 col-md-6">Scheme Management</li>
                <li className="col-12 col-md-6">
                  Sales Tracking Capability at Primary, Secondary and Tertiary
                  level.
                </li>
                <li className="col-12 col-md-6">
                  Seamless visibility across channels.
                </li>
                <li className="col-12 col-md-6">
                  Measure on Real-Time Accurate Sales Data.
                </li>
                <li className="col-12 col-md-6">
                  View on products movement across the channel.
                </li>
                <li className="col-12 col-md-6">
                  Effective Measurement of Target Vs Achievement.
                </li>
                <li className="col-12 col-md-6">
                  Better production planning with actual sales insights.
                </li>
                <li className="col-12 col-md-6">
                  Strong Sales & Marketing Activities based on market demand
                  conditions.
                </li>
                <li className="col-12 col-md-6">
                  Reduced overall sales realization & processing time.
                </li>
                <li className="col-12 col-md-6">
                  Streamlined sales return and scheme settlement.
                </li>
                <li className="col-12 col-md-6">
                  Analyze local market demands and trends.
                </li>
                <li className="col-12 col-md-6">
                  Seamless claim settlements/Scheme settlements.
                </li>
                <li className="col-12 col-md-6">Faster stock replenishment.</li>
                <li className="col-12 col-md-6">
                  Create an ecology of happy channel partners.
                </li>
                <li className="col-12 col-md-6">Minimal Change Management</li>
                <li className="col-12 col-md-6">
                  Implementation Speed & Acceptability
                </li>
                <li className="col-12 col-md-6">
                  Service and Local Support via Partner Network
                </li>
                <li className="col-12 col-md-6">
                  Tally Presence - Indirect Benefits of Using Tally
                </li>
                <li className="col-12 col-md-6">Multi Brand Outlet</li>
                <li className="col-12 col-md-6">
                  Master Control with Distributor Broadcasting
                </li>
                <li className="col-12 col-md-6">
                  Sales Target and Achievement
                </li>
              </ul>
            </div>
          </div>

          <div className="container">
            <div className="mmplementation-main d-flex justify-content-between flex-md-row flex-column mb100">
              <div className="expertise">
                <div className="titletwo">
                  <h2>
                    Implementation / <span>Rollout Model</span>
                  </h2>
                </div>
                <p>
                  Our expertise lies not just in the product, but in the way the
                  rollouts are done on PAN India basis. We are tied up with an
                  extensive network of Tally Partners who would physically visit
                  your Distributor/Dealer and train him on how to use the
                  solution. Due to this expertise we can achieve speeds of even
                  50-60 rollouts in a month.
                </p>
              </div>

              <div className="expertise">
                <div className="titletwo">
                  <h2>
                    Support / <span>Helpdesk Model</span>
                  </h2>
                </div>
                <p>
                  A DMS is not complete upon installation. It requires hand
                  holding to cater to the day-to-day queries of the
                  Distributor/Dealer on how to use the system efficiently. We
                  provide helpdesk resources who become the single point of
                  contact for all the issues for your Distributor/Dealer network
                  related to Tally
                </p>
              </div>
            </div>
          </div>
        </section>
      </PageLayout>
    </div>
  );
}
