import React, { useState } from "react";
import CareersForm from "../../component/CareerForm";
import PageLayout from "../../component/PageLayout";
import "./style.css";
import { CareersData } from "./CareersData";
import GoogleTagManager from "../../component/GoogleTagManager";

export default function Careers() {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? null : accordionId);
  };

  return (
    <div className="home-main careers-page">
      <GoogleTagManager/>
      <PageLayout>
        <section className="careers-banner">
          <img
            src="images/careers.svg"
            className="img-fluid"
            alt="we are hiring"
          />
        </section>
        <CareersForm />

        <section className="careers-accordion mt100">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-10 col-xl-8 mx-auto px-4 px-lg-0">
                <div className="titletwo text-center">
                  <h2>Career</h2>
                  <p>Tell us what is your interest area, we'll help you to get there.</p>
                </div>
                <div className="accordion accordion-flush">
                  {CareersData.map((accordionData, index) => (
                    <div key={index} className="accordion-item">
                      <button
                        className="accordion-button justify-content-center"
                        onClick={() => toggleAccordion(`accordion${index + 1}`)}
                        aria-expanded={openAccordion === `accordion${index + 1}`}
                      >
                        {accordionData.title}
                      </button>

                      <div
                        className={`accordion-collapse collapse ${
                          openAccordion === `accordion${index + 1}` ? "show" : ""
                        }`}
                        id={`flush-collapse${index + 1}`}
                      >
                          <div className="accordion-body">
                            <ol type="1">
                              <li>{accordionData.des1}</li>
                              <li>{accordionData.des2}</li>
                              <li>{accordionData.des3}</li>
                              <li>{accordionData.des4}</li>
                              {accordionData.des5 && <li>{accordionData.des5}</li>}
                            </ol>
                        
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </PageLayout>
    </div>
  );
}
