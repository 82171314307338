import React from 'react'

export default function Iconshoper9() {
    return (
        <svg width="225" height="74" viewBox="0 0 225 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M38.2836 53.2012C34.9587 54.213 11.821 59.5881 11.821 59.5881C11.821 59.5881 2.11214 67.8677 11.2078 72.2725L81.5612 52.6025C81.5612 52.6025 151.724 34.6875 149.714 35.2518C147.724 35.7887 41.1928 52.0381 38.2836 53.2012Z" fill="#D92429" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.3938 0.922241H38.7877V15.3065C40.1299 14.4256 41.3358 13.524 43.0664 12.9872C48.5238 11.3216 57.4491 12.1406 59.2683 16.4077C59.97 18.0182 59.7997 20.7024 59.7724 23.0149V35.8782H51.324C51.2968 31.7487 51.2968 27.6536 51.2695 23.5242C51.2695 21.8586 51.6851 19.202 51.1264 17.8186C49.5322 13.7236 40.5796 16.1531 38.8763 18.5275V35.7887C36.0488 35.8162 33.2213 35.8506 30.3938 35.8782V0.922241Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.1086 12.2232C16.7196 12.1682 19.5675 12.76 22.2588 13.524C22.286 14.7078 22.3133 15.8984 22.3405 17.1098C20.2148 16.8276 12.1003 14.2604 10.9556 17.3093C10.4787 18.6101 13.5855 20.4202 14.3963 20.957C17.3328 22.9323 21.0256 24.7148 23.0968 27.5642C23.6555 28.3557 24.6366 29.6014 24.439 31.0467C23.4852 37.9085 7.79429 37.0619 2.11206 34.715V30.3929H2.16657C4.79647 31.4941 8.46199 33.2215 12.3523 32.5952C13.64 32.3681 16.0179 32.1204 15.6568 30.3929C15.3161 28.7824 13.4425 27.9083 12.2978 27.0618C9.72243 25.2241 2.89558 21.1842 2.30964 17.9288C1.69645 14.5977 6.81319 13.1248 9.30683 12.6155C10.5605 12.4779 11.8482 12.3609 13.1086 12.2232Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M178.104 35.8781C175.311 35.8162 172.483 35.7611 169.656 35.7336V12.8701C172.429 12.8426 175.168 12.8151 177.941 12.8151C177.995 14.7972 178.05 16.7725 178.104 18.7753V18.6376C178.636 17.8461 179.167 17.0547 179.699 16.2632C182.022 13.2969 187.057 10.9775 192.235 12.9321V13.3794C192.215 15.0381 191.922 16.0911 191.588 17.3644C189.101 16.1806 185.517 16.7174 183.589 17.8737C177.096 21.721 178.077 25.9261 178.104 35.8781Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M164.117 25.1966C164.792 23.242 164.117 20.2481 163.477 18.7202C161.766 14.7077 158.973 13.2968 153.686 12.3058C151.921 11.9685 148.372 12.2232 146.689 12.5329C142.356 13.407 139.194 15.5887 137.709 19.3465C137.232 20.6473 136.898 22.6776 137.014 24.2881C137.6 31.6661 141.204 34.5223 147.588 35.9332C148.958 36.2429 156.452 36.415 158.326 36.0433C160.063 35.7061 161.487 35.0247 162.918 34.5223C163.306 33.6413 163.109 31.8381 163.081 30.7301C157.604 34.1782 145.49 33.9235 145.994 25.3342C152.03 25.2792 158.074 25.2516 164.117 25.1966ZM145.374 22.1132C145.319 19.1194 145.742 15.7539 150.382 15.7539C155.587 15.7539 155.417 21.4044 155.137 22.0307C152.453 22.0307 149.823 22.1132 145.374 22.1132Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M110.769 14.343V12.8151C107.969 12.7876 105.149 12.76 102.321 12.7325V47.4888H110.715V34.715C118.264 38.2526 128.756 35.8162 132.286 30.0831C133.008 28.8374 133.321 27.399 133.601 25.644C135.474 14.1709 119.218 9.13988 110.769 14.343ZM123.612 29.3192C122.018 32.0584 118.073 33.9855 113.57 32.5952C112.643 32.3131 111.076 31.439 111.076 31.439C111.076 31.439 111.049 20.9846 111.049 17.7636C112.282 16.4628 117.624 14.1434 121.207 16.917C124.559 18.9749 126.099 25.0521 123.612 29.3192Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M94.99 17.1098C92.728 14.226 89.008 13.1868 84.6135 12.4228C83.1282 12.3609 78.9653 11.8584 75.6609 12.8151C71.4367 13.8337 67.8529 15.8709 66.3199 19.6012C65.6999 21.0947 65.1958 23.3865 65.5637 25.5339C66.6538 32.2305 70.1217 34.3778 75.9402 36.0158C78.7677 37.0895 86.235 36.0709 86.5961 35.9883C88.5038 35.5616 90.2071 35.1417 91.6924 34.2677C97.0612 31.0467 99.7456 23.1869 94.99 17.1098ZM85.9829 30.675C84.811 32.0033 83.2167 32.5952 81.0297 32.8224C74.3459 33.4762 72.8334 25.761 74.6525 19.966C76.022 15.5268 79.9668 16.0361 80.7504 15.926C82.9647 16.0636 84.8383 16.5729 85.9012 17.7085C88.388 20.3307 88.6946 27.5986 85.9829 30.675Z" fill="#231F20" />
            <path d="M197.924 27.0411C198.306 29.9248 199.457 32.1891 201.392 33.8272C203.327 35.4583 205.739 36.2773 208.628 36.2773C210.862 36.2773 212.811 35.7818 214.473 34.7769C216.143 33.779 217.499 32.43 218.555 30.7301C219.604 29.0301 220.374 27.1581 220.864 25.1071C221.348 23.063 221.593 20.9776 221.593 18.8441C221.593 16.5935 221.389 14.4531 220.98 12.4159C220.571 10.3856 219.89 8.55484 218.929 6.91682C217.969 5.2788 216.661 3.9849 214.991 3.03512C213.329 2.08535 211.299 1.60358 208.914 1.60358C207.245 1.60358 205.698 1.91329 204.267 2.53271C202.836 3.15213 201.603 4.01931 200.568 5.13427C199.532 6.24234 198.735 7.54312 198.183 9.03661C197.638 10.5301 197.359 12.1131 197.359 13.7993C197.359 15.162 197.57 16.4972 197.999 17.798C198.422 19.0987 199.055 20.2412 199.907 21.2254C200.847 22.2784 201.998 23.1112 203.347 23.7306C204.696 24.35 206.052 24.5015 207.497 24.4051C209.125 24.2881 210.379 23.6893 211.612 22.8084C212.75 21.9962 214.671 19.2846 215.189 17.5227L215.039 21.0396C214.978 22.1201 214.835 23.2282 214.617 24.3707C214.399 25.5132 214.051 26.6006 213.581 27.633C213.111 28.6653 212.464 29.5188 211.646 30.1795C210.828 30.8471 209.854 31.1843 208.723 31.1843C207.592 31.1843 206.625 30.7851 205.821 29.9936C205.024 29.1953 204.513 28.2111 204.288 27.0411H197.924ZM209.411 20.1449C207.776 20.1449 206.216 18.9404 205.351 17.7016C204.485 16.4628 204.056 14.9899 204.056 13.2762C204.056 11.6588 204.519 10.2342 205.446 9.00908C206.373 7.79089 207.592 7.17835 209.098 7.17835C210.706 7.17835 211.993 7.79777 212.968 9.03661C213.942 10.2755 214.426 11.7208 214.426 13.3726C214.426 15.1138 214.208 17.3024 213.281 18.4931C212.355 19.6837 211.108 20.1449 209.411 20.1449Z" fill="#D92429" />
        </svg>

    )
}
