import React from 'react'
import { IndustriesData } from './IndustriesData'

export default function IndustriesWeServer() {
  return (
    <section className="Industriesmain pt100 pb100 mt100 mb100 box-default">
    <div className="container">
      <div
        className="titletwo text-center">
        <h2>
          <span>Industries</span> We Serve
        </h2>
      </div>
      <div className="gap50 text-center d-flex justify-content-center flex-wrap">
        {IndustriesData.map((Industries, index) => (
          <div
            className="industriesbox text-center"
            style={{ animationDelay: `${index * 0.3}s` }}
            key={Industries.id}
          >
            <div className="industries-pic d-flex justify-content-center align-items-center">
              <img
                src={Industries.img}
                className="img-fluid"
                alt={Industries.title}
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: Industries.title }} />
          </div>
        ))}
      </div>
    </div>
  </section>
  )
}
