export const CareersData =[

    {
        id:1,
        title:'Sr. & Jr. Andriod Developers',
        des1:'Should be having knowledge of Android, SDK, RESTAPI, Json, MySQL, SQ Lite, and Firebase. (Multiple Post).',
        des2:'Understanding and ability to implement software design patterns',
        des3:'Qualification : BCA/MCA/B-Tech',
        des4:'Experience : Freshers, 4 to 5 Years Experience'
    },
    
    {
        id:2,
        title:'Content Writing & Documentation (India Only)',
        des1:'Good oral and written communication skills. In - English, Hindi and Gujarati is must.',
        des2:'Knowledge of web Development/Tally will be an added advantage.',
        des3:'Qualification : BCA/MCA/BE/ME/BSC IT/MSC IT',
        des4:'Experience : Minimum 2+ Years Industrial Experience'
    },
    
    {
        id:3,
        title:'Sr. & Jr. Tally Programmers',
        des1:'Having knowledge of Tally Definition Language. (Multiple Post).',
        des2:'Understanding and ability to implement software design patterns',
        des3:'Qualification : BCA/MCA/B-Tech',
        des4:'Experience : Freshers May Apply'
    },
    
    {
        id:4,
        title:'PHP Developer (Multiple Post)',
        des1:'Skill Set Required, Core PHP, MVC, CMS, MySQL, MS SQL',
        des2:'1+ years industry experience3. Strong OOP PHP skills, any other languages are bonus',
        des3:'Understanding and ability to implement software design patterns',
        des4:'Understanding of database best practices (MySQL, MS SQL, Oracle etc)',
        des5:' Cloud technologies experience (AWS preferably)'
    },
    
    {
        id:5,
        title:'Support Executives & Marketing Executives (Multiple Post) (India Only)',
        des1:'Candidate must have a minimum qualification of Bachelorâ€™s Degree with Accountancy and Commerce background (B.com or BBA).',
        des2:'Excellent oral and written communication skills. In - English, Hindi and Gujarati is must.',
        des3:'Basic computer knowledge.',
        des4:'Tally knowledge is an added advantage.',        
    }
]