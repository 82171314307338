import React from "react";
import PageLayout from "../../component/PageLayout";
import TallySupportBoxes from "../../component/TallySupportBoxes";
import "./style.css";
import GoogleTagManager from "../../component/GoogleTagManager";

export default function CorporateImplementation() {
  return (
    <div className="home-main supportpage">
      <GoogleTagManager/>
      <PageLayout>
        <TallySupportBoxes />
        <div className="container">
   
                
          <div className="implementation">
                 <div className="titletwo">
                  <h2>Corporate Tally <span>Implementation</span> </h2>
                </div>
            <p>
              Our Implementation Services are truly innovative and designed to
              optimise processes and bring value to the business from day
              one.Expertise gleaned from our 2 decades of providing technology
              solutions to businesses in emerging economies gives us the edge to
              get you quickly attain your objectives. The tremendous flexibility
              & adaptability of TallyPrime simplifies the implementation and
              makes it an easy to start and configure on an incremental
              basis.Implementation Services by us take full advantage of this
              flexibility and adaptability to allow Customers to run their
              businesses while TallyPrime implementation is done. More
              importantly Customers can use the ERP and do transactions while
              the ERP implementation is going on simultaneously! No other ERP in
              the world will be able to give you this comfort of using the
              product while the implementation is underway! Thus the
              implementation can be performed on an iterative basis. It becomes
              important for a Customer to understand the flexibility &
              adaptability of TallyPrime before we discuss the incremental
              implementation methodology.
            </p>
          </div>

          <div className="unmached-main mt100 mb100">
            <div className="titletwo">
              <h2>
                <span>Unmached Flexibility</span> - An Agile Business<br></br>
                Growth
              </h2>
            </div>
            <p>
              TallyPrime recognises that in an ever changing world, process
              flexibility is required for businesses to stay agile. TallyPrime
              has a technology foundation which enables unconstrained
              flexibility in your solution. TallyPrime allows you to configure
              one or more ways to carry out the processes with appropriate
              controls. As a business organisation it is imperative to have
              rigid processes coexisting with flexible processes. While
              TallyPrime enables controlled processes to be enforced on the
              organisation it also allows process exceptions as the business
              demands.
            </p>
          </div>

          <div className="unmached-main">
            <div className="titletwo">
              <h2>
                <span>Adaptability on the Fly</span> - ERP Adapts to Your
                <br></br> Business Needs
              </h2>
            </div>
            <p>
              One of the most amazing benefits TallyPrime offers your business
              is the ability to change business structures, on real time data,
              enabling your business environment to respond and adapt to
              changes. TallyPrime seamlessly adapts to your business rather than
              expecting your business processes to adapt to the application
              flows. It allows you to concentrate on running your business and
              managing it your way. In short, TallyPrime is designed to adapt to
              specific business processes and needs and not to be a 'force-fit'.
              So, whether it is tripling production to meet unexpected demands,
              adding new players on your supply chain, or reorganizing finance
              for a R&D initiative, you can adapt to the new scenario on the
              fly.
            </p>
          </div>

          <div className="unmached-main mt100 mb100">
            <div className="titletwo">
              <h2>
                <span>Benefits</span> of Our Implementation Services <br></br>
                Include
              </h2>
            </div>
            <ul>
              <li>
                Minimal start-up, rapid installation - start from day one!
              </li>
              <li>Complete 'suits-your-business' flexibility</li>
              <li>Minimal disruption to regular processes</li>
              <li>Huge operational savings</li>
              <li>Easy learning</li>
            </ul>
          </div>
        </div>
      </PageLayout>
    </div>
  );
}
