
export const ProductsData = [
    {
        id:1,
        img:'images/Our-Products/invoice-validation.svg',
        title:'Invoice Validation',
        des:'Validate any invoice before sending it to client through Mobile App.',
        btn:"Read More",        
        
    },
    {
        id:2,
        img:'images/Our-Products/global-quote.svg',
        title:'Global Quote',
        des:'Send Quotation to any one instantly through App Synced with Tally.',
        btn:"Read More",        
        
    },
    {
        id:3,
        img:'images/Our-Products/sales-order.svg',
        title:'Sales Order',
        des:'Create and Send Sales Order from Mobile app, Validate and Send to Customer on the Go.',
        btn:"Read More",        
         
    },
    {
        id:4,
        img:'images/Our-Products/stock-selling.svg',
        title:'Stock Selling',
        des:'Sell your stock. from the app',
        btn:"Read More",        
         
    },
    {
        id:5,
        img:'images/Our-Products/money-collection-app.svg',
        title:'Money Collection App',
        des:'Send Remainders to Clients for their Payment Due from Mobile App on the GO.',
        btn:"Read More",        
         
    },
    {
        id:6,
        img:'images/Our-Products/employee-payroll.svg',
        title:'Employee Payroll',
        des:'Monitor Your payroll on the Go.',
        btn:"Read More",        
        
    },
]


export const OurVerticalSolutions =[
    {
       id:1,
       img:'/images/Our-Products/super-markets.svg',
       title:'Super<br/> Markets',
       url:"#"
    },
    {
       id:2,
       img:'/images/Our-Products/petrol-pumps.svg',
       title:'Petrol <br/>Pumps',
       url:"#"
    },
    {
       id:3,
       img:'/images/Our-Products/school-management.svg',
       title:'School<br/> Management',
       url:"#"
    },
    {
       id:4,
       img:'/images/Our-Products/automobile.svg',
       title:'Automobile',
       url:"#"
    },
    {
       id:5,
       img:'/images/Our-Products/tea-garden-management.svg',
       title:'Tea Garden<br/> Management',
       url:"#"
    },
    {
       id:6,
       img:'/images/Our-Products/sales-stock-visiblity.svg',
       title:'Sales and <br/>Stock Visiblity',
       url:"#"
    },
    {
       id:7,
       img:'/images/Our-Products/container-depo-management.svg',
       title:'Container Depo<br/> Management',
       url:"#"
    },
    {
       id:8,
       img:'/images/Our-Products/bakery-management.svg',
       title:'Bakery <br/>Management',
       url:"#"
    },
    {
       id:9,
       img:'/images/Our-Products/freight-forwarding.svg',
       title:'Freight <br/>Forwarding',
       url:"#"
    }
   ]