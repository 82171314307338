export const Technology =[
    {
        id:1,
        image:"../images/OurTechnology-logo/android.svg",
        name:"Android"
    },
    {
        id:2,
        image:"../images/OurTechnology-logo/flutter_logo.svg",
        name:"Flutter"
    },
    {
        id:3,
        image:"../images/OurTechnology-logo/tally-logo.png",
        name:"Tally"
    },
    {
        id:4,
        image:"../images/OurTechnology-logo/react.svg",
        name:"ReactJs and React Native"
    },    
    {
        id:5,
        image:"../images/OurTechnology-logo/html-5.svg",
        name:"HTML 5"
    },
    {
        id:6,
        image:"../images/OurTechnology-logo/css.svg",
        name:"Css 3"
    },
    {
        id:7,
        image:"../images/OurTechnology-logo/laravel.svg",
        name:"Laravel"
    },
    {
        id:8,
        image:"../images/OurTechnology-logo/php.svg",
        name:"PHP"
    },
    {
        id:9,
        image:"../images/OurTechnology-logo/nodejs.svg",
        name:"nodeJs"
    },
    {
        id:10,
        image:"../images/OurTechnology-logo/sql.svg",
        name:"Sql"
    },
    {
        id:11,
        image:"../images/OurTechnology-logo/sql-server.svg",
        name:"Sql Server"
    }, 
    {
        id:12,
        image:"../images/OurTechnology-logo/java.svg",
        name:"Java"
    },   
    {
        id:13,
        image:"../images/OurTechnology-logo/aspnet.svg",
        name:"ASP.Net"
    },   
    {
        id:14,
        image:"../images/OurTechnology-logo/vbnet.png",
        name:"VB.Net"
    },
    {
        id:15,
        image:"../images/OurTechnology-logo/mysql.png",
        name:"MySql"
    },
    {
        id:16,
        image:"../images/OurTechnology-logo/jquery.png",
        name:"jQuery"
    },
    
    
]