import React from "react";
import "./style.css";
import PageLayout from "../../component/PageLayout";
import GoogleTagManager from "../../component/GoogleTagManager";

export default function PrivacyPolicy() {
  return (
    <div className="home-main">
      <GoogleTagManager/>
      <PageLayout>
        <div className="Innerpage-title">
          <h1 className="title1 text-center box-default">Privacy Policy</h1>
        </div>

        <section className="policy-mainfirst">
          <div className="container">
            <div className="policy-main d-flex gap50 align-items-center justify-content-between">
              <div className="policy-left">
                <p>
                  Our Privacy Policy was last updated and posted on June 17,
                  2013. It governs the privacy terms of our Website, located at
                  https://www.globalsoftwares.net/. Any capitalized terms not
                  defined in our Privacy Policy, have the meaning as specified
                  in our Terms of Service.
                </p>
              </div>
              <div className="policy-right">
                <img
                  src="images/privacy.jpg"
                  className="img-fluid"
                  alt="Our Privacy Policy was last updated"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="updated-mainfirst mt100">
          <div className="container">
            <div className="updated-main">
              <div className="updated-left">
                <div className="titletwo">
                  <h2>
                    <span>Your</span> Privacy
                  </h2>
                  <p>
                    Global Software follows all legal requirements to protect
                    your privacy. Our Privacy Policy is a legal statement that
                    explains how we may collect information from you, how we may
                    share your information, and how you can limit our sharing of
                    your information. You will see terms in our Privacy Policy
                    that are capitalized. These terms have meanings as described
                    in the Definitions section below.
                  </p>
                </div>
              </div>
              <div className="updated-right">
                <div className="titletwo">
                  <h2>Definitions</h2>
                  <p>
                    "Non Personal Information" is information that is not
                    personally identifiable to you and that we automatically
                    collect when you access our Website with a web browser. It
                    may also include publicly available information that is
                    shared between you and others. "Personally Identifiable
                    Information" is non-public information that is personally
                    identifiable to you and obtained in order for us to provide
                    you within our Website. Personally Identifiable Information
                    may include information such as your name, email address,
                    and other related information that you provide to us or that
                    we obtain about you.
                  </p>
                </div>
              </div>
            </div>
            <div className="updated-main">
              <div className="updated-left">
                <div className="titletwo">
                  <h2>
                    <span>Information</span> We Collect
                  </h2>
                  <p>
                    Generally, you control the amount and type of information
                    you provide to us when using our Website. As a Visitor, you
                    can browse our website to find out more about our Website.
                    You are not required to provide us with any Personally
                    Identifiable Information as a Visitor.
                  </p>
                </div>
              </div>
              <div className="updated-right">
                <div className="titletwo">
                  <h2>
                    <span>Computer</span> Information Collected
                  </h2>
                  <p>
                    When you use our Website, we automatically collect certain
                    computer information by the interaction of your mobile phone
                    or web browser with our Website. Such information is
                    typically considered Non Personal Information. We also
                    collect the following:
                  </p>
                  <p>
                    <strong>Cookies</strong> : Our Website uses "Cookies" to
                    identify the areas of our Website that you have visited. A
                    Cookie is a small piece of data stored on your computer or
                    mobile device by your web browser. We use Cookies to
                    personalize the Content that you see on our Website. Most
                    web browsers can be set to disable the use of Cookies.
                    However, if you disable Cookies, you may not be able to
                    access functionality on our Website correctly or at all. We
                    never place Personally Identifiable Information in Cookies.
                  </p>
                  <p>
                    <strong>Automatic Information</strong> : We automatically
                    receive information from your web browser or mobile device.
                    This information includes the name of the website from which
                    you entered our Website, if any, as well as the name of the
                    website to which you're headed when you leave our website.
                    This information also includes the IP address of your
                    computer/proxy server that you use to access the Internet,
                    your Internet Website provider name, web browser type, type
                    of mobile device, and computer operating system. We use all
                    of this information to analyze trends among our Users to
                    help improve our Website.
                  </p>
                </div>
              </div>
            </div>

            <div className="updated-main">
              <div className="updated-left">
                <div className="titletwo">
                  <h2>
                    <span>How We Use</span> Your Information
                  </h2>
                  <p>
                    <strong>Customizing Our Website</strong> : We may use the
                    Personally Identifiable information you provide to us along
                    with any computer information we receive to customize our
                    Website.
                  </p>
                  <p>
                    <strong>
                      Sharing Information with Affiliates and Other Third
                      Parties
                    </strong>{" "}
                    : We do not sell, rent, or otherwise provide your Personally
                    Identifiable Information to third parties for marketing
                    purposes. We may provide your Personally Identifiable
                    Information to affiliates that provide services to us with
                    regards to our Website (i.e. payment processors, Website
                    hosting companies, etc.); such affiliates will only receive
                    information necessary to provide the respective services and
                    will be bound by confidentiality agreements limiting the use
                    of such information.
                  </p>
                  <p>
                    <strong> Data Aggregation </strong>: We retain the right to
                    collect and use any Non Personal Information collected from
                    your use of our Website and aggregate such data for internal
                    analytics that improve our Website and Service as well as
                    for use or resale to others. At no time is your Personally
                    Identifiable Information included in such data aggregations.
                  </p>
                  <p>
                    <strong>Legally Required Releases of Information </strong>:
                    We may be legally required to disclose your Personally
                    Identifiable Information, if such disclosure is (a) required
                    by subpoena, law, or other legal process; (b) necessary to
                    assist law enforcement officials or government enforcement
                    agencies; (c) necessary to investigate violations of or
                    otherwise enforce our Legal Terms; (d) necessary to protect
                    us from legal action or claims from third parties including
                    you and/or other Members; and/or (e) necessary to protect
                    the legal rights, personal/real property, or personal safety
                    of Global Software, our Users, employees, and affiliates.
                  </p>
                </div>
              </div>
              <div className="updated-right">
                <div className="titletwo">
                  <h2>
                    <span>Privacy Policy</span> Updates
                  </h2>
                  <p>
                    We reserve the right to modify this Privacy Policy at any
                    time. You should review this Privacy Policy frequently. If
                    we make material changes to this policy, we may notify you
                    on our Website, by a blog post, by email, or by any method
                    we determine. The method we chose is at our sole discretion.
                    We will also change the "Last Updated" date at the beginning
                    of this Privacy Policy. Any changes we make to our Privacy
                    Policy are effective as of this Last Updated date and
                    replace any prior Privacy Policies.
                  </p>
                </div>
                <div className="policy-privacy">
                  <img
                    src="images/privacy-updates.png"
                    className="img-fluid"
                    alt="We reserve the right to modify this Privacy Polic"
                  />
                </div>
              </div>
            </div>

            <div className="updated-main">
              <div className="updated-left">
                <div className="titletwo">
                  <h2>
                    <span>Privacy</span> Policy
                  </h2>
                  <p>
                    Protecting your privacy is very important to us. As part of
                    the normal operation of our services, your basic information
                    (name, address, e-mail and phone numbers) is collected by us
                    for internal record purpose. We do use your contact
                    information to send you products/services based on your
                    orders and your interests and we also use your name and
                    address for raising bills and invoices.
                  </p>
                </div>
              </div>
              <div className="updated-right">
                <div className="titletwo">
                  <h2>
                    <span>Questions About</span> Our Privacy Practices or This
                    Privacy Policy
                  </h2>
                  <p>
                    If you have any questions about our Privacy Practices or
                    this Policy, please contact us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </PageLayout>
    </div>
  );
}
