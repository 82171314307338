import React from "react";

export default function IconCall() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15_600)">
        <path
          d="M27.3119 4.68434C21.0626 -1.5631 10.9319 -1.5616 4.68446 4.68778C-1.56297 10.9372 -1.56147 21.0678 4.6879 27.3152C10.9373 33.5627 21.0679 33.5612 27.3153 27.3118C30.3157 24.3105 32.0008 20.2402 31.9999 15.9963C31.999 11.7532 30.3127 7.68422 27.3119 4.68434ZM24.2313 22.2876C24.2307 22.2883 24.23 22.289 24.2292 22.2897V22.2844L23.4185 23.0897C22.3701 24.1514 20.8432 24.5882 19.3918 24.2417C17.9297 23.8503 16.5397 23.2273 15.2745 22.3964C14.0992 21.6452 13.0099 20.7671 12.0265 19.7777C11.1217 18.8795 10.3088 17.8933 9.59984 16.8337C8.82434 15.6936 8.21053 14.4516 7.77584 13.143C7.27753 11.6058 7.69046 9.91897 8.84253 8.78572L9.79184 7.8364C10.0558 7.57128 10.4847 7.57034 10.7497 7.83428C10.7504 7.83497 10.7512 7.83565 10.7518 7.8364L13.7492 10.8337C14.0143 11.0977 14.0152 11.5265 13.7513 11.7916C13.7506 11.7923 13.7499 11.793 13.7492 11.7937L11.9892 13.5537C11.4842 14.0532 11.4207 14.847 11.8398 15.4204C12.4764 16.294 13.1808 17.1161 13.9465 17.8791C14.8002 18.7365 15.7283 19.5164 16.7198 20.2098C17.2928 20.6094 18.0695 20.542 18.5652 20.0498L20.2665 18.3218C20.5304 18.0567 20.9593 18.0557 21.2243 18.3197C21.225 18.3203 21.2257 18.321 21.2265 18.3218L24.2292 21.3298C24.4943 21.5937 24.4953 22.0225 24.2313 22.2876Z"
          fill="#513F95"
        />
      </g>
      <defs>
        <clipPath id="clip0_15_600">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
