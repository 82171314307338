export const IndustriesData =[

    {
        id:1,
        img:'images/about/industries/1.svg',
        title:'ERP/<br>CRM'
    },
    {
        id:2,
        img:'images/about/industries/2.svg',
        title:'Super<br>Markets'
    },
    {
        id:3,
        img:'images/about/industries/3.svg',
        title:'Petrol<br>Pumps'
    },
    {
        id:4,
        img:'images/about/industries/4.svg',
        title:'Rice<br>Mill'
    },
    {
        id:5,
        img:'images/about/industries/5.svg',
        title:'Automobile'
    },
    {
        id:6,
        img:'images/about/industries/6.svg',
        title:'Warehouse<br>Billing'
    },
    {
        id:7,
        img:'images/about/industries/7.svg',
        title:'Manufacturing<br>Module'
    },
    {
        id:8,
        img:'images/about/industries/8.svg',
        title:'Micro<br>Finance'
    },
    {
        id:9,
        img:'images/about/industries/9.svg',
        title:'Banking, Finance<br>& Insurance'
    },
    {
        id:10,
        img:'images/about/industries/10.svg',
        title:'Education'
    },
    {
        id:11,
        img:'images/about/industries/11.svg',
        title:'Tea Garden<br>Management'
    },
    {
        id:12,
        img:'images/about/industries/12.svg',
        title:'Others'
    },


]